_:-ms-lang(x),
.elements .tableview td.checkbox-cell input.checkbox + label,
.elements .tableview td.checkbox-cell div.checkbox {
  top: 16px;
}

_:-ms-lang(x),
input.checkbox + label:empty,
div:empty.checkbox {
  display: block;
}
