@charset "UTF-8";
/* ----------------------------------------
/*  Reset (thanks Eric!)
/* ----------------------------------------*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video { margin: 0; padding: 0; border: 0; font: inherit; vertical-align: baseline; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section { display: block; }

body { line-height: 1; }

ol, ul { list-style: none; }

blockquote, q { quotes: none; }

blockquote:before, blockquote:after, q:before, q:after { content: none; }

table { border-collapse: separate; border-spacing: 0; }

a { text-decoration: none; }

button { appearance: none; background-color: transparent; border: none; padding: 0; }

input, textarea { margin: 0; font-family: 'Lucida Grande', sans-serif; font-size: 100%; }

textarea { resize: vertical; }

select { margin: 0; }

@font-face { font-family: 'Craft'; src: url("../fonts/Craft.woff") format("woff"), url("../fonts/Craft.ttf") format("truetype"), url("../fonts/Craft.svg#Craft") format("svg"); font-weight: normal; font-style: normal; }

/* ----------------------------------------
/*  Basic stuff
/* ----------------------------------------*/
body, html { background-color: #e4edf6; }

html.noscroll, html.noscroll body { overflow: hidden; }

body { width: 100vw; overflow-x: hidden; font-size: 14px; line-height: 20px; color: #3f4d5a; -webkit-font-smoothing: subpixel-antialiased; }

body.rtl { direction: rtl; }

body, input, select, textarea { font-family: system-ui, BlinkMacSystemFont, -apple-system, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; }

.first, h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child, p:first-child, blockquote:first-child, hr:first-child, .pane:first-child, .grid:first-child, fieldset:first-child, .field:first-child, .toolbar:first-child, .buttons:first-child { margin-top: 0 !important; }

.last, h1:last-child, h2:last-child, h3:last-child, h4:last-child, h5:last-child, h6:last-child, p:last-child, blockquote:last-child, .pane:last-child, .grid:last-child, fieldset:last-child, .field:last-child, .toolbar:last-child, .buttons:last-child { margin-bottom: 0 !important; }

.draghelper { box-sizing: border-box; }

body.rtl .ltr .text, body.rtl .ltr table.editable textarea { text-align: left !important; direction: ltr !important; }

body.ltr .rtl .text, body.ltr .rtl table.editable textarea { text-align: right !important; direction: rtl !important; }

textarea.nicetext { resize: none; }

/* icons */
.icon:before, .menu ul.padded li a.sel:before, .menu .flex.padded.sel:before, .texticon:before, .element:before, #help:before, .secure:before, .insecure:before, .go:after, .required:after, #preview-btn:before, #share-btn:before, [data-icon]:before, [data-icon-after]:after { font-family: 'Craft'; speak: none; -webkit-font-feature-settings: "liga", "dlig"; -moz-font-feature-settings: "liga=1, dlig=1"; -moz-font-feature-settings: "liga", "dlig"; -ms-font-feature-settings: "liga", "dlig"; -o-font-feature-settings: "liga", "dlig"; font-feature-settings: "liga", "dlig"; text-rendering: optimizeLegibility; font-weight: normal; font-variant: normal; text-transform: none; line-height: 1; direction: ltr; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; display: inline-block; text-align: center; font-style: normal; vertical-align: middle; word-wrap: normal !important; user-select: none; opacity: 0.8; }

.badge-icon { display: inline-flex; align-items: center; justify-content: center; width: 17px; height: 17px; box-sizing: border-box; border: 1px solid #F0B429; border-radius: 3px; color: #F0B429; font-size: 9px; }

.secure:before, .insecure:before { margin-top: -3px; font-size: 14px; }

[data-icon]:before { content: attr(data-icon); }

[data-icon-after]:after { content: attr(data-icon-after); }

body.rtl [data-icon=list]:before, body.rtl [data-icon-after=list]:after { content: 'listrtl'; }

body.rtl [data-icon=structure]:before, body.rtl [data-icon-after=structure]:after { content: 'structurertl'; }

.icon.secure:before { content: 'secure'; }

.icon.insecure:before { content: 'insecure'; }

.icon.add:before { content: 'plus'; }

.icon.edit:before { content: 'edit'; }

.icon.settings:before { content: 'settings'; }

.icon.search:before { content: 'search'; }

.icon.expand:before { content: 'expand'; }

.icon.collapse:before { content: 'collapse'; }

.help:before { content: 'help'; color: #E8368F; }

body.ltr #preview-btn:before, body.ltr #share-btn:before { margin-right: 7px; }

body.rtl #preview-btn:before, body.rtl #share-btn:before { margin-left: 7px; }

#preview-btn:before { margin-top: -2px; content: 'view'; }

body.ltr #share-btn:before { content: 'share'; }

body.rtl #share-btn:before { content: 'shareleft'; }

/* headings */
h1 { margin-bottom: 24px; font-size: 18px; font-weight: bold; line-height: 1.2; }

h2 { margin: 14px 0; font-size: 16px; font-weight: bold; line-height: 20px; }

h3 { margin: 14px 0; font-weight: bold; line-height: 1.2; }

h4 { margin: 14px 0; font-weight: bold; line-height: 1.2; color: #596673; }

h5 { margin: 14px 0 3px; line-height: 1.2; color: #596673; }

h6 { margin: 14px 0 3px; font-size: 10px; line-height: 1.2; color: #606d7b; text-transform: uppercase; font-weight: bold; }

body.ltr h1[data-icon]:before { margin: -8px 10px 0 0 ; }

body.rtl h1[data-icon]:before { margin: -8px 0 0 10px ; }

h2[data-icon]:before { font-size: 19px; }

body.ltr h2[data-icon]:before { margin: -4px 6px 0 0 ; }

body.rtl h2[data-icon]:before { margin: -4px 0 0 6px ; }

/* horizontal rule */
hr { margin: 24px 0; border: none; border-top: 1px solid rgba(51, 64, 77, 0.1); height: 0; color: transparent; }

.pane hr { margin: 24px -24px; }

/* paragraphs */
p { margin: 1em 0; }

h5 + p { margin-top: 0; }

sup { vertical-align: super; font-size: smaller; }

sub { vertical-align: sub; font-size: smaller; }

body.ltr .indent { margin-left: 14px; }

body.rtl .indent { margin-right: 14px; }

/* lists */
.bullets { list-style-type: square; }

body.ltr .bullets { padding-left: 40px; }

body.rtl .bullets { padding-right: 40px; }

ol { list-style-type: decimal; }

body.ltr ol { padding-left: 40px; }

body.rtl ol { padding-right: 40px; }

/* code */
code, .code, .code input, .code textarea { font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace; font-size: 0.9em !important; }

code.smalltext, .code.smalltext, .code input.smalltext, .code textarea.smalltext { font-size: 0.8em !important; }

/* links */
a { color: #0B69A3; cursor: pointer; }

body.underline-links a { text-decoration: underline; }

a:hover { text-decoration: underline; }

a.sel, li.sel a { cursor: default !important; text-decoration: none; }

.go:after { font-size: 11px; margin-top: -1px; color: #606d7b; }

body.ltr .go:after { padding-left: 4px; }

body.rtl .go:after { padding-right: 4px; }

body.ltr .go:after { content: 'circlerarr'; }

body.rtl .go:after { content: 'circlelarr'; }

.go:hover:after { color: #0B69A3; }

/* revision button */
body.ltr #context-btngroup { margin-right: 7px; }

body.rtl #context-btngroup { margin-left: 7px; }

#context-btn.disabled { opacity: 1; color: #596673; background-color: rgba(205, 216, 228, 0.5) !important; }

/* status icons */
.checkmark-icon, .alert-icon { padding: 5px; margin-bottom: 0 !important; line-height: 10px; border-radius: 20px; cursor: pointer; }

.checkmark-icon:before, .alert-icon:before { font-family: 'Craft'; speak: none; -webkit-font-feature-settings: "liga", "dlig"; -moz-font-feature-settings: "liga=1, dlig=1"; -moz-font-feature-settings: "liga", "dlig"; -ms-font-feature-settings: "liga", "dlig"; -o-font-feature-settings: "liga", "dlig"; font-feature-settings: "liga", "dlig"; text-rendering: optimizeLegibility; font-weight: normal; font-variant: normal; text-transform: none; line-height: 1; direction: ltr; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; display: inline-block; text-align: center; font-style: normal; vertical-align: middle; word-wrap: normal !important; user-select: none; opacity: 0.8; }

.checkmark-icon { background-color: #cdd8e4; }

p .checkmark-icon { display: inline-block; }

.checkmark-icon:before { content: 'check'; color: #27AB83; }

.alert-icon { background-color: #cdd8e4; }

.alert-icon:before { content: 'alert'; color: #CF1124; }

/* toggles */
button.toggle { appearance: none; color: inherit; background: none; border: none; padding: 0; }

.toggle:before, a.fieldtoggle:before { display: block; content: '.'; font-size: 0; width: 5px; height: 5px; border: solid #596673; border-width: 0 2px 2px 0; transition: transform linear 100ms; }

body.ltr .toggle:before, body.ltr a.fieldtoggle:before { transform: rotate(-45deg); }

body.rtl .toggle:before, body.rtl a.fieldtoggle:before { transform: rotate(135deg); }

.toggle.expanded:before, a.fieldtoggle.expanded:before, .sidebar nav li.expanded > .toggle:before, .structure li:not(.collapsed) > .row > .toggle:before { transform: rotate(45deg) !important; }

a.fieldtoggle { display: block; position: relative; margin: 14px 0; color: #3f4d5a; text-decoration: none; }

body.ltr a.fieldtoggle { padding-left: 12px; }

body.rtl a.fieldtoggle { padding-right: 12px; }

a.fieldtoggle:before { display: block; position: absolute; top: 7px; }

body.ltr a.fieldtoggle:before { left: -1px; }

body.rtl a.fieldtoggle:before { right: -1px; }

/* emphasis */
em, i { font-style: italic; }

strong, b, i em { font-weight: bold; }

/* readable blocks */
.readable { font-size: 16px; line-height: 22px; }

.readable h1, .readable h2, .readable h3, .readable h4, .readable h5, .readable h6 { margin: 24px 0 16px; font-weight: 600; }

.readable h1 { font-size: 32px; line-height: 40px; color: #000; }

.readable h2 { font-size: 24px; line-height: 30px; }

.readable h3 { font-size: 20px; line-height: 24px; }

.readable h4 { font-size: 16px; line-height: 20px; }

.readable h5 { font-size: 14px; line-height: 18px; }

.readable h6 { font-size: 13.6px; line-height: 17px; color: #596673; }

.readable ul, .readable ol { margin: 1em 0; }

body.ltr .readable ul, body.ltr .readable ol { padding-left: 2em; }

body.rtl .readable ul, body.rtl .readable ol { padding-right: 2em; }

.readable ul li { list-style-type: disc; }

.readable li + li { margin-top: 0.25em; }

.readable blockquote { margin: 16px 0; }

.readable blockquote:not(.note) { padding: 0 16px; color: #596673; }

body.ltr .readable blockquote:not(.note) { border-left: 4px solid rgba(51, 64, 77, 0.1); }

body.rtl .readable blockquote:not(.note) { border-right: 4px solid rgba(51, 64, 77, 0.1); }

.readable blockquote.note { position: relative; border-radius: 4px; padding: 1em; border: 1px solid; }

body.ltr .readable blockquote.note { padding-left: 56px; }

body.rtl .readable blockquote.note { padding-right: 56px; }

.readable blockquote.note:not(.tip):not(.warning) { border-color: #CF1124; color: #bf503f; }

.readable blockquote.note:not(.tip):not(.warning):before { content: 'alert'; color: #CF1124; }

.readable blockquote.note.tip { border-color: #0B69A3; color: #596673; }

.readable blockquote.note.tip:before { content: 'lightbulb'; color: #0B69A3; }

.readable blockquote.note.warning { border-color: #B44D12; color: #cf783a; }

.readable blockquote.note.warning:before { content: 'alert'; color: #B44D12; }

.readable blockquote.note:before { font-family: 'Craft'; speak: none; -webkit-font-feature-settings: "liga", "dlig"; -moz-font-feature-settings: "liga=1, dlig=1"; -moz-font-feature-settings: "liga", "dlig"; -ms-font-feature-settings: "liga", "dlig"; -o-font-feature-settings: "liga", "dlig"; font-feature-settings: "liga", "dlig"; text-rendering: optimizeLegibility; font-weight: normal; font-variant: normal; text-transform: none; line-height: 1; direction: ltr; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; display: inline-block; text-align: center; font-style: normal; vertical-align: middle; word-wrap: normal !important; user-select: none; opacity: 0.8; position: absolute; top: 12px; font-size: 30px; width: 24px; }

body.ltr .readable blockquote.note:before { left: 16px; }

body.rtl .readable blockquote.note:before { right: 16px; }

/* text styles */
body.ltr .leftalign { text-align: left; }

body.rtl .leftalign { text-align: right; }

.topalign { vertical-align: top; }

body.ltr .rightalign { text-align: right; }

body.rtl .rightalign { text-align: left; }

.centeralign { text-align: center !important; }

.nowrap { white-space: nowrap; }

.break-word { word-wrap: break-word; }

.light { color: #596673 !important; font-weight: normal; }

.extralight { color: #606d7b !important; }

.smalltext { font-size: 12px; line-height: 1.2; }

.largetext { font-size: 16px; line-height: 1.2; }

.zilch { padding: 100px 0; text-align: center; font-size: 20px; line-height: 24px; color: #606d7b; }

.zilch.small { padding: 24px 0; font-size: 16px; }

input.checkbox + label.smalltext { padding-top: 2px; }

.required:after { content: 'asterisk'; font-size: 7px; color: #CF1124; }

body.ltr .required:after { margin: -2px 0 0 5px ; }

body.rtl .required:after { margin: -2px 5px 0 0 ; }

.scrollpane { overflow: auto; }

body.ltr .left { float: left; }

body.rtl .left { float: right; }

body.ltr .right { float: right; }

body.rtl .right { float: left; }

th, td { vertical-align: middle; }

body.ltr th, body.ltr td { text-align: left; }

body.rtl th, body.rtl td { text-align: right; }

body.ltr table[dir='rtl'] th, body.ltr table[dir='rtl'] td { text-align: right; }

body.rtl table[dir='ltr'] th, body.rtl table[dir='ltr'] td { text-align: left; }

th.right, td.right { float: none; }

body.ltr th.right, body.ltr td.right { text-align: right; }

body.rtl th.right, body.rtl td.right { text-align: left; }

.no-outline { outline: none; }

.clear { display: block; clear: both; height: 0; }

.fullwidth { width: 100%; }

.token { display: inline-block; border-radius: 10px; padding: 2px 7px; font-size: 12px; line-height: 14px; color: #3f4d5a; background-color: rgba(255, 255, 255, 0.5); box-shadow: 0 0 0 1px rgba(123, 135, 147, 0.5); }

.token[data-name='*'] { position: relative; width: 10px; }

.token[data-name='*'] span { opacity: 0; }

.token[data-name='*']:before { font-family: 'Craft'; speak: none; -webkit-font-feature-settings: "liga", "dlig"; -moz-font-feature-settings: "liga=1, dlig=1"; -moz-font-feature-settings: "liga", "dlig"; -ms-font-feature-settings: "liga", "dlig"; -o-font-feature-settings: "liga", "dlig"; font-feature-settings: "liga", "dlig"; text-rendering: optimizeLegibility; font-weight: normal; font-variant: normal; text-transform: none; line-height: 1; direction: ltr; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; display: inline-block; text-align: center; font-style: normal; vertical-align: middle; word-wrap: normal !important; user-select: none; opacity: 0.8; display: block; position: absolute; top: 0; left: 0; width: 100%; font-size: 9px; line-height: 17px; content: 'asterisk'; text-indent: 0; }

.token:focus { box-shadow: 0 0 0 1px rgba(96, 109, 123, 0.25); background-color: #fff; outline: none; }

.highlight .token { display: inline; border: none; border-radius: 0; padding: 0; font-size: inherit; line-height: inherit; text-shadow: none; background: transparent; box-shadow: none; }

.pane.highlight pre[class*="language-"] { overflow: visible; }

.pane.highlight pre[class*="language-"] > code.diff-highlight .token:not(.prefix) { margin: 0 -24px; padding: 0 24px; }

.success { color: #27AB83 !important; }

.notice { color: #0B69A3 !important; }

.warning { color: #B44D12 !important; }

.with-icon.notice:before, .with-icon.warning:before { font-family: 'Craft'; speak: none; -webkit-font-feature-settings: "liga", "dlig"; -moz-font-feature-settings: "liga=1, dlig=1"; -moz-font-feature-settings: "liga", "dlig"; -ms-font-feature-settings: "liga", "dlig"; -o-font-feature-settings: "liga", "dlig"; font-feature-settings: "liga", "dlig"; text-rendering: optimizeLegibility; font-weight: normal; font-variant: normal; text-transform: none; line-height: 1; direction: ltr; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; display: inline-block; text-align: center; font-style: normal; vertical-align: middle; word-wrap: normal !important; user-select: none; opacity: 0.8; width: 1em; }

body.ltr .with-icon.notice:before, body.ltr .with-icon.warning:before { margin: -2px 2px 0 0 ; }

body.rtl .with-icon.notice:before, body.rtl .with-icon.warning:before { margin: -2px 0 0 2px ; }

.with-icon.notice:before { content: 'lightbulb'; }

.with-icon.warning:before { content: 'alert'; }

.error { color: #CF1124 !important; }

.icon.move { display: inline-block; }

.icon.move:not(.disabled) { cursor: move; }

.icon.move:before { content: 'move'; color: rgba(123, 135, 147, 0.5); }

.icon.move:not(.disabled):hover:before { color: #0B69A3; }

.icon.delete { display: inline-block; }

.icon.delete:before { content: 'remove'; color: rgba(123, 135, 147, 0.5); }

.icon.delete:not(.disabled):hover:before { color: #CF1124; }

.icon.delete:not(.disabled):active:before { color: #a00d1c; }

.hidden { display: none !important; }

.visually-hidden { position: absolute; width: 1px; height: 1px; padding: 0; margin: 0; overflow: hidden; clip: rect(0, 0, 0, 0); border: 0; }

.invisible { visibility: hidden; }

.clearafter:after { content: '.'; display: block; height: 0; clear: both; visibility: hidden; }

.info { vertical-align: bottom; display: inline-block; width: 1em; height: 1.375em; text-align: center; cursor: pointer; overflow: hidden; }

.info:before { font-family: 'Craft'; speak: none; -webkit-font-feature-settings: "liga", "dlig"; -moz-font-feature-settings: "liga=1, dlig=1"; -moz-font-feature-settings: "liga", "dlig"; -ms-font-feature-settings: "liga", "dlig"; -o-font-feature-settings: "liga", "dlig"; font-feature-settings: "liga", "dlig"; text-rendering: optimizeLegibility; font-weight: normal; font-variant: normal; text-transform: none; line-height: 1; direction: ltr; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; display: inline-block; text-align: center; font-style: normal; vertical-align: middle; word-wrap: normal !important; user-select: none; opacity: 0.8; vertical-align: baseline; width: 100%; line-height: 1.375; color: #606d7b; }

.info:not(.warning):before { content: 'info'; }

.info:not(.warning):hover:before { color: #0B69A3; }

.info.warning:before { content: 'alert'; }

.info.warning:hover:before { color: #B44D12; }

.info-hud table { max-width: 280px; table-layout: auto; }

.info-hud td { word-wrap: break-word; width: 100%; }

@media (max-width: 450px) { .info-hud table { table-layout: fixed; width: 100%; } }

/* ----------------------------------------
/*  Content
/* ----------------------------------------*/
.content { position: relative; }

.content:after { content: '.'; display: block; height: 0; clear: both; visibility: hidden; }

/* Customize Sources */
.sidebar .customize-sources { display: block; margin: 14px -24px 4px; color: #606d7b !important; transition: color linear 100ms; }

body.ltr .sidebar .customize-sources { padding: 7px 14px 7px 24px ; }

body.rtl .sidebar .customize-sources { padding: 7px 24px 7px 14px ; }

body.ltr .sidebar .customize-sources .icon { margin-right: 4px; }

body.rtl .sidebar .customize-sources .icon { margin-left: 4px; }

.sidebar .customize-sources .label { opacity: 0; transition: opacity linear 100ms; }

.sidebar .customize-sources:hover { color: #596673 !important; text-decoration: none; }

.sidebar .customize-sources:hover .label { opacity: 1; }

.sidebar .customize-sources:hover { color: #0B69A3; }

body.ltr .customize-sources-modal { padding-left: 200px; }

body.rtl .customize-sources-modal { padding-right: 200px; }

.customize-sources-modal > .spinner { position: absolute; top: calc(50% - 44px); left: calc(50% - 12px); }

.customize-sources-modal > .cs-sidebar { position: absolute; top: 0; margin: 0; padding: 10px 0; border: none; width: 200px; height: calc(100% - 62px); box-sizing: border-box; background-color: #f3f7fc; overflow: auto; box-shadow: inset -1px 0 0 rgba(51, 64, 77, 0.1); }

body.ltr .customize-sources-modal > .cs-sidebar { left: 0; }

body.rtl .customize-sources-modal > .cs-sidebar { right: 0; }

body.ltr .customize-sources-modal > .cs-sidebar > .btn { margin: 10px 0 0 14px ; }

body.rtl .customize-sources-modal > .cs-sidebar > .btn { margin: 10px 14px 0 0 ; }

.customize-sources-item { position: relative; margin-top: -1px; background-color: #e4edf6; border: solid rgba(51, 64, 77, 0.1); border-width: 1px 0; user-select: none; cursor: default; }

body.ltr .customize-sources-item { margin-right: 1px; }

body.rtl .customize-sources-item { margin-left: 1px; }

body.ltr .customize-sources-item { padding: 10px 14px 10px 40px ; }

body.rtl .customize-sources-item { padding: 10px 40px 10px 14px ; }

.customize-sources-item + .customize-sources-item:not(.heading) { border-top: 1px solid rgba(51, 64, 77, 0.1); }

.customize-sources-item.sel { background-color: #cdd8e4; z-index: 1; }

.customize-sources-item .move { display: block; position: absolute; top: 11px; width: 24px; text-align: center; }

body.ltr .customize-sources-item .move { left: 7px; }

body.rtl .customize-sources-item .move { right: 7px; }

.customize-sources-item + .customize-sources-item.heading { margin-top: 10px; }

.customize-sources-item.heading .label { text-transform: uppercase; color: #606d7b; font-size: 12px; font-weight: bold; }

.customize-sources-modal > .source-settings { position: relative; height: calc(100% - 62px); box-sizing: border-box; padding: 24px; overflow: auto; }

body.ltr .customize-sources-table-column .move { margin-right: 10px; }

body.rtl .customize-sources-table-column .move { margin-left: 10px; }

.customize-sources-modal > .footer { position: absolute; bottom: 0; left: 0; width: 100%; }

/* ----------------------------------------
/*  Icon lists
/* ----------------------------------------*/
ul.icons { margin-top: 20px; display: flex; flex-wrap: wrap; }

ul.icons li { margin: 0 0 10px; }

ul.icons li a { display: block; position: relative; padding: 60px 5px 10px; width: 110px; text-align: center; color: #3f4d5a; border-radius: 4px; border: 1px solid #fff; }

ul.icons li a:before { display: block; position: absolute; top: 0; left: 0; width: 100%; font-size: 40px; line-height: 60px; }

ul.icons li a .icon img, ul.icons li a .icon svg { width: 40px; height: 40px; position: absolute; top: 12px; left: calc(50% - 20px); }

ul.icons li a .icon.icon-mask svg rect, ul.icons li a .icon.icon-mask svg circle, ul.icons li a .icon.icon-mask svg ellipse, ul.icons li a .icon.icon-mask svg line, ul.icons li a .icon.icon-mask svg polyline, ul.icons li a .icon.icon-mask svg polygon, ul.icons li a .icon.icon-mask svg path, ul.icons li a .icon.icon-mask svg text { fill: #3f4d5a; stroke-width: 0; transition: fill linear 100ms; }

ul.icons li a:hover { text-decoration: none; background-color: #f3f7fc; border-color: #e4edf6; }

ul.icons li a:hover .icon.icon-mask svg rect, ul.icons li a:hover .icon.icon-mask svg circle, ul.icons li a:hover .icon.icon-mask svg ellipse, ul.icons li a:hover .icon.icon-mask svg line, ul.icons li a:hover .icon.icon-mask svg polyline, ul.icons li a:hover .icon.icon-mask svg polygon, ul.icons li a:hover .icon.icon-mask svg path, ul.icons li a:hover .icon.icon-mask svg text { fill: #0B69A3; stroke-width: 0; transition: fill linear 100ms; }

@media only screen and (max-width: 380px) { ul.icons li a { width: 96px; } }

@media only screen and (max-width: 320px) { ul.icons li a { width: 75px; } }

/* ----------------------------------------
/*  Buttons
/* ----------------------------------------*/
.toolbar { position: relative; margin-bottom: 14px; min-height: 34px; }

.toolbar.flex, .toolbar .flex { align-items: flex-start; }

.toolbar .text { border-radius: 5px !important; box-shadow: none !important; }

.flex { display: flex; align-items: center; align-content: stretch; }

.flex:not(.flex-nowrap) { flex-wrap: wrap; }

.flex:not(.flex-nowrap) > * { margin-bottom: 7px; }

.flex > *.label { white-space: nowrap; }

body.ltr .flex > *:not(:last-child) { margin-right: 7px !important; }

body.rtl .flex > *:not(:last-child) { margin-left: 7px !important; }

body.ltr .flex > *.spinner { margin-left: 0 !important; }

body.rtl .flex > *.spinner { margin-right: 0 !important; }

.centeralign .flex { justify-content: center; }

.flex-grow { flex: 1; }

.flex-center { align-items: center; }

.spacer { width: 14px; }

.buttons { display: flex; position: relative; margin: 24px 0; }

body.ltr .buttons > .btn:not(.hidden) + .btn, body.ltr .buttons > .btn:not(.hidden) + .btngroup, body.ltr .buttons > .btngroup:not(.hidden) + .btn, body.ltr .buttons > .btngroup:not(.hidden) + .btngroup { margin-left: 5px; }

body.rtl .buttons > .btn:not(.hidden) + .btn, body.rtl .buttons > .btn:not(.hidden) + .btngroup, body.rtl .buttons > .btngroup:not(.hidden) + .btn, body.rtl .buttons > .btngroup:not(.hidden) + .btngroup { margin-right: 5px; }

.hud-footer > .buttons, .footer > .buttons { margin: 0; }

.btn { display: inline-flex; align-items: center; justify-content: center; border-radius: 5px; padding: 7px 14px; border: none; text-align: center; white-space: nowrap; user-select: none; cursor: pointer; box-sizing: border-box; appearance: none; outline: none; color: #3f4d5a; font-size: inherit; background-color: rgba(96, 125, 159, 0.25); }

.btn.chromeless { background-color: transparent; height: auto; padding: 0; }

.btn.chromeless:hover, .btn.chromeless:active, .btn.chromeless:focus { background-color: transparent; }

.btn.chromeless:hover, .btn.chromeless:active { text-decoration: underline; }

.btn:not(.disabled):focus, .btn:not(.disabled).focus, .btn:not(.disabled):hover { background-color: rgba(96, 125, 159, 0.35); }

.btn:not(.disabled):active, .btn:not(.disabled).active { background-color: rgba(96, 125, 159, 0.5); }

.btn:not(.disabled):active:focus, .btn:not(.disabled):active.focus, .btngroup:focus .btn:not(.disabled):active, .btn:not(.disabled).active:focus, .btn:not(.disabled).active.focus, .btngroup:focus .btn:not(.disabled).active { background-color: rgba(96, 125, 159, 0.6); }

.btn[type='color'] { padding: 6px !important; width: 36px; }

.btn.active { cursor: default; }

.btn:hover { text-decoration: none; }

.btn:active { outline: none; }

body.ltr .btn[data-icon]:not(:empty):before, body.ltr .btn.icon:not(:empty):before, body.ltr .btn.menubtn[data-icon]:empty:before, body.ltr .btn.menubtn.icon:empty:before { margin-right: 5px; }

body.rtl .btn[data-icon]:not(:empty):before, body.rtl .btn.icon:not(:empty):before, body.rtl .btn.menubtn[data-icon]:empty:before, body.rtl .btn.menubtn.icon:empty:before { margin-left: 5px; }

.btn div.checkbox { margin-top: 2px; }

.disabled { opacity: 0.25; }

.disabled, .disabled .btn { cursor: default; }

.btn, .spinner { height: 34px; }

body.ltr .btn[data-icon-after]:not(:empty):after, body.ltr .menubtn:not(:empty):after, body.ltr .menubtn.icon:after { margin-left: 6px; }

body.rtl .btn[data-icon-after]:not(:empty):after, body.rtl .menubtn:not(:empty):after, body.rtl .menubtn.icon:after { margin-right: 6px; }

.btn[data-icon]:before, .btn[data-icon-after]:after, .btn.icon:before { position: relative; }

.btn.small[data-icon]:before, .btn.small[data-icon-after]:after, .btn.icon.small:before { font-size: 10px; }

.btn.icon.add.loading { position: relative; }

.btn.icon.add.loading:before { visibility: hidden; }

.btn.icon.add.loading:after { position: absolute; content: ''; font-size: 0; display: block; width: 24px; height: 100%; left: 5px; top: 0; background: url(../images/spinner.gif) no-repeat 0 50%; }

.btn.icon.add.loading.submit:after { background-image: url(../images/spinner_submit.gif); }

.secondary-buttons .btn.icon.add.loading.submit:after, .btn.secondary.icon.add.loading.submit:after { background-image: url(../images/spinner_submit_secondary.gif); }

/* button groups */
.btngroup { position: relative; z-index: 1; display: flex; white-space: nowrap; align-items: center; }

.btngroup:focus { outline: none; }

.btngroup.fullwidth .btn { flex: 1; }

.btngroup.disabled .btn { cursor: default; }

.btngroup .btn.active { cursor: default; }

body.ltr .btngroup .btn:not(.dashed):not(:last-child) { margin-right: 1px; }

body.rtl .btngroup .btn:not(.dashed):not(:last-child) { margin-left: 1px; }

body.ltr .btngroup .btn:not(:first-child) { border-top-left-radius: 0; border-bottom-left-radius: 0; }

body.ltr .btngroup .btn:not(:last-child) { border-top-right-radius: 0; border-bottom-right-radius: 0; }

body.rtl .btngroup .btn:not(:first-child) { border-top-right-radius: 0; border-bottom-right-radius: 0; }

body.rtl .btngroup .btn:not(:last-child) { border-top-left-radius: 0; border-bottom-left-radius: 0; }

.copytext { position: relative; z-index: 1; display: flex; white-space: nowrap; align-items: center; }

body.ltr .copytext .text { border-top-right-radius: 0; border-bottom-right-radius: 0; }

body.ltr .copytext .btn { border-top-left-radius: 0; border-bottom-left-radius: 0; }

body.rtl .copytext .text { border-top-left-radius: 0; border-bottom-left-radius: 0; }

body.rtl .copytext .btn { border-top-right-radius: 0; border-bottom-right-radius: 0; }

.copytextbtn { display: flex; flex-wrap: nowrap; align-items: center; border: 1px solid rgba(51, 64, 77, 0.1); border-radius: 3px; transition: border-color linear 100ms; padding: 0 9px; cursor: pointer; outline: none; transition: margin-left linear 100ms, margin-right linear 100ms; }

.copytextbtn input { border: 0; padding: 0; height: 32px; box-shadow: none; background-color: transparent; border: none; cursor: pointer; color: #3f4d5a; text-align: center; outline: none; }

.copytextbtn.light input { color: #596673; }

.copytextbtn span { opacity: 0; padding: 0; width: 13px; background: none; transition: opacity linear 100ms, margin-left linear 100ms, margin-right linear 100ms; }

body.ltr .copytextbtn span { margin-left: -13px; }

body.rtl .copytextbtn span { margin-right: -13px; }

.copytextbtn:hover, .copytextbtn:focus { border-color: rgba(81, 95, 108, 0.25); }

body.ltr .copytextbtn:hover, body.ltr .copytextbtn:focus { margin-left: -16px; }

body.rtl .copytextbtn:hover, body.rtl .copytextbtn:focus { margin-right: -16px; }

.copytextbtn:hover span, .copytextbtn:focus span { opacity: 1; }

body.ltr .copytextbtn:hover span, body.ltr .copytextbtn:focus span { margin-left: 3px; }

body.rtl .copytextbtn:hover span, body.rtl .copytextbtn:focus span { margin-right: 3px; }

.copytextbtn.small { padding: 0 5px; }

.copytextbtn.small input { font-size: 11px !important; height: 20px; }

/* menu buttons */
.menubtn { display: inline-flex; align-items: center; user-select: none; }

.menubtn:after { display: block; content: '.'; font-size: 0; width: 5px; height: 5px; border: solid #596673; border-width: 0 2px 2px 0; transform: rotate(45deg); position: relative; }

.menubtn.btn:after { top: -1px; }

.menubtn.btn:not(.disabled):not(.inactive):active:after, .menubtn.btn:not(.disabled):not(.inactive).active:after { border-color: #3f4d5a; }

.menubtn.btn.submit:after { border-color: #fff !important; opacity: 0.8; }

.menubtn.btn.submit:not(.disabled):not(.inactive):hover:after, .menubtn.btn.submit:not(.disabled):not(.inactive).hover:after, .menubtn.btn.submit:not(.disabled):not(.inactive):active:after, .menubtn.btn.submit:not(.disabled):not(.inactive).active:after { opacity: 1; }

.menubtn:not(.btn):not(.icon) { height: 17px; }

.menubtn:not(.btn):not(.icon):after { top: 1px; border-color: #0B69A3; }

body.ltr .menubtn:empty { padding-left: 8px; }

body.rtl .menubtn:empty { padding-right: 8px; }

body.ltr .menubtn:empty { padding-right: 8px; }

body.rtl .menubtn:empty { padding-left: 8px; }

/* spinner */
.spinner { display: inline-block; vertical-align: bottom; width: 24px; background: url(../images/spinner.gif) no-repeat 50% 50%; }

.spinner.over-bg { background-image: url(../images/spinner_bg.gif); }

.spinner.big { width: 48px; height: 48px; background: url(../images/spinner_big.gif) no-repeat 50% 50%; }

.spinner.big.over-bg { background-image: url(../images/spinner_big_bg.gif); }

body.ltr .btn + .spinner { margin-left: 7px; }

body.rtl .btn + .spinner { margin-right: 7px; }

.buttons .spinner { display: block; }

body.ltr .buttons .spinner { float: left; }

body.rtl .buttons .spinner { float: right; }

body.ltr .buttons .btn + .spinner, body.ltr .buttons .btngroup + .spinner { margin-left: 0; }

body.rtl .buttons .btn + .spinner, body.rtl .buttons .btngroup + .spinner { margin-right: 0; }

body.ltr .buttons.right .btn + .spinner { margin-right: -24px; }

body.rtl .buttons.right .btn + .spinner { margin-left: -24px; }

/* small buttons */
.btngroup.small .btn, .btn.small { padding: 0 7px !important; font-size: 12px; line-height: 22px; }

.btngroup.small, .btngroup.small input.btn, .btn.small, .btn.small + .spinner { height: 22px; }

/* big buttons */
.btngroup.big .btn, .btn.big { padding: 0 14px; font-size: 14px; line-height: 36px; }

body.ltr .btn.big[data-icon]:before, body.ltr #preview-btn:before, body.ltr #share-btn:before { margin-left: -2px; }

body.rtl .btn.big[data-icon]:before, body.rtl #preview-btn:before, body.rtl #share-btn:before { margin-right: -2px; }

.btngroup.big, .btngroup.big input.btn, .btn.big, .btn.big + .spinner { height: 36px; }

/* special buttons */
.btn.submit, .btn.submit:before, .btn.submit:after, .btn.secondary, .btn.secondary:before, .btn.secondary:after { color: #fff !important; -moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; font-weight: 500; }

.btn.submit { background-color: #E12D39 !important; }

.btn.submit:not(.disabled):not(.inactive):hover, .btn.submit:not(.disabled):not(.inactive).hover, .btn.submit:not(.disabled):not(.inactive):focus { background-color: #d61f2b !important; }

.btn.submit:not(.disabled):not(.inactive):active, .btn.submit:not(.disabled):not(.inactive).active { background-color: #c01b26 !important; }

.btn.caution { background-color: #FFE3E3 !important; color: #CF1124; }

.btn.caution:hover, .btn.caution.hover, .btn.caution:focus { background-color: #ffcaca !important; }

.btn.caution:active, .btn.caution.active { background-color: #FFBDBD !important; }

.secondary-buttons .btn.submit, .btn.secondary { background-color: #606d7b !important; }

.secondary-buttons .btn.submit:not(.disabled):not(.inactive):hover, .secondary-buttons .btn.submit:not(.disabled):not(.inactive).hover, .secondary-buttons .btn.submit:not(.disabled):not(.inactive):focus, .btn.secondary:not(.disabled):not(.inactive):hover, .btn.secondary:not(.disabled):not(.inactive).hover, .btn.secondary:not(.disabled):not(.inactive):focus { background-color: #55616d !important; }

.secondary-buttons .btn.submit:not(.disabled):not(.inactive):active, .secondary-buttons .btn.submit:not(.disabled):not(.inactive).active, .btn.secondary:not(.disabled):not(.inactive):active, .btn.secondary:not(.disabled):not(.inactive).active { background-color: #4a545e !important; }

div.btn.submit { position: relative; overflow: hidden; }

div.btn.submit input { position: absolute; left: 100%; }

/* dashed buttons */
.btn.dashed { border: 1px dashed rgba(81, 95, 108, 0.25); background-color: transparent !important; }

body.ltr .btngroup .btn.dashed:not(:last-child) { border-right: 1px solid transparent; }

body.rtl .btngroup .btn.dashed:not(:last-child) { border-left: 1px solid transparent; }

body.ltr .btngroup .btn.dashed:not(:last-child) { margin-right: -1px; }

body.rtl .btngroup .btn.dashed:not(:last-child) { margin-left: -1px; }

.btn.dashed:focus { background-color: rgba(205, 216, 228, 0.1) !important; }

.btn.dashed:not(.disabled):active, .btn.dashed:not(.disabled).active { background-color: rgba(205, 216, 228, 0.25) !important; }

/* color inputs */
.color-input { font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace; font-size: 0.9em !important; }

.color { display: inline-block; position: relative; vertical-align: middle; width: 34px; height: 34px; border-radius: 17px; padding: 0; }

.color:not(.static) { cursor: pointer; }

.color:not(.small) { background-image: linear-gradient(45deg, #e4edf6 25%, transparent 25%), linear-gradient(135deg, #e4edf6 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #e4edf6 75%), linear-gradient(135deg, transparent 75%, #e4edf6 75%); background-size: 17px 17px; background-position: 0 0, 8.5px 0, 8.5px -8.5px, 0 8.5px; }

.color.small { width: 16px; height: 16px; background-image: linear-gradient(45deg, #e4edf6 25%, transparent 25%), linear-gradient(135deg, #e4edf6 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #e4edf6 75%), linear-gradient(135deg, transparent 75%, #e4edf6 75%); background-size: 8px 8px; background-position: 0 0, 4px 0, 4px -4px, 0 4px; }

.color .color-preview { position: absolute; top: 0; width: 100%; height: 100%; border-radius: 17px; box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.15); }

body.ltr .color .color-preview { left: 0; }

body.rtl .color .color-preview { right: 0; }

.color .color-preview > .color-preview-input { position: absolute; width: 100%; height: 100%; margin: 0; padding: 0; border: none; opacity: 0; }

body.ltr .color .color-preview > .color-preview-input { left: 0; }

body.rtl .color .color-preview > .color-preview-input { right: 0; }

.colorhex { display: inline-block; margin-left: 5px; vertical-align: middle; color: #596673; }

/* lightswitch */
.lightswitch-outer-container { display: flex; }

.lightswitch-outer-container .lightswitch-inner-container { border: 1px solid rgba(51, 64, 77, 0.1); border-radius: 3px; display: flex; align-items: center; }

.lightswitch-outer-container .lightswitch-inner-container span { padding: 7px 0; color: #596673; cursor: default; }

body.ltr .lightswitch-outer-container .lightswitch-inner-container span[data-toggle=on] { padding-right: 10px; }

body.rtl .lightswitch-outer-container .lightswitch-inner-container span[data-toggle=on] { padding-left: 10px; }

body.ltr .lightswitch-outer-container .lightswitch-inner-container span[data-toggle=on] { margin-left: 7px; }

body.rtl .lightswitch-outer-container .lightswitch-inner-container span[data-toggle=on] { margin-right: 7px; }

body.ltr .lightswitch-outer-container .lightswitch-inner-container span[data-toggle=off] { padding-left: 10px; }

body.rtl .lightswitch-outer-container .lightswitch-inner-container span[data-toggle=off] { padding-right: 10px; }

body.ltr .lightswitch-outer-container .lightswitch-inner-container span[data-toggle=off] { margin-right: 7px; }

body.rtl .lightswitch-outer-container .lightswitch-inner-container span[data-toggle=off] { margin-left: 7px; }

.lightswitch { position: relative; border: none !important; overflow: hidden; cursor: pointer; user-select: none; background-image: linear-gradient(to right, #9aa5b1, #9aa5b1); transition: background-image linear 100ms; }

.lightswitch.on { background-image: linear-gradient(to right, #27AB83, #27AB83); }

.lightswitch.indeterminate { background-image: linear-gradient(to right, #27AB83, #9aa5b1); }

.lightswitch .lightswitch-container { position: relative; height: 100%; }

.lightswitch .lightswitch-container .handle { position: absolute; top: 1px; background-color: #fff; }

.lightswitch:not(.small) { border-radius: 11px; width: 34px; height: 22px; }

.lightswitch:not(.small) .lightswitch-container { width: 46px; }

body.ltr .lightswitch:not(.small) .lightswitch-container { margin-left: -12px; }

body.rtl .lightswitch:not(.small) .lightswitch-container { margin-right: -12px; }

.lightswitch:not(.small) .lightswitch-container .handle { border-radius: 10px; width: 20px; height: 20px; left: calc(50% - 10px); }

.lightswitch.small { border-radius: 9px; width: 28px; height: 18px; }

.lightswitch.small .lightswitch-container { width: 38px; }

body.ltr .lightswitch.small .lightswitch-container { margin-left: -10px; }

body.rtl .lightswitch.small .lightswitch-container { margin-right: -10px; }

.lightswitch.small .lightswitch-container .handle { border-radius: 8px; width: 16px; height: 16px; left: calc(50% - 8px); }

table .lightswitch { display: inline-block; margin-bottom: -5px; }

.lightswitch:focus { outline: none; }

.lightswitch:focus .lightswitch-container .handle { background-color: #cdd8e4; }

body.ltr .lightswitch.on .lightswitch-container { margin-left: 0; }

body.rtl .lightswitch.on .lightswitch-container { margin-right: 0; }

body.ltr .lightswitch.indeterminate:not(.small) .lightswitch-container { margin-left: -6px; }

body.rtl .lightswitch.indeterminate:not(.small) .lightswitch-container { margin-right: -6px; }

body.ltr .lightswitch.indeterminate.small .lightswitch-container { margin-left: -5px; }

body.rtl .lightswitch.indeterminate.small .lightswitch-container { margin-right: -5px; }

/* pagination */
table.data + .pagination { margin-top: 24px; }

.pagination .page-link { width: 32px; height: 32px; display: flex; align-items: center; justify-content: center; border-radius: 4px; }

.pagination .page-link:after { position: relative; transition: border-color linear 100ms; }

.pagination .page-link.prev-page:after { display: block; content: '.'; font-size: 0; width: 5px; height: 5px; border: solid #606d7b; border-width: 0 2px 2px 0; }

body.ltr .pagination .page-link.prev-page:after { transform: rotate(135deg); }

body.rtl .pagination .page-link.prev-page:after { transform: rotate(-45deg); }

body.ltr .pagination .page-link.prev-page:after { right: -1px; }

body.rtl .pagination .page-link.prev-page:after { left: -1px; }

.pagination .page-link.next-page:after { display: block; content: '.'; font-size: 0; width: 5px; height: 5px; border: solid #606d7b; border-width: 0 2px 2px 0; }

body.ltr .pagination .page-link.next-page:after { transform: rotate(-45deg); }

body.rtl .pagination .page-link.next-page:after { transform: rotate(135deg); }

body.ltr .pagination .page-link.next-page:after { left: -1px; }

body.rtl .pagination .page-link.next-page:after { right: -1px; }

.pagination .page-link:not(.disabled) { transition: box-shadow linear 100ms; box-shadow: inset 0 0 0 1px rgba(51, 64, 77, 0.1); cursor: pointer; }

.pagination .page-link:not(.disabled):hover { text-decoration: none; box-shadow: inset 0 0 0 1px #0B69A3; }

.pagination .page-link:not(.disabled):hover:after { border-color: #0B69A3; }

.pagination .page-link.disabled { opacity: 1; }

.pagination .page-link.disabled:after { border-color: rgba(51, 64, 77, 0.1); }

/* action buttons */
body.ltr .actions { float: right; }

body.rtl .actions { float: left; }

body.ltr .actions > li { float: left; }

body.rtl .actions > li { float: right; }

body.ltr .actions > li + li { margin-left: 10px; }

body.rtl .actions > li + li { margin-right: 10px; }

h1 + .actions { margin-top: -100px; }

h2 + .actions { margin-top: -54px; }

/* ----------------------------------------
/*  Tables
/* ----------------------------------------*/
.tablepane { margin: -24px -24px -12px; overflow-x: auto; }

body.ltr .tablepane table th:first-child, body.ltr .tablepane table td:first-child { padding-left: 24px; }

body.rtl .tablepane table th:first-child, body.rtl .tablepane table td:first-child { padding-right: 24px; }

body.ltr .tablepane table th:last-child, body.ltr .tablepane table td:last-child { padding-right: 24px; }

body.rtl .tablepane table th:last-child, body.rtl .tablepane table td:last-child { padding-left: 24px; }

body.ltr .tablepane table thead th, body.ltr .tablepane table thead td { border-bottom-left-radius: 0; }

body.rtl .tablepane table thead th, body.rtl .tablepane table thead td { border-bottom-right-radius: 0; }

body.ltr .tablepane table thead th, body.ltr .tablepane table thead td { border-bottom-right-radius: 0; }

body.rtl .tablepane table thead th, body.rtl .tablepane table thead td { border-bottom-left-radius: 0; }

body.ltr .tablepane table thead:first-child tr:first-child th:first-child, body.ltr .tablepane table thead:first-child tr:first-child td:first-child, body.ltr .tablepane table tbody:first-child tr:first-child th:first-child, body.ltr .tablepane table tbody:first-child tr:first-child td:first-child, body.ltr .tablepane table tfoot:first-child tr:first-child th:first-child, body.ltr .tablepane table tfoot:first-child tr:first-child td:first-child { border-top-left-radius: 5px; }

body.rtl .tablepane table thead:first-child tr:first-child th:first-child, body.rtl .tablepane table thead:first-child tr:first-child td:first-child, body.rtl .tablepane table tbody:first-child tr:first-child th:first-child, body.rtl .tablepane table tbody:first-child tr:first-child td:first-child, body.rtl .tablepane table tfoot:first-child tr:first-child th:first-child, body.rtl .tablepane table tfoot:first-child tr:first-child td:first-child { border-top-right-radius: 5px; }

body.ltr .tablepane table thead:first-child tr:first-child th:last-child, body.ltr .tablepane table thead:first-child tr:first-child td:last-child, body.ltr .tablepane table tbody:first-child tr:first-child th:last-child, body.ltr .tablepane table tbody:first-child tr:first-child td:last-child, body.ltr .tablepane table tfoot:first-child tr:first-child th:last-child, body.ltr .tablepane table tfoot:first-child tr:first-child td:last-child { border-top-right-radius: 5px; }

body.rtl .tablepane table thead:first-child tr:first-child th:last-child, body.rtl .tablepane table thead:first-child tr:first-child td:last-child, body.rtl .tablepane table tbody:first-child tr:first-child th:last-child, body.rtl .tablepane table tbody:first-child tr:first-child td:last-child, body.rtl .tablepane table tfoot:first-child tr:first-child th:last-child, body.rtl .tablepane table tfoot:first-child tr:first-child td:last-child { border-top-left-radius: 5px; }

body.ltr .tablepane table thead:last-child tr:last-child th:first-child, body.ltr .tablepane table thead:last-child tr:last-child td:first-child, body.ltr .tablepane table tbody:last-child tr:last-child th:first-child, body.ltr .tablepane table tbody:last-child tr:last-child td:first-child, body.ltr .tablepane table tfoot:last-child tr:last-child th:first-child, body.ltr .tablepane table tfoot:last-child tr:last-child td:first-child { border-bottom-left-radius: 5px; }

body.rtl .tablepane table thead:last-child tr:last-child th:first-child, body.rtl .tablepane table thead:last-child tr:last-child td:first-child, body.rtl .tablepane table tbody:last-child tr:last-child th:first-child, body.rtl .tablepane table tbody:last-child tr:last-child td:first-child, body.rtl .tablepane table tfoot:last-child tr:last-child th:first-child, body.rtl .tablepane table tfoot:last-child tr:last-child td:first-child { border-bottom-right-radius: 5px; }

body.ltr .tablepane table thead:last-child tr:last-child th:last-child, body.ltr .tablepane table thead:last-child tr:last-child td:last-child, body.ltr .tablepane table tbody:last-child tr:last-child th:last-child, body.ltr .tablepane table tbody:last-child tr:last-child td:last-child, body.ltr .tablepane table tfoot:last-child tr:last-child th:last-child, body.ltr .tablepane table tfoot:last-child tr:last-child td:last-child { border-bottom-right-radius: 5px; }

body.rtl .tablepane table thead:last-child tr:last-child th:last-child, body.rtl .tablepane table thead:last-child tr:last-child td:last-child, body.rtl .tablepane table tbody:last-child tr:last-child th:last-child, body.rtl .tablepane table tbody:last-child tr:last-child td:last-child, body.rtl .tablepane table tfoot:last-child tr:last-child th:last-child, body.rtl .tablepane table tfoot:last-child tr:last-child td:last-child { border-bottom-left-radius: 5px; }

table.fixed-layout { table-layout: fixed; }

table th.thin, table td.thin { width: 0.01% !important; white-space: nowrap; }

table thead th { font-weight: bold; vertical-align: top; }

body.ltr table thead th { text-align: left; }

body.rtl table thead th { text-align: right; }

table:not(.data) th, table:not(.data) td { padding-top: 7px; padding-bottom: 7px; }

table:not(.data) th:not(:first-child), table:not(.data) td:not(:first-child) { padding-left: 12px; }

table:not(.data) th:not(:last-child), table:not(.data) td:not(:last-child) { padding-right: 12px; }

table.data th, table.data td { position: relative; padding-left: 12px; padding-right: 12px; box-sizing: border-box; }

table.data th.checkbox-cell, table.data td.checkbox-cell { width: 16px !important; min-width: 16px; box-sizing: content-box; position: relative; }

table.data th.checkbox-cell input.checkbox + label, table.data th.checkbox-cell div.checkbox, table.data td.checkbox-cell input.checkbox + label, table.data td.checkbox-cell div.checkbox { position: absolute; top: calc(50% - 8px); }

table.data th { font-weight: bold; }

table.data thead th, table.data thead td { padding-top: 14px; padding-bottom: 14px; width: auto; background-color: #f3f7fc; cursor: default; }

body.ltr table.data thead th:first-child, body.ltr table.data thead td:first-child { border-top-left-radius: 5px; }

body.rtl table.data thead th:first-child, body.rtl table.data thead td:first-child { border-top-right-radius: 5px; }

body.ltr table.data thead th:first-child, body.ltr table.data thead td:first-child { border-bottom-left-radius: 5px; }

body.rtl table.data thead th:first-child, body.rtl table.data thead td:first-child { border-bottom-right-radius: 5px; }

body.ltr table.data thead th:last-child, body.ltr table.data thead td:last-child { border-top-right-radius: 5px; }

body.rtl table.data thead th:last-child, body.rtl table.data thead td:last-child { border-top-left-radius: 5px; }

body.ltr table.data thead th:last-child, body.ltr table.data thead td:last-child { border-bottom-right-radius: 5px; }

body.rtl table.data thead th:last-child, body.rtl table.data thead td:last-child { border-bottom-left-radius: 5px; }

table.data thead th { white-space: nowrap; }

table.data thead th.orderable:not(.ordered):hover { background-color: #e4edf6; }

table.data thead th.ordered { background-color: #cdd8e4; }

table.data thead th.ordered { position: relative; }

body.ltr table.data thead th.ordered { padding-right: 26px; }

body.rtl table.data thead th.ordered { padding-left: 26px; }

body.ltr table.data thead th.ordered:first-child { padding-left: 10px; }

body.rtl table.data thead th.ordered:first-child { padding-right: 10px; }

table.data thead th.ordered:not(.loading):after { display: block; content: '.'; font-size: 0; width: 5px; height: 5px; border: solid #596673; border-width: 0 2px 2px 0; transform: rotate(225deg); position: absolute; top: calc(50% - 3px); }

body.ltr table.data thead th.ordered:not(.loading):after { right: 10px; }

body.rtl table.data thead th.ordered:not(.loading):after { left: 10px; }

table.data thead th.ordered:not(.loading).desc:after { transform: rotate(45deg); }

table.data thead th.ordered.loading { background-image: url(../images/spinner_tableheader.gif); background-repeat: no-repeat; }

body.ltr table.data thead th.ordered.loading { background-position: 100% 16px; }

body.rtl table.data thead th.ordered.loading { background-position: 0 16px; }

table.data tbody tr { outline: none; }

table.data tbody tr:not(.disabled):hover th, table.data tbody tr:not(.disabled):hover td { background-color: #f3f7fc; }

table.data tbody tr:not(.disabled).sel th, table.data tbody tr:not(.disabled).sel td { background-color: #cdd8e4; }

table.data tbody th, table.data tbody td { padding-top: 7px; padding-bottom: 7px; background-clip: padding-box; }

table.data tbody td.timestamp { vertical-align: bottom; white-space: nowrap; color: #606d7b; }

body.ltr table.data tbody td.timestamp { text-align: right; }

body.rtl table.data tbody td.timestamp { text-align: left; }

table.data thead + tbody tr th, table.data thead + tbody tr td, table.data tr + tr th, table.data tr + tr td { border-top: 1px solid transparent; }

table.collapsed { width: auto; }

table.collapsed, table.collapsed tbody, table.collapsed tbody tr, table.collapsed tbody th, table.collapsed tbody td { display: block; border: none; padding: 0; width: auto !important; white-space: normal; }

body.ltr table.collapsed, body.ltr table.collapsed tbody, body.ltr table.collapsed tbody tr, body.ltr table.collapsed tbody th, body.ltr table.collapsed tbody td { text-align: left; }

body.rtl table.collapsed, body.rtl table.collapsed tbody, body.rtl table.collapsed tbody tr, body.rtl table.collapsed tbody th, body.rtl table.collapsed tbody td { text-align: right; }

table.collapsed thead { display: none; }

table.collapsed tbody tr { padding: 6px 0; border-bottom: 1px dotted rgba(51, 64, 77, 0.1); }

table.collapsed tbody tr:after { content: '.'; display: block; height: 0; clear: both; visibility: hidden; }

table.collapsed tbody th, table.collapsed tbody td { padding: 2px 0 !important; }

table.collapsed tbody td:empty { display: none; }

body.ltr table.collapsed tbody [data-title] { margin-right: 0; }

body.rtl table.collapsed tbody [data-title] { margin-left: 0; }

table.collapsed tbody [data-title]:before { margin-right: 5px; content: attr(data-title) ":"; font-weight: bold; }

table.collapsed tbody [data-title] form { display: inline-block; }

.datatablesorthelper, .editabletablesorthelper, .thumbviewhelper { background-color: #fff; box-shadow: 0 1px 5px -1px rgba(31, 41, 51, 0.2); }

.datatablesorthelper, .datatablesorthelper .element, .datatablesorthelper a { cursor: move !important; }

.datatablesorthelper tr:first-child th, .datatablesorthelper tr:first-child td { border-top: none !important; }

.datatablesorthelper tr:last-child th, .datatablesorthelper tr:last-child td { border-bottom: none !important; }

/* elements */
.element { position: relative; cursor: default; outline: none !important; user-select: none; font-weight: normal; }

.element:focus, li:focus .element { background-color: #f3f7fc; }

.element.sel, li.sel .element { background-color: #cdd8e4 !important; border-radius: 3px; cursor: default; }

.element.sel:focus, li.sel .element:focus { background-color: #bdcbdb !important; }

.element.sel.loading:after, li.sel .element.loading:after { background-image: url(../images/spinner_element.gif); }

.element.sel.hasthumb .elementthumb img, li.sel .element.hasthumb .elementthumb img { box-shadow: 0 0 0 1px rgba(81, 95, 108, 0.1), 0 6px 4px -4px rgba(81, 95, 108, 0.2); }

.element.hasthumb .elementthumb { position: absolute; display: flex; justify-content: center; -ms-flex-pack: center; align-items: center; -ms-flex-align: center; }

.element.hasthumb .elementthumb img { display: block; flex-shrink: 0; pointer-events: none; border-radius: 3px; max-width: 100%; max-height: 100%; }

.element.hasthumb .elementthumb.rounded img { border-radius: 50%; }

.element .label { display: inline-block; }

.element .label .draft-label { display: inline-block; padding: 1px 5px; font-weight: normal; text-decoration: none !important; color: #606d7b; background: #e4edf6; border-radius: 5px; }

body.ltr .element .label .draft-label { margin: -1px 0 -1px 7px ; }

body.rtl .element .label .draft-label { margin: -1px 7px -1px 0 ; }

.element.small, .element.large:not(.hasthumb) { display: inline-block; padding: 7px; box-sizing: border-box; }

body.ltr .element.small.hasstatus, body.ltr .element.large:not(.hasthumb).hasstatus { padding-left: 22px; }

body.rtl .element.small.hasstatus, body.rtl .element.large:not(.hasthumb).hasstatus { padding-right: 22px; }

.element.small.hasstatus .status, .element.large:not(.hasthumb).hasstatus .status { position: absolute; top: calc(50% - 5px); }

body.ltr .element.small.hasstatus .status, body.ltr .element.large:not(.hasthumb).hasstatus .status { left: 7px; }

body.rtl .element.small.hasstatus .status, body.rtl .element.large:not(.hasthumb).hasstatus .status { right: 7px; }

.element.small.hasstatus .icon:not(.delete), .element.large:not(.hasthumb).hasstatus .icon:not(.delete) { position: absolute; top: calc(50% - 11px); }

body.ltr .element.small.hasstatus .icon:not(.delete), body.ltr .element.large:not(.hasthumb).hasstatus .icon:not(.delete) { left: 6px; }

body.rtl .element.small.hasstatus .icon:not(.delete), body.rtl .element.large:not(.hasthumb).hasstatus .icon:not(.delete) { right: 6px; }

body.ltr .element.small.hasthumb, body.ltr .element.large:not(.hasthumb).hasthumb { padding-left: 39px; }

body.rtl .element.small.hasthumb, body.rtl .element.large:not(.hasthumb).hasthumb { padding-right: 39px; }

.element.small.hasthumb .elementthumb, .element.large:not(.hasthumb).hasthumb .elementthumb { top: calc(50% - 17px); width: 34px; height: 34px; }

body.ltr .element.small.hasthumb .elementthumb, body.ltr .element.large:not(.hasthumb).hasthumb .elementthumb { left: 0; }

body.rtl .element.small.hasthumb .elementthumb, body.rtl .element.large:not(.hasthumb).hasthumb .elementthumb { right: 0; }

.element.small.hasthumb .elementthumb.checkered img, .element.large:not(.hasthumb).hasthumb .elementthumb.checkered img { background-image: linear-gradient(45deg, #e4edf6 25%, transparent 25%), linear-gradient(135deg, #e4edf6 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #e4edf6 75%), linear-gradient(135deg, transparent 75%, #e4edf6 75%); background-size: 8px 8px; background-position: 0 0, 4px 0, 4px -4px, 0 4px; }

body.ltr .element.small.hasthumb.hasstatus, body.ltr .element.large:not(.hasthumb).hasthumb.hasstatus { padding-left: 54px; }

body.rtl .element.small.hasthumb.hasstatus, body.rtl .element.large:not(.hasthumb).hasthumb.hasstatus { padding-right: 54px; }

body.ltr .element.small.hasthumb.hasstatus .status, body.ltr .element.large:not(.hasthumb).hasthumb.hasstatus .status { left: 39px; }

body.rtl .element.small.hasthumb.hasstatus .status, body.rtl .element.large:not(.hasthumb).hasthumb.hasstatus .status { right: 39px; }

.element.large.hasthumb { display: block; padding: 132px 7px 7px; width: 134px; box-sizing: border-box; }

body.ltr .element.large.hasthumb.hasstatus { padding-left: 22px; }

body.rtl .element.large.hasthumb.hasstatus { padding-right: 22px; }

.element.large.hasthumb.hasstatus .status { position: absolute; top: 138px; }

body.ltr .element.large.hasthumb.hasstatus .status { left: 7px; }

body.rtl .element.large.hasthumb.hasstatus .status { right: 7px; }

.element.large.hasthumb .elementthumb { top: 7px; width: 120px; height: 120px; }

body.ltr .element.large.hasthumb .elementthumb { left: 7px; }

body.rtl .element.large.hasthumb .elementthumb { right: 7px; }

.element.large.hasthumb .elementthumb.checkered img { background-image: linear-gradient(45deg, #e4edf6 25%, transparent 25%), linear-gradient(135deg, #e4edf6 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #e4edf6 75%), linear-gradient(135deg, transparent 75%, #e4edf6 75%); background-size: 15px 15px; background-position: 0 0, 7.5px 0, 7.5px -7.5px, 0 7.5px; }

.element.large.hasthumb .label { display: block; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; word-wrap: normal; }

body.ltr .element.removable .label { padding-right: 20px; }

body.rtl .element.removable .label { padding-left: 20px; }

.element.removable .delete:before { color: rgba(123, 135, 147, 0.5); }

.element.removable.small .delete, .element.removable.large:not(.hasthumb) .delete { position: absolute; top: calc(50% - 11px); }

body.ltr .element.removable.small .delete, body.ltr .element.removable.large:not(.hasthumb) .delete { right: 7px; }

body.rtl .element.removable.small .delete, body.rtl .element.removable.large:not(.hasthumb) .delete { left: 7px; }

.element.removable.large.hasthumb .delete { position: absolute; }

body.ltr .element.removable.large.hasthumb .delete { right: 7px; }

body.rtl .element.removable.large.hasthumb .delete { left: 7px; }

body.ltr .element.loading { padding-right: 31px; }

body.rtl .element.loading { padding-left: 31px; }

.element.loading:after { content: ''; font-size: 0; position: absolute; bottom: 0; width: 24px; background: url(../images/spinner.gif) no-repeat 50% 50%; height: 32px; }

body.ltr .element.loading:after { right: 3px; }

body.rtl .element.loading:after { left: 3px; }

.element.loading.removable .delete { display: none; }

.elements { position: relative; }

.elements.busy { min-height: 200px; }

.elements.busy:after { display: block; content: ''; font-size: 0; position: absolute; top: 0; left: -24px; width: calc(100% + 48px); height: 100%; background: rgba(255, 255, 255, 0.75) url(../images/spinner.gif) no-repeat 50% 50%; border-radius: 5px; }

.elements .header { margin: -24px -24px 24px; padding: 14px 24px; background-color: #f3f7fc; box-shadow: none; }

.elements .header:after { content: ''; }

.elements .header .selectallcontainer { cursor: default; }

.elements .header .selectallcontainer:focus { outline: none; }

body.ltr .elements .header .selectallcontainer .checkbox { margin-right: 5px; }

body.rtl .elements .header .selectallcontainer .checkbox { margin-left: 5px; }

.elements .tableview thead th.selectallcontainer { outline: none; }

.elements .tableview .move { display: block; position: absolute; top: calc(50% - 11px); font-size: 11px; text-decoration: none; }

body.ltr .elements .tableview .move { margin-left: -14px; }

body.rtl .elements .tableview .move { margin-right: -14px; }

.elements .tableview .toggle { display: block; position: absolute; top: calc(50% - 7px); padding: 4px; }

body.ltr .elements .tableview .toggle { margin-left: -16px; }

body.rtl .elements .tableview .toggle { margin-right: -16px; }

body.ltr .elements .tableview .move + .toggle { margin-left: -34px; }

body.rtl .elements .tableview .move + .toggle { margin-right: -34px; }

.elements .thumbsview { width: calc(100% + 15px); display: flex; flex-direction: row; flex-wrap: wrap; }

body.ltr .elements .thumbsview { margin: -7px -8px -8px -7px ; }

body.rtl .elements .thumbsview { margin: -7px -7px -8px -8px ; }

.elements .thumbsview li { position: relative; outline: none; }

body.ltr .elements .thumbsview li { margin: 0 1px 1px 0 ; }

body.rtl .elements .thumbsview li { margin: 0 0 1px 1px ; }

.elements .thumbsview li:hover .element { background-color: #f3f7fc; }

body.ltr .elements .thumbsview li.has-checkbox .element.hasthumb:not(.hasstatus) { padding-left: 28px; }

body.rtl .elements .thumbsview li.has-checkbox .element.hasthumb:not(.hasstatus) { padding-right: 28px; }

body.ltr .elements .thumbsview li.has-checkbox .element.hasthumb.hasstatus { padding-left: 43px; }

body.rtl .elements .thumbsview li.has-checkbox .element.hasthumb.hasstatus { padding-right: 43px; }

body.ltr .elements .thumbsview li.has-checkbox .element.hasthumb.hasstatus .status { left: 28px; }

body.rtl .elements .thumbsview li.has-checkbox .element.hasthumb.hasstatus .status { right: 28px; }

.elements .thumbsview li.has-checkbox .checkbox { position: absolute; top: 134px; }

body.ltr .elements .thumbsview li.has-checkbox .checkbox { left: 7px; }

body.rtl .elements .thumbsview li.has-checkbox .checkbox { right: 7px; }

.export-form { position: relative; }

.export-form .spinner { position: absolute; bottom: 0; }

body.ltr .export-form .spinner { right: -24px; }

body.rtl .export-form .spinner { left: -24px; }

.thumbviewhelper { margin: -7px; padding: 7px; }

.thumbviewhelper .thumbsview, .thumbviewhelper .thumbsview li { margin: 0 !important; }

/* structures */
.structure { position: relative; z-index: 1; }

body.ltr .structure li { padding-left: 8px; }

body.rtl .structure li { padding-right: 8px; }

.structure li.collapsed > ul { display: none; }

.structure li .row:hover > .icon, .structure li.draghelper > .row .move, .structure li .add.active { opacity: 1; }

.structure li.draghelper > .row .add { opacity: 0; }

.structure li.draghelper > .row .move:before { color: #0B69A3; }

.structure li.draginsertion { position: relative; height: 2px; background-color: #0B69A3 !important; border-radius: 1px; }

body.ltr .structure li.draginsertion { margin: -1px 0 -1px 8px ; }

body.rtl .structure li.draginsertion { margin: -1px 8px -1px 0 ; }

body.ltr .structure li.draginsertion { padding-left: 0; }

body.rtl .structure li.draginsertion { padding-right: 0; }

body.ltr .structure li.draginsertion { border-left: none; }

body.rtl .structure li.draginsertion { border-right: none; }

.structure li .toggle { position: relative; z-index: 1; padding: 4px; }

body.ltr .structure li .toggle { float: left; }

body.rtl .structure li .toggle { float: right; }

body.ltr .structure li .toggle { margin: 10px -8px 0 -12px ; }

body.rtl .structure li .toggle { margin: 10px -12px 0 -8px ; }

.structure li .row:after { content: '.'; display: block; height: 0; clear: both; visibility: hidden; }

.structure li .move, .structure li .add { opacity: 0; transition: opacity linear 100ms; }

body.ltr .structure li .move, body.ltr .structure li .add { margin: 5px 5px 0 0 ; }

body.rtl .structure li .move, body.rtl .structure li .add { margin: 5px 0 0 5px ; }

.structure li .add { padding: 0 5px; }

.structure li .add:before { content: 'downangle'; color: rgba(123, 135, 147, 0.5); }

.structure li .add:not(.disabled):hover:before, .structure li .add.active:before { color: #0B69A3; }

body.ltr .structure li .checkbox { float: left; }

body.rtl .structure li .checkbox { float: right; }

body.ltr .structure li .checkbox { margin: 7px 0 0 7px ; }

body.rtl .structure li .checkbox { margin: 7px 7px 0 0 ; }

body.ltr .structure ul { margin-left: -3px; }

body.rtl .structure ul { margin-right: -3px; }

.structure ul li { background-repeat: no-repeat; }

body.ltr .structure ul li { padding-left: 38px; }

body.rtl .structure ul li { padding-right: 38px; }

body.ltr .structure ul li { background-image: url(../images/branch.png); background-position: 0 0; }

body.rtl .structure ul li { background-image: url(../images/branch_rtl.png); background-position: 100% 0; }

body.ltr .structure ul li:not(:last-child):not(.last) { padding-left: 37px; }

body.rtl .structure ul li:not(:last-child):not(.last) { padding-right: 37px; }

body.ltr .structure ul li:not(:last-child):not(.last) { border-left: 1px solid rgba(51, 64, 77, 0.1); }

body.rtl .structure ul li:not(:last-child):not(.last) { border-right: 1px solid rgba(51, 64, 77, 0.1); }

body.ltr .structure ul li:not(:last-child):not(.last) { background-position: -1px 0; }

body.rtl .structure ul li:not(:last-child):not(.last) { background-position: calc(100% + 1px) 0; }

body.ltr .structure ul li.draginsertion { margin-left: 38px; }

body.rtl .structure ul li.draginsertion { margin-right: 38px; }

.structure .row.draghover .element { z-index: 2; border-radius: 15px; box-shadow: inset 0 0 0 2px #0B69A3; }

.structure .row.droptarget { border-radius: 5px; box-shadow: inset 0 0 0 2px #0B69A3; }

/* element select fields */
.elementselect { position: relative; min-height: 37px; margin-top: -7px; }

.elementselect:after { content: '.'; display: block; height: 0; clear: both; visibility: hidden; }

body.ltr .elementselect .element, body.ltr .elementselect .btn { float: left; }

body.rtl .elementselect .element, body.rtl .elementselect .btn { float: right; }

body.ltr .elementselect .element, body.ltr .elementselect .btn { margin: 7px 7px 0 0 ; }

body.rtl .elementselect .element, body.rtl .elementselect .btn { margin: 7px 0 0 7px ; }

.elementselect .element.small, .elementselect .flex, .elementselect .btn { clear: both; }

.elementselect .element { z-index: 1; }

.elementselect .element.small { max-width: 100%; }

.elementselect .element.small .label { display: block; max-width: 100%; box-sizing: border-box; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }

body.ltr .elementselect .caboose { float: left; }

body.rtl .elementselect .caboose { float: right; }

.elementselect .flex .btn { float: none !important; }

/* editable tables */
table.editable { border-radius: 5px; border: 1px solid #cdd8e4; }

table.editable th, table.editable td.action { color: #596673; font-weight: normal; background-color: #f3f7fc; }

table.editable thead tr th, table.editable tbody tr th { padding: 6px 10px; }

table.editable thead tr th { border-bottom: 1px solid rgba(51, 64, 77, 0.1); }

body.ltr table.editable thead tr th.has-info { padding-right: calc(15px + 1em); }

body.rtl table.editable thead tr th.has-info { padding-left: calc(15px + 1em); }

table.editable thead tr th span.info { position: absolute; margin-left: 5px; }

table.editable tbody tr:not(:first-child) th, table.editable tbody tr:not(:first-child) td { border-top: 1px solid rgba(51, 64, 77, 0.1); }

body.ltr table.editable tbody tr:last-child td:first-child { border-bottom-left-radius: 5px; }

body.rtl table.editable tbody tr:last-child td:first-child { border-bottom-right-radius: 5px; }

body.ltr table.editable tbody tr:last-child td:first-child textarea, body.ltr table.editable tbody tr:last-child td:first-child input.text { border-bottom-left-radius: 4px; }

body.rtl table.editable tbody tr:last-child td:first-child textarea, body.rtl table.editable tbody tr:last-child td:first-child input.text { border-bottom-right-radius: 4px; }

body.ltr table.editable tbody tr td:not(:first-child), body.ltr table.editable tbody tr th ~ td:not(.hidden) ~ td { border-left: 1px solid rgba(51, 64, 77, 0.1); }

body.rtl table.editable tbody tr td:not(:first-child), body.rtl table.editable tbody tr th ~ td:not(.hidden) ~ td { border-right: 1px solid rgba(51, 64, 77, 0.1); }

body.ltr table.editable tbody tr th ~ td:not(:first-child) { border-left: 1px solid #dbdddf; }

body.rtl table.editable tbody tr th ~ td:not(:first-child) { border-right: 1px solid #dbdddf; }

table.editable tbody tr td { vertical-align: top; text-align: center; background-color: #fff; padding: 4px 10px; }

table.editable tbody tr td.focus { box-shadow: inset 0 0 0 1px rgba(51, 64, 77, 0.1); }

table.editable tbody tr td.textual { padding: 0; }

table.editable tbody tr td.textual textarea { resize: none; }

table.editable tbody tr td.textual pre { white-space: pre-wrap; }

body.ltr table.editable tbody tr td.textual pre { text-align: left; }

body.rtl table.editable tbody tr td.textual pre { text-align: right; }

table.editable tbody tr td.lightswitch-cell { padding-top: 9px; padding-bottom: 9px; }

table.editable tbody tr td.lightswitch-cell .lightswitch { display: block; margin: 0 auto; }

table.editable tbody tr td.checkbox-cell { padding-top: 10px; padding-bottom: 10px; }

table.editable tbody tr td.checkbox-cell .checkbox-wrapper { display: block; margin: -2px auto 0; width: 16px; height: 16px; }

table.editable tbody tr td.error { box-shadow: inset 0 0 0 1px #CF1124; }

table.editable tbody tr td.disabled { position: relative; opacity: 1; }

table.editable tbody tr td.disabled:after { content: ''; font-size: 0; position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(243, 247, 252, 0.75); user-select: none; }

table.editable tbody tr td.action { padding: 4px 7px; }

body.ltr table.editable tbody tr td.action + td.action { border-left: none; }

body.rtl table.editable tbody tr td.action + td.action { border-right: none; }

body.ltr table.editable tbody tr td.action + td.action { padding-left: 0; }

body.rtl table.editable tbody tr td.action + td.action { padding-right: 0; }

table.editable tbody tr td .flex > * { margin-bottom: 0; }

table.editable tbody textarea, table.editable tbody textarea.text, table.editable tbody input.text, table.editable tbody pre { display: block; width: 100%; border: none; box-shadow: none; border-radius: 0; padding: 7px 10px; background-color: transparent; overflow: hidden; transition: none; box-sizing: border-box; }

table.editable tbody textarea:focus, table.editable tbody textarea.text:focus, table.editable tbody input.text:focus, table.editable tbody pre:focus { outline: none; }

table.editable tbody .color-container { display: block; position: relative; }

table.editable tbody .color-container > .color, table.editable tbody .color-container > .color-input { margin-bottom: 0; }

table.editable tbody .color-container > .color { position: absolute; top: 10px; }

body.ltr table.editable tbody .color-container > .color { left: 10px; }

body.rtl table.editable tbody .color-container > .color { right: 10px; }

body.ltr table.editable tbody .color-container > .color-input { padding-left: 35px; }

body.rtl table.editable tbody .color-container > .color-input { padding-right: 35px; }

table.editable tbody .datewrapper, table.editable tbody .timewrapper { display: block; width: 100%; }

table.editable tbody .datewrapper .text:placeholder-shown + div[data-icon], table.editable tbody .timewrapper .text:placeholder-shown + div[data-icon] { top: 6px; }

body.ltr table.editable tbody .datewrapper .text:placeholder-shown + div[data-icon], body.ltr table.editable tbody .timewrapper .text:placeholder-shown + div[data-icon] { left: 10px; }

body.rtl table.editable tbody .datewrapper .text:placeholder-shown + div[data-icon], body.rtl table.editable tbody .timewrapper .text:placeholder-shown + div[data-icon] { right: 10px; }

table.editable:not(.static) td.textual { cursor: text; }

table.editable + .btn.add { display: block; width: 100%; border: 1px dashed rgba(81, 95, 108, 0.25); background-color: transparent; box-shadow: none; }

table.editable + .btn.add:focus { border-style: solid; border-color: #1992D4; }

table.editable:not(.hidden) + .btn.add { border-top-width: 0; border-radius: 0 0 4px 4px; }

.border-box + .buttons, .shadow-box + .buttons { margin-top: 7px; }

/* ----------------------------------------
/*  Nav
/* ----------------------------------------*/
body.ltr ul.tree, body.ltr .tree ul { margin-left: 20px; }

body.rtl ul.tree, body.rtl .tree ul { margin-right: 20px; }

body.ltr .tree li .toggle { margin: 7px 0 0 -15px ; }

body.rtl .tree li .toggle { margin: 7px -15px 0 0 ; }

/* status icons */
.status { display: inline-block; width: 10px; height: 10px; border: 1px solid transparent; border-radius: 100%; box-sizing: border-box; }

body.ltr .status { margin-right: 10px; }

body.rtl .status { margin-left: 10px; }

body.use-shapes .status.pending { background-color: transparent; border-style: solid; border-width: 0 5px 10px 5px; border-color: transparent transparent #CB6E17 transparent; border-radius: 1px; }

body.use-shapes .status.off, body.use-shapes .status.suspended, body.use-shapes .status.expired { border-radius: 1px; }

.status:not(.on):not(.live):not(.active):not(.enabled):not(.pending):not(.off):not(.suspended):not(.expired):not(.yellow):not(.orange):not(.red):not(.pink):not(.purple):not(.blue):not(.green):not(.turquoise):not(.light):not(.grey):not(.black) { border-color: rgba(96, 125, 159, 0.8); }

.green, .status.on, .status.live, .status.active, .status.enabled { background-color: #27AB83; }

/* green */
.orange, .status.pending { background-color: #CB6E17; }

/* orange */
.red, .status.off, .status.suspended, .status.expired { background-color: #CF1124; }

/* red */
.yellow { background-color: #FADB5F; }

.pink { background-color: #E8368F; }

.purple { background-color: #9B59B6; }

.blue { background-color: #1992D4; }

.turquoise { background-color: #65D6AD; }

.status.light { background-color: #e4edf6; }

.grey { background-color: #9aa5b1; }

.black { background-color: #33404d; }

.status.white, .status.disabled { opacity: 1; }

/* ----------------------------------------
/*  Progress bar
/* ----------------------------------------*/
.progressbar { border-radius: 6px; border: 2px solid #3f4d5a; padding: 2px; position: absolute; left: 20%; width: 60%; z-index: 1000; }

.progressbar-inner { border-radius: 2px; height: 4px; background-color: #3f4d5a; }

.progressbar:not(.pending) .progressbar-inner { width: 0; transition: width linear 100ms; }

.progressbar.pending .progressbar-inner { animation-timing-function: linear; animation-duration: 250ms; animation-iteration-count: infinite; }

body.ltr .progressbar.pending .progressbar-inner { background: repeating-linear-gradient(135deg, #fff, #fff 8.83883px, #3f4d5a 8.83883px, #3f4d5a 17.67767px); }

body.rtl .progressbar.pending .progressbar-inner { background: repeating-linear-gradient(45deg, #fff, #fff 8.83883px, #3f4d5a 8.83883px, #3f4d5a 17.67767px); }

body.ltr .progressbar.pending .progressbar-inner { animation-name: pendingprogress-ltr; }

body.rtl .progressbar.pending .progressbar-inner { animation-name: pendingprogress-rtl; }

@keyframes pendingprogress-ltr { from { background-position: 0; }
  to { background-position: 25px; } }

@keyframes pendingprogress-rtl { from { background-position: 0; }
  to { background-position: -25px; } }

.elementselect .progress-shade { background-color: rgba(255, 255, 255, 0.8); width: 100%; height: 100%; position: absolute; top: 0; display: none; }

body.ltr .elementselect .progress-shade { left: 0; }

body.rtl .elementselect .progress-shade { right: 0; }

.elementselect.uploading { position: relative; }

.elementselect.uploading .progress-shade { display: block; z-index: 2; }

.missing-component { padding: 7px 10px !important; max-width: 400px; background-color: #f3f7fc !important; }

.missing-component .error { margin: 0; }

.missing-component .install-plugin { margin: 7px 0 -7px; border-top: 1px solid rgba(51, 64, 77, 0.1); position: relative; }

body.ltr .missing-component .install-plugin { padding: 10px 0 10px 40px ; }

body.rtl .missing-component .install-plugin { padding: 10px 40px 10px 0 ; }

.missing-component .install-plugin .icon { width: 32px; height: 32px; position: absolute; top: calc(50% - 16px); }

body.ltr .missing-component .install-plugin .icon { left: 0; }

body.rtl .missing-component .install-plugin .icon { right: 0; }

.missing-component .install-plugin .icon img, .missing-component .install-plugin .icon svg { width: 100%; height: 100%; }

.missing-component .install-plugin h3 { flex: 1; margin: 8px 0 !important; }

.missing-component .install-plugin .btn { margin: 0; }

/* ----------------------------------------
/*  Panes, Modals and HUDs
/* ----------------------------------------*/
.pane { background: #fff; box-shadow: 0 0 0 1px rgba(205, 216, 228, 0.25), 0 2px 12px rgba(205, 216, 228, 0.5); position: relative; margin: 14px 0; padding: 24px; border-radius: 5px; word-wrap: break-word; box-sizing: border-box; }

.pane .pane, #content .pane { background-color: #f3f7fc; border: 1px solid rgba(51, 64, 77, 0.1); box-shadow: none; }

.pane.loading { min-height: 200px; }

.pane.loading:after { display: block; content: ''; font-size: 0; position: absolute; top: 0; left: -24px; width: calc(100% + 48px); height: 100%; background: url(../images/spinner.gif) no-repeat 50% 50%; }

/* meta panes */
.meta { padding: 0 24px; overflow: visible; }

.meta > .field, .meta > .data, .meta > .flex-fields > .field, .meta > .flex-fields > .data { display: flex; flex-wrap: wrap; justify-content: space-between; align-items: center; margin: 0 -24px !important; padding: 0 24px; transition: padding-left linear 100ms, padding-right linear 100ms; }

body.ltr .meta > .field.nested, body.ltr .meta > .data.nested, body.ltr .meta > .flex-fields > .field.nested, body.ltr .meta > .flex-fields > .data.nested { padding-left: 38px; }

body.rtl .meta > .field.nested, body.rtl .meta > .data.nested, body.rtl .meta > .flex-fields > .field.nested, body.rtl .meta > .flex-fields > .data.nested { padding-right: 38px; }

.meta > .field.add, .meta > .data.add, .meta > .flex-fields > .field.add, .meta > .flex-fields > .data.add { background-color: #ebf2fa; }

.meta > .field.add:before, .meta > .data.add:before, .meta > .flex-fields > .field.add:before, .meta > .flex-fields > .data.add:before { position: absolute; width: 31px; font-family: 'Craft'; speak: none; -webkit-font-feature-settings: "liga", "dlig"; -moz-font-feature-settings: "liga=1, dlig=1"; -moz-font-feature-settings: "liga", "dlig"; -ms-font-feature-settings: "liga", "dlig"; -o-font-feature-settings: "liga", "dlig"; font-feature-settings: "liga", "dlig"; text-rendering: optimizeLegibility; font-weight: normal; font-variant: normal; text-transform: none; line-height: 1; direction: ltr; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; display: inline-block; text-align: center; font-style: normal; vertical-align: middle; word-wrap: normal !important; user-select: none; opacity: 0.8; content: 'plus'; color: #606d7b; }

body.ltr .meta > .field.add:before, body.ltr .meta > .data.add:before, body.ltr .meta > .flex-fields > .field.add:before, body.ltr .meta > .flex-fields > .data.add:before { left: 0; }

body.rtl .meta > .field.add:before, body.rtl .meta > .data.add:before, body.rtl .meta > .flex-fields > .field.add:before, body.rtl .meta > .flex-fields > .data.add:before { right: 0; }

body.ltr .meta > .field.add:before, body.ltr .meta > .data.add:before, body.ltr .meta > .flex-fields > .field.add:before, body.ltr .meta > .flex-fields > .data.add:before { text-align: right; }

body.rtl .meta > .field.add:before, body.rtl .meta > .data.add:before, body.rtl .meta > .flex-fields > .field.add:before, body.rtl .meta > .flex-fields > .data.add:before { text-align: left; }

.meta > .field.add .input, .meta > .data.add .input, .meta > .flex-fields > .field.add .input, .meta > .flex-fields > .data.add .input { width: 100%; }

.meta > .field > .heading, .meta > .data > .heading, .meta > .flex-fields > .field > .heading, .meta > .flex-fields > .data > .heading { flex: 0 0 105px; line-height: 18px; }

body.ltr .meta > .field > .heading, body.ltr .meta > .data > .heading, body.ltr .meta > .flex-fields > .field > .heading, body.ltr .meta > .flex-fields > .data > .heading { margin: 0 7px 0 0 ; }

body.rtl .meta > .field > .heading, body.rtl .meta > .data > .heading, body.rtl .meta > .flex-fields > .field > .heading, body.rtl .meta > .flex-fields > .data > .heading { margin: 0 0 0 7px ; }

.meta > .field.lightswitch-field > .heading, .meta > .data.lightswitch-field > .heading, .meta > .flex-fields > .field.lightswitch-field > .heading, .meta > .flex-fields > .data.lightswitch-field > .heading { flex: 1; }

.meta > .field > .input .flex, .meta > .data > .input .flex, .meta > .flex-fields > .field > .input .flex, .meta > .flex-fields > .data > .input .flex { flex-wrap: nowrap; }

.meta > .field > .input .flex > *, .meta > .data > .input .flex > *, .meta > .flex-fields > .field > .input .flex > *, .meta > .flex-fields > .data > .input .flex > * { margin-bottom: 0; }

.meta > .field > .heading, .meta > .flex-fields > .field > .heading { padding: 14px 0; }

.meta > .field > .heading > .copytextbtn, .meta > .flex-fields > .field > .heading > .copytextbtn { display: none; }

.meta > .data > .heading, .meta > .flex-fields > .data > .heading { padding: 7px 0; }

.meta > .field > .heading > label, .meta > .data > .heading, .meta > .flex-fields > .field > .heading > label, .meta > .flex-fields > .data > .heading { color: #596673; }

.meta > .field > .input, .meta > .data > .value, .meta > .flex-fields > .field > .input, .meta > .flex-fields > .data > .value { padding: 7px 0; width: calc(100% - 112px); }

.meta > .field.lightswitch-field > .input, .meta > .flex-fields > .field.lightswitch-field > .input { flex: 0; width: auto; }

.meta > .field.has-errors, .meta > .flex-fields > .field.has-errors { border: 1px solid #CF1124 !important; }

.meta > .field.has-errors:first-child, .meta > .flex-fields > .field.has-errors:first-child { border-top-left-radius: 5px; border-top-right-radius: 5px; }

.meta > .field.has-errors:last-child, .meta > .flex-fields > .field.has-errors:last-child { border-bottom-left-radius: 5px; border-bottom-right-radius: 5px; }

.meta > .field.has-errors + .field, .meta > .flex-fields > .field.has-errors + .field { border-top: none !important; }

.meta > .field > .heading > label, .meta > .flex-fields > .field > .heading > label { font-weight: normal; }

.meta > .field > .input > .text, .meta > .field > .input > .flex > .text, .meta > .field > .input > .flex > .textwrapper > .text, .meta > .field > .input > .datewrapper > .text, .meta > .field > .input > .timewrapper > .text, .meta > .field > .input > .datetimewrapper > .datewrapper > .text, .meta > .field > .input > .datetimewrapper > .timewrapper > .text, .meta > .flex-fields > .field > .input > .text, .meta > .flex-fields > .field > .input > .flex > .text, .meta > .flex-fields > .field > .input > .flex > .textwrapper > .text, .meta > .flex-fields > .field > .input > .datewrapper > .text, .meta > .flex-fields > .field > .input > .timewrapper > .text, .meta > .flex-fields > .field > .input > .datetimewrapper > .datewrapper > .text, .meta > .flex-fields > .field > .input > .datetimewrapper > .timewrapper > .text { display: block; margin: -7px 0; padding: 14px 0; border-radius: 0; box-shadow: none; background-color: transparent; border: none !important; }

.meta > .field > .input > .datewrapper, .meta > .field > .input > .timewrapper, .meta > .field > .input > .datetimewrapper > .datewrapper, .meta > .field > .input > .datetimewrapper > .timewrapper, .meta > .flex-fields > .field > .input > .datewrapper, .meta > .flex-fields > .field > .input > .timewrapper, .meta > .flex-fields > .field > .input > .datetimewrapper > .datewrapper, .meta > .flex-fields > .field > .input > .datetimewrapper > .timewrapper { background-color: transparent; }

body.ltr .meta > .field > .input > .datewrapper .text:placeholder-shown + div[data-icon], body.ltr .meta > .field > .input > .timewrapper .text:placeholder-shown + div[data-icon], body.ltr .meta > .field > .input > .datetimewrapper > .datewrapper .text:placeholder-shown + div[data-icon], body.ltr .meta > .field > .input > .datetimewrapper > .timewrapper .text:placeholder-shown + div[data-icon], body.ltr .meta > .flex-fields > .field > .input > .datewrapper .text:placeholder-shown + div[data-icon], body.ltr .meta > .flex-fields > .field > .input > .timewrapper .text:placeholder-shown + div[data-icon], body.ltr .meta > .flex-fields > .field > .input > .datetimewrapper > .datewrapper .text:placeholder-shown + div[data-icon], body.ltr .meta > .flex-fields > .field > .input > .datetimewrapper > .timewrapper .text:placeholder-shown + div[data-icon] { left: 0; }

body.rtl .meta > .field > .input > .datewrapper .text:placeholder-shown + div[data-icon], body.rtl .meta > .field > .input > .timewrapper .text:placeholder-shown + div[data-icon], body.rtl .meta > .field > .input > .datetimewrapper > .datewrapper .text:placeholder-shown + div[data-icon], body.rtl .meta > .field > .input > .datetimewrapper > .timewrapper .text:placeholder-shown + div[data-icon], body.rtl .meta > .flex-fields > .field > .input > .datewrapper .text:placeholder-shown + div[data-icon], body.rtl .meta > .flex-fields > .field > .input > .timewrapper .text:placeholder-shown + div[data-icon], body.rtl .meta > .flex-fields > .field > .input > .datetimewrapper > .datewrapper .text:placeholder-shown + div[data-icon], body.rtl .meta > .flex-fields > .field > .input > .datetimewrapper > .timewrapper .text:placeholder-shown + div[data-icon] { right: 0; }

.meta > .field > .input > .datetimewrapper > .datewrapper, .meta > .flex-fields > .field > .input > .datetimewrapper > .datewrapper { width: 55%; }

.meta > .field > .input > .datetimewrapper > .timewrapper, .meta > .flex-fields > .field > .input > .datetimewrapper > .timewrapper { width: 45%; }

body.ltr .meta > .field > .input > .datetimewrapper > .clear-btn, body.ltr .meta > .flex-fields > .field > .input > .datetimewrapper > .clear-btn { margin-right: -24px; }

body.rtl .meta > .field > .input > .datetimewrapper > .clear-btn, body.rtl .meta > .flex-fields > .field > .input > .datetimewrapper > .clear-btn { margin-left: -24px; }

.meta > .field > .input > .datewrapper, .meta > .field > .input > .timewrapper, .meta > .flex-fields > .field > .input > .datewrapper, .meta > .flex-fields > .field > .input > .timewrapper { display: block; width: 100%; }

.meta > .field > ul.errors, .meta > .flex-fields > .field > ul.errors { margin: 0; padding: 0 0 6px; width: 100%; list-style-type: none; }

body.ltr .meta > .field > .clear-btn, body.ltr .meta > .flex-fields > .field > .clear-btn { margin-right: -24px; }

body.rtl .meta > .field > .clear-btn, body.rtl .meta > .flex-fields > .field > .clear-btn { margin-left: -24px; }

.meta > .field:not(:first-child), .meta > .flex-fields + .field { border-top: 1px solid rgba(51, 64, 77, 0.1); }

.meta > .flex-fields h2, .meta > .flex-fields blockquote.note { margin: 0 -24px !important; padding: 14px 24px; background-color: #ebf2fa; }

.meta > .flex-fields blockquote.note { border-radius: 0; border: none; }

.meta > .flex-fields hr { margin: 0 -24px; }

.meta > .field > .input > .select { display: block; margin: -7px 0; width: 100%; border-radius: 0; box-shadow: none; background-color: transparent; }

body.ltr .meta > .field > .input > .select:after { right: 0; }

body.rtl .meta > .field > .input > .select:after { left: 0; }

.meta > .field > .input > .select + .spinner { position: absolute; top: calc(50% - 17px); }

body.ltr .meta > .field > .input > .select + .spinner { right: -24px; }

body.rtl .meta > .field > .input > .select + .spinner { left: -24px; }

.meta > .field > .input > .select select { width: 100%; background-color: transparent; }

body.ltr .meta > .field > .input > .select select { padding: 7px 12px 7px 0 ; }

body.rtl .meta > .field > .input > .select select { padding: 7px 0 7px 12px ; }

.body { position: relative; }

.modal, .hud { z-index: 100; box-sizing: border-box; }

.modal, .hud .body { border-radius: 5px; background-color: #fff; box-shadow: 0 25px 100px rgba(31, 41, 51, 0.5); }

.header:after, .hud-header:after, .footer:after, .hud-footer:after, .body:after { content: '.'; display: block; height: 0; clear: both; visibility: hidden; }

.header, .hud-header, .footer, .hud-footer { position: relative; z-index: 1; box-sizing: border-box; }

.header, .hud-header, .footer, .hud-footer { background-color: #e4edf6; }

.header, .hud-header { border-radius: 5px 5px 0 0; padding: 24px; box-shadow: inset 0 -1px 0 rgba(51, 64, 77, 0.1); }

.header h1, .hud-header h1 { margin: 0; }

.footer, .hud-footer { border-radius: 0 0 5px 5px; padding: 14px 24px; box-shadow: inset 0 1px 0 rgba(51, 64, 77, 0.1); }

.footer.flex > *, .hud-footer.flex > * { margin-bottom: 0; }

.modal .body, .hud .main { padding: 24px; overflow: hidden; box-sizing: border-box; }

.pane .header, .modal .body .header { margin: -24px -24px 24px; }

.pane .footer, .modal .body .footer { margin: 24px -24px -24px; }

.modal-shade, .hud-shade { z-index: 100; position: fixed; top: 0; left: 0; width: 100%; height: 100%; display: none; }

.modal-shade:not(.dark) { background-color: rgba(228, 237, 246, 0.65) !important; }

.modal-shade.dark { background-color: rgba(31, 41, 51, 0.5) !important; }

.modal { position: fixed; width: 66%; height: 66%; min-width: 600px; min-height: 400px; overflow: hidden; }

.modal.fitted { width: auto; height: auto; min-width: 0; min-height: 0; }

body.ltr .modal.alert .body { padding-left: 76px; }

body.rtl .modal.alert .body { padding-right: 76px; }

.modal.alert .body:before { font-family: 'Craft'; speak: none; -webkit-font-feature-settings: "liga", "dlig"; -moz-font-feature-settings: "liga=1, dlig=1"; -moz-font-feature-settings: "liga", "dlig"; -ms-font-feature-settings: "liga", "dlig"; -o-font-feature-settings: "liga", "dlig"; font-feature-settings: "liga", "dlig"; text-rendering: optimizeLegibility; font-weight: normal; font-variant: normal; text-transform: none; line-height: 1; direction: ltr; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; display: inline-block; text-align: center; font-style: normal; vertical-align: middle; word-wrap: normal !important; user-select: none; opacity: 0.8; content: 'alert'; font-size: 40px; color: #606d7b; }

body.ltr .modal.alert .body:before { margin: -6px 0 0 -58px ; }

body.rtl .modal.alert .body:before { margin: -6px -58px 0 0 ; }

body.ltr .modal.alert .body:before { float: left; }

body.rtl .modal.alert .body:before { float: right; }

body.ltr .modal.secure .body { padding-left: 76px; }

body.rtl .modal.secure .body { padding-right: 76px; }

.modal.secure .body:before { font-family: 'Craft'; speak: none; -webkit-font-feature-settings: "liga", "dlig"; -moz-font-feature-settings: "liga=1, dlig=1"; -moz-font-feature-settings: "liga", "dlig"; -ms-font-feature-settings: "liga", "dlig"; -o-font-feature-settings: "liga", "dlig"; font-feature-settings: "liga", "dlig"; text-rendering: optimizeLegibility; font-weight: normal; font-variant: normal; text-transform: none; line-height: 1; direction: ltr; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; display: inline-block; text-align: center; font-style: normal; vertical-align: middle; word-wrap: normal !important; user-select: none; opacity: 0.8; content: 'secure'; font-size: 58px; color: #606d7b; }

body.ltr .modal.secure .body:before { margin: -14px 0 0 -56px ; }

body.rtl .modal.secure .body:before { margin: -14px -56px 0 0 ; }

body.ltr .modal.secure .body:before { float: left; }

body.rtl .modal.secure .body:before { float: right; }

.modal .resizehandle { position: absolute; z-index: 1; bottom: 0; width: 24px; height: 24px; background: no-repeat 50% 50%; cursor: nwse-resize; }

body.ltr .modal .resizehandle { right: 0; }

body.rtl .modal .resizehandle { left: 0; }

body.ltr .modal .resizehandle { background-image: url(../images/resizehandle.png); }

body.rtl .modal .resizehandle { background-image: url(../images/resizehandle_rtl.png); }

.hud { position: absolute; display: none; top: 0; }

.hud.has-footer .tip-bottom { background-image: url(../images/hudtip_bottom_gray.png); }

.hud .tip { position: absolute; z-index: 101; background: no-repeat 0 0; }

.hud .tip-left { left: -15px; width: 15px; height: 30px; background-image: url(../images/hudtip_left.png); }

.hud .tip-top { top: -15px; width: 30px; height: 15px; background-image: url(../images/hudtip_top.png); }

.hud .tip-right { right: -15px; width: 15px; height: 30px; background-image: url(../images/hudtip_right.png); }

.hud .tip-bottom { bottom: -15px; width: 30px; height: 15px; background-image: url(../images/hudtip_bottom.png); }

.hud .hud-header, .hud .hud-footer { padding: 7px 24px; }

.hud .body { overflow: hidden; }

.hud .body ::-webkit-scrollbar { appearance: none; }

.hud .body ::-webkit-scrollbar:vertical { width: 11px; }

.hud .body ::-webkit-scrollbar:horizontal { height: 11px; }

.hud .body ::-webkit-scrollbar-thumb { border-radius: 8px; border: 2px solid transparent; background-color: rgba(0, 0, 0, 0.5); background-clip: content-box; }

.hud .body ::-webkit-scrollbar-track { background-color: #f3f7fc; }

/* inline asset previews */
.preview-thumb-container { position: relative; display: flex; flex-direction: row; align-items: center; height: 190px; background-color: #1f2933; cursor: pointer; }

.preview-thumb-container.loading:after { content: ''; font-size: 0; display: block; position: absolute; width: 100%; height: 100%; left: 0; top: 0; background: rgba(31, 41, 51, 0.8) no-repeat 50% 50% url(../images/spinner_dark.gif); }

#details .preview-thumb-container { margin-bottom: 14px; border-radius: 5px; overflow: hidden; }

.preview-thumb-container .preview-thumb { display: flex; flex-direction: column; align-items: center; width: 100%; }

.preview-thumb-container .preview-thumb img { display: block; max-width: 100%; max-height: 190px; }

.preview-thumb-container .buttons { opacity: 0; position: absolute; top: 10px; margin: 0; transition: opacity linear 100ms; }

body.ltr .preview-thumb-container .buttons { right: 10px; }

body.rtl .preview-thumb-container .buttons { left: 10px; }

.preview-thumb-container .buttons .btn { background-color: #515f6c; color: #fff; -moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; font-weight: 500; }

@supports (backdrop-filter: blur(10px)) { .preview-thumb-container .buttons .btn { background-color: rgba(81, 95, 108, 0.4); backdrop-filter: blur(10px); transition: opacity linear 100ms, backdrop-filter linear 100ms; } }

.preview-thumb-container .buttons .btn:hover { background-color: #606d7b; }

@supports (backdrop-filter: blur(10px)) { .preview-thumb-container .buttons .btn:hover { background-color: rgba(96, 109, 123, 0.7); } }

.preview-thumb-container:hover .buttons { opacity: 1; }

/* element editor HUDs */
@media (min-width: 440px) { .hud .elementeditor { min-width: 380px; } }

.hud .elementeditor { max-width: 600px; }

.hud .elementeditor .hud-header { text-align: center; }

.hud .elementeditor .main { padding: 8px 0; }

.hud .elementeditor .main .meta .preview-thumb-container { margin: -8px -24px 8px; }

.hud .elementeditor .main .meta .field { padding-left: 24px; padding-right: 24px; }

.hud .elementeditor .main .meta .field.has-errors { border-radius: 0; }

/* element selector modals */
.elementselectormodal { padding-bottom: 62px; user-select: none; }

.elementselectormodal .body { position: relative; height: 100%; }

.elementselectormodal .body .spinner.big { position: absolute; top: 50%; left: 50%; margin: -24px 0 0 -24px; }

.elementselectormodal .body .content { height: calc(100% + 48px); }

.elementselectormodal .body .content .sidebar { position: absolute; top: 0; height: 100%; overflow: auto; }

body.ltr .elementselectormodal .body .content .sidebar { margin-left: -249px; }

body.rtl .elementselectormodal .body .content .sidebar { margin-right: -249px; }

.elementselectormodal .body .content .main { margin: -24px; padding: 24px; height: 100%; box-sizing: border-box; overflow: auto; position: relative; }

.elementselectormodal .body .content .main .elements.busy { min-height: calc(100% - 48px); }

.elementselectormodal .body .content .main .elements .tableview table .element { display: inline-block; }

.elementselectormodal .body .content .main .elements .tableview table tr:focus { outline: none; }

.elementselectormodal .body .content .main .elements .tableview table tr.disabled { opacity: 1; color: #cdd8e4; }

.elementselectormodal .body .content .main .elements .tableview table tr.disabled .element { opacity: 0.25; }

.elementselectormodal .body .content .main .elements .tableview table tr th, .elementselectormodal .body .content .main .elements .tableview table tr td { cursor: default; }

body.ltr .elementselectormodal .body .content .main .elements .tableview table tr td:first-child { padding-left: 7px; }

body.rtl .elementselectormodal .body .content .main .elements .tableview table tr td:first-child { padding-right: 7px; }

.elementselectormodal .body .content .main .elements .structure .row { margin-top: 1px; outline: none; }

.elementselectormodal .footer { position: absolute; bottom: 0; left: 0; width: 100%; margin: 0; }

body.ltr .elementselectormodal .footer .spinner { float: right; }

body.rtl .elementselectormodal .footer .spinner { float: left; }

body.ltr .elementselectormodal .footer .spinner { margin-right: -24px; }

body.rtl .elementselectormodal .footer .spinner { margin-left: -24px; }

/* element editing HUD */
.element-hud-form .buttons { position: relative; }

.element-hud-form .buttons .spinner { position: absolute; top: 0; }

body.ltr .element-hud-form .buttons .spinner { right: -24px; }

body.rtl .element-hud-form .buttons .spinner { left: -24px; }

/* logout warning/login/elevated session modals */
.logoutwarningmodalshade, .loginmodalshade { z-index: 101; }

#logoutwarningmodal, #loginmodal, #elevatedsessionmodal { width: 500px; }

#logoutwarningmodal, #loginmodal { z-index: 101; }

#loginmodal .inputcontainer, #elevatedsessionmodal .inputcontainer { position: relative; }

#loginmodal .inputcontainer .spinner, #elevatedsessionmodal .inputcontainer .spinner { position: absolute; top: 0; margin-top: 0; }

body.ltr #loginmodal .inputcontainer .spinner, body.ltr #elevatedsessionmodal .inputcontainer .spinner { right: -24px; }

body.rtl #loginmodal .inputcontainer .spinner, body.rtl #elevatedsessionmodal .inputcontainer .spinner { left: -24px; }

/* delete user modal */
.deleteusermodal .content-summary { margin: -24px -24px 24px; padding: 24px; background-color: #f3f7fc; }

.deleteusermodal .options label { display: inline-block; line-height: 30px; }

.deleteusermodal .elementselect { display: inline-block; vertical-align: middle; }

body.ltr .deleteusermodal .elementselect { margin-left: 10px; }

body.rtl .deleteusermodal .elementselect { margin-right: 10px; }

body.ltr .deleteusermodal .buttons .spinner { margin-right: -20px; }

body.rtl .deleteusermodal .buttons .spinner { margin-left: -20px; }

.dropdownsettingsmodal { width: auto; height: auto; min-width: 0; min-height: 0; max-width: 400px; }

.dropdownsettingsmodal .body { max-height: 100%; overflow-y: auto; }

.previewmodal.zilch { padding: 100px 0; display: flex; align-items: center; justify-content: center; }

/* ----------------------------------------
/*  Menus
/* ----------------------------------------*/
.menu, .ui-datepicker, .ui-timepicker-list { z-index: 100; border-radius: 4px; padding: 0 14px; overflow: auto; background: #fff; user-select: none; box-shadow: 0 0 0 1px rgba(31, 41, 51, 0.1), 0 5px 20px rgba(31, 41, 51, 0.25); }

.ui-datepicker, .ui-timepicker-list { padding: 0; }

.menu { display: none; position: absolute; }

.menu h6:first-child { margin-top: 14px !important; }

body.ltr .menu ul.padded li a { padding-left: 24px; }

body.rtl .menu ul.padded li a { padding-right: 24px; }

.menu ul.padded li a[data-icon]:before, .menu ul.padded li a.icon:before, .menu ul.padded li a.sel:before { font-size: 14px; color: #606d7b; }

body.ltr .menu ul.padded li a[data-icon]:before, body.ltr .menu ul.padded li a.icon:before, body.ltr .menu ul.padded li a.sel:before { float: left; }

body.rtl .menu ul.padded li a[data-icon]:before, body.rtl .menu ul.padded li a.icon:before, body.rtl .menu ul.padded li a.sel:before { float: right; }

body.ltr .menu ul.padded li a[data-icon]:before, body.ltr .menu ul.padded li a.icon:before, body.ltr .menu ul.padded li a.sel:before { margin: 3px 0 0 -17px ; }

body.rtl .menu ul.padded li a[data-icon]:before, body.rtl .menu ul.padded li a.icon:before, body.rtl .menu ul.padded li a.sel:before { margin: 3px -17px 0 0 ; }

.menu ul.padded li a[data-icon].error:before, .menu ul.padded li a.icon.error:before, .menu ul.padded li a.sel.error:before { color: #CF1124; }

.menu ul.padded li a.sel:not([data-icon]):before { content: 'check'; }

.menu ul li a { margin: 0 -14px; padding: 10px 14px; color: #3f4d5a; text-decoration: none; white-space: nowrap; font-size: 14px; }

.menu ul li a:not(.flex) { display: block !important; }

.menu ul li a.sel { cursor: default; }

.menu ul li a .shortcut { color: #596673; }

body.ltr .menu ul li a .shortcut { float: right; }

body.rtl .menu ul li a .shortcut { float: left; }

body.ltr .menu ul li a .shortcut { margin-left: 14px; }

body.rtl .menu ul li a .shortcut { margin-right: 14px; }

.menu > .flex { margin-top: 10px; margin-bottom: 10px; position: relative; }

body.ltr .menu > .flex.padded { margin-left: -14px; }

body.rtl .menu > .flex.padded { margin-right: -14px; }

body.ltr .menu > .flex.padded { padding-left: 24px; }

body.rtl .menu > .flex.padded { padding-right: 24px; }

.menu > .flex.padded.sel:before { position: absolute; top: 36px; content: 'check'; font-size: 14px; color: #606d7b; }

body.ltr .menu > .flex.padded.sel:before { left: 7px; }

body.rtl .menu > .flex.padded.sel:before { right: 7px; }

.menu hr { margin: 5px -14px; }

.menubtn span.icon, .menu span.icon { display: inline-block; margin-top: -1px; width: 10px; text-align: center; font-size: 14px; color: #606d7b; }

body.ltr .menubtn span.icon, body.ltr .menu span.icon { margin-right: 10px; }

body.rtl .menubtn span.icon, body.rtl .menu span.icon { margin-left: 10px; }

.menu ul li a:not(.sel):not(.disabled):hover, .menu ul li a:not(.sel):not(.disabled):hover .light, .menu:not(:hover) ul li a:not(.disabled).hover, .menu:not(:hover) ul li a:not(.sel):not(.disabled).hover .light { color: #3f4d5a; background-color: #f3f7fc; }

body.ltr .menu hr.padded { margin-left: 10px; }

body.rtl .menu hr.padded { margin-right: 10px; }

/* tag select fields */
.tagselect .elements { display: inline; }

.tagselect .element.small { clear: none; }

.tagselect .add { position: relative; z-index: 1; display: inline-block; width: 12em; }

body.ltr .tagselect .add { margin: 7px 7px 0 0 ; }

body.rtl .tagselect .add { margin: 7px 0 0 7px ; }

body.ltr .tagselect .add .text { padding-right: 30px; }

body.rtl .tagselect .add .text { padding-left: 30px; }

.tagselect .add .spinner { position: absolute; top: 0; }

body.ltr .tagselect .add .spinner { right: 5px; }

body.rtl .tagselect .add .spinner { left: 5px; }

body.ltr .tagselect.elementselect .element, body.rtl .tagselect.elementselect .element { float: none !important; display: inline-block; }

body.ltr .tagmenu ul li a { padding-left: 26px; }

body.rtl .tagmenu ul li a { padding-right: 26px; }

body.ltr .tagmenu ul li a:before { float: left; }

body.rtl .tagmenu ul li a:before { float: right; }

body.ltr .tagmenu ul li a:before { margin: 3px 0 0 -18px ; }

body.rtl .tagmenu ul li a:before { margin: 3px -18px 0 0 ; }

/* selectize */
/* ----------------------------------------
/*  Fields
/* ----------------------------------------*/
.shadow-box { border-radius: 5px; border: 1px solid #cdd8e4; box-shadow: 0 1px 5px -1px rgba(31, 41, 51, 0.2); }

table.shadow-box thead:first-child tr:first-child th:first-child, table.shadow-box thead:first-child tr:first-child th:first-child.disabled:after, table.shadow-box thead:first-child tr:first-child td:first-child, table.shadow-box thead:first-child tr:first-child td:first-child.disabled:after, table.shadow-box tbody:first-child tr:first-child th:first-child, table.shadow-box tbody:first-child tr:first-child th:first-child.disabled:after, table.shadow-box tbody:first-child tr:first-child td:first-child, table.shadow-box tbody:first-child tr:first-child td:first-child.disabled:after, table.editable thead:first-child tr:first-child th:first-child, table.editable thead:first-child tr:first-child th:first-child.disabled:after, table.editable thead:first-child tr:first-child td:first-child, table.editable thead:first-child tr:first-child td:first-child.disabled:after, table.editable tbody:first-child tr:first-child th:first-child, table.editable tbody:first-child tr:first-child th:first-child.disabled:after, table.editable tbody:first-child tr:first-child td:first-child, table.editable tbody:first-child tr:first-child td:first-child.disabled:after { border-top-left-radius: 4px; }

table.shadow-box thead:first-child tr:first-child th:last-child, table.shadow-box thead:first-child tr:first-child th:last-child.disabled:after, table.shadow-box thead:first-child tr:first-child td:last-child, table.shadow-box thead:first-child tr:first-child td:last-child.disabled:after, table.shadow-box tbody:first-child tr:first-child th:last-child, table.shadow-box tbody:first-child tr:first-child th:last-child.disabled:after, table.shadow-box tbody:first-child tr:first-child td:last-child, table.shadow-box tbody:first-child tr:first-child td:last-child.disabled:after, table.editable thead:first-child tr:first-child th:last-child, table.editable thead:first-child tr:first-child th:last-child.disabled:after, table.editable thead:first-child tr:first-child td:last-child, table.editable thead:first-child tr:first-child td:last-child.disabled:after, table.editable tbody:first-child tr:first-child th:last-child, table.editable tbody:first-child tr:first-child th:last-child.disabled:after, table.editable tbody:first-child tr:first-child td:last-child, table.editable tbody:first-child tr:first-child td:last-child.disabled:after { border-top-right-radius: 4px; }

table.shadow-box thead:last-child tr:last-child th:first-child, table.shadow-box thead:last-child tr:last-child th:first-child.disabled:after, table.shadow-box thead:last-child tr:last-child td:first-child, table.shadow-box thead:last-child tr:last-child td:first-child.disabled:after, table.shadow-box tbody:last-child tr:last-child th:first-child, table.shadow-box tbody:last-child tr:last-child th:first-child.disabled:after, table.shadow-box tbody:last-child tr:last-child td:first-child, table.shadow-box tbody:last-child tr:last-child td:first-child.disabled:after, table.editable thead:last-child tr:last-child th:first-child, table.editable thead:last-child tr:last-child th:first-child.disabled:after, table.editable thead:last-child tr:last-child td:first-child, table.editable thead:last-child tr:last-child td:first-child.disabled:after, table.editable tbody:last-child tr:last-child th:first-child, table.editable tbody:last-child tr:last-child th:first-child.disabled:after, table.editable tbody:last-child tr:last-child td:first-child, table.editable tbody:last-child tr:last-child td:first-child.disabled:after { border-bottom-left-radius: 4px; }

table.shadow-box thead:last-child tr:last-child th:last-child, table.shadow-box thead:last-child tr:last-child th:last-child.disabled:after, table.shadow-box thead:last-child tr:last-child td:last-child, table.shadow-box thead:last-child tr:last-child td:last-child.disabled:after, table.shadow-box tbody:last-child tr:last-child th:last-child, table.shadow-box tbody:last-child tr:last-child th:last-child.disabled:after, table.shadow-box tbody:last-child tr:last-child td:last-child, table.shadow-box tbody:last-child tr:last-child td:last-child.disabled:after, table.editable thead:last-child tr:last-child th:last-child, table.editable thead:last-child tr:last-child th:last-child.disabled:after, table.editable thead:last-child tr:last-child td:last-child, table.editable thead:last-child tr:last-child td:last-child.disabled:after, table.editable tbody:last-child tr:last-child th:last-child, table.editable tbody:last-child tr:last-child th:last-child.disabled:after, table.editable tbody:last-child tr:last-child td:last-child, table.editable tbody:last-child tr:last-child td:last-child.disabled:after { border-bottom-right-radius: 4px; }

.text:not(.selectize-text), .passwordwrapper, .border-box, .matrix-configurator > .field > .input, .selectize-text > .selectize-control > .selectize-input, .multiselect > select, .selectize.multiselect .selectize-control.multi .selectize-input { border-radius: 3px; border: 1px solid rgba(96, 125, 159, 0.25); background-color: #fbfcfe; box-shadow: inset 0 1px 4px -1px rgba(96, 125, 159, 0.25); background-clip: padding-box; }

.text.focus, .text:focus, .passwordwrapper.focus, .passwordwrapper:focus, .border-box.focus, .border-box:focus, .selectize-text > .selectize-control > .selectize-input.focus, .selectize-text > .selectize-control > .selectize-input:focus { outline: none; border-color: rgba(96, 125, 159, 0.8); }

input.text, textarea.text, .text > input, .text > textarea, table.editable textarea, .selectize-text > .selectize-control > .selectize-input { font-size: 14px; line-height: 20px; color: #3f4d5a; min-height: 3px; box-sizing: border-box; appearance: none; }

.selectize-text > .selectize-control > .selectize-input { line-height: 18px; }

textarea.text.fullwidth { display: block; }

.multitext .multitextrow:after { content: '.'; display: block; height: 0; clear: both; visibility: hidden; }

body.ltr .multitext .multitextrow:first-child .text:first-child { border-top-left-radius: 4px; }

body.rtl .multitext .multitextrow:first-child .text:first-child { border-top-right-radius: 4px; }

body.ltr .multitext .multitextrow:first-child .text:last-child { border-top-right-radius: 4px; }

body.rtl .multitext .multitextrow:first-child .text:last-child { border-top-left-radius: 4px; }

body.ltr .multitext .multitextrow:last-child .text:first-child { border-bottom-left-radius: 4px; }

body.rtl .multitext .multitextrow:last-child .text:first-child { border-bottom-right-radius: 4px; }

body.ltr .multitext .multitextrow:last-child .text:last-child { border-bottom-right-radius: 4px; }

body.rtl .multitext .multitextrow:last-child .text:last-child { border-bottom-left-radius: 4px; }

.multitext .multitextrow:not(:first-child) .text { margin-top: -1px; }

.multitext .multitextrow .text { border-radius: 0; float: left; box-sizing: border-box; }

body.ltr .multitext .multitextrow .text:not(:first-child) { margin-left: -1px; }

body.rtl .multitext .multitextrow .text:not(:first-child) { margin-right: -1px; }

.multitext .multitextrow .text:first-child:nth-last-child(1) { width: 100%; }

.multitext .multitextrow .text:first-child:nth-last-child(2) { width: 50%; }

.multitext .multitextrow .text:first-child:nth-last-child(2) ~ .text { width: calc(50% + 1px); }

.multitext .multitextrow .text.error { position: relative; z-index: 1; }

.multitext .multitextrow .text:focus, .multitext .multitextrow .selectize-text > .selectize-control > .selectize-input.focus { position: relative; z-index: 2; }

.chars-left { position: relative; color: #606d7b; }

body.ltr .chars-left { float: right; }

body.rtl .chars-left { float: left; }

body.ltr .chars-left { margin: -27px 7px 0 0 ; }

body.rtl .chars-left { margin: -27px 0 0 7px ; }

.chars-left.negative-chars-left { color: #CF1124; }

.field, fieldset { position: relative; margin: 24px 0; }

.flex > .field, .flex > fieldset { margin-top: 0; margin-bottom: 0; }

.field > .status-badge { position: absolute; top: -3px; font-size: 9px; font-weight: bold; line-height: 15px; width: 15px; text-align: center; cursor: default; border-radius: 3px; }

body.ltr .field > .status-badge { left: -19px; }

body.rtl .field > .status-badge { right: -19px; }

.field > .status-badge.modified { background-color: #1992D4; color: #fff; -moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; font-weight: 500; }

.field > .status-badge.outdated { background-color: #FADB5F; }

.field > .status-badge.conflicted { background-color: #CF1124; color: #fff; -moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; font-weight: 500; }

.field > .heading { display: flex; flex-wrap: wrap; align-items: center; position: relative; margin-top: -5px; margin-bottom: 5px; }

.field > .heading > label, .field > .heading > legend { font-weight: bold; color: #596673; }

.field > .heading > label code, .field > .heading > legend code { font-size: 1em !important; }

body.ltr .field > .heading > label .info, body.ltr .field > .heading > legend .info { margin-left: 5px; }

body.rtl .field > .heading > label .info, body.rtl .field > .heading > legend .info { margin-right: 5px; }

.field > .heading > .t9n-indicator { color: #606d7b; }

body.ltr .field > .heading > .t9n-indicator { margin-left: 7px; }

body.rtl .field > .heading > .t9n-indicator { margin-right: 7px; }

.field > .heading + .instructions { margin-top: -3px; }

.field > .heading > .instructions { width: 100%; }

.field > .instructions { margin-bottom: 5px; }

.field > .input { position: relative; }

.field > .input:after { content: '.'; display: block; height: 0; clear: both; visibility: hidden; }

.field > .input + .instructions { margin: 5px 0 0; }

.field > .notice, .field > .warning { margin: 5px 0 0; }

.field > .instructions, .field > .heading > .instructions, .checkboxfield .instructions { color: #596673; }

.field > .instructions img, .field > .heading > .instructions img, .checkboxfield .instructions img { max-width: 100%; }

.field > .instructions ul, .field > .instructions ol, .field > .heading > .instructions ul, .field > .heading > .instructions ol, .checkboxfield .instructions ul, .checkboxfield .instructions ol { margin: 1em 0; }

body.ltr .field > .instructions ul, body.ltr .field > .instructions ol, body.ltr .field > .heading > .instructions ul, body.ltr .field > .heading > .instructions ol, body.ltr .checkboxfield .instructions ul, body.ltr .checkboxfield .instructions ol { padding-left: 2em; }

body.rtl .field > .instructions ul, body.rtl .field > .instructions ol, body.rtl .field > .heading > .instructions ul, body.rtl .field > .heading > .instructions ol, body.rtl .checkboxfield .instructions ul, body.rtl .checkboxfield .instructions ol { padding-right: 2em; }

.field > .instructions ul li, .field > .heading > .instructions ul li, .checkboxfield .instructions ul li { list-style-type: disc; }

.field > .instructions li + li, .field > .heading > .instructions li + li, .checkboxfield .instructions li + li { margin-top: 0.25em; }

#expand-status-btn { margin-left: 5px; width: 30px; height: 17px; padding: 0; line-height: 16px; border-radius: 3px; color: #596673; }

#expand-status-btn:before { margin: 0; }

/* toggles and nested fields */
.nested-fields { margin: -24px; padding: 24px 24px 0; }

.nested-fields.hidden { display: block; height: 0; }

.nested-fields > .field:last-child { padding-bottom: 24px; }

/* checkbox */
input.checkbox { opacity: 0; position: absolute; width: 16px; height: 16px; }

input.checkbox + label, div.checkbox { display: inline-block; clear: none; position: relative; line-height: 16px; height: 16px; cursor: pointer; }

body.ltr input.checkbox + label, body.ltr div.checkbox { padding-left: 21px; }

body.rtl input.checkbox + label, body.rtl div.checkbox { padding-right: 21px; }

input.checkbox + label .info, div.checkbox .info { height: 16px; }

input.checkbox:disabled + label, .disabled div.checkbox { cursor: default; }

body.ltr input.checkbox + label:empty, body.ltr div.checkbox:empty { padding-left: 16px; }

body.rtl input.checkbox + label:empty, body.rtl div.checkbox:empty { padding-right: 16px; }

input.checkbox + label:empty:after, div.checkbox:empty:after { content: ''; font-size: 0; }

/* fixes a RTL bug */
input.checkbox + label:before, div.checkbox:before { display: block; position: absolute; top: 0; width: 16px !important; height: 16px; box-sizing: border-box; content: ''; font-size: 0; background-color: #fbfcfe; border: 1px solid rgba(96, 125, 159, 0.4); background-clip: padding-box; border-radius: 3px; }

body.ltr input.checkbox + label:before, body.ltr div.checkbox:before { left: 0; }

body.rtl input.checkbox + label:before, body.rtl div.checkbox:before { right: 0; }

input.checkbox:disabled + label, div.checkbox.disabled:before, div.checkbox.disabled + label { opacity: 0.25; }

input.checkbox:checked + label:before, div.checkbox.checked:before, .sel div.checkbox:before, input.checkbox:indeterminate + label:before, div.checkbox.indeterminate:before { font-family: 'Craft'; speak: none; -webkit-font-feature-settings: "liga", "dlig"; -moz-font-feature-settings: "liga=1, dlig=1"; -moz-font-feature-settings: "liga", "dlig"; -ms-font-feature-settings: "liga", "dlig"; -o-font-feature-settings: "liga", "dlig"; font-feature-settings: "liga", "dlig"; text-rendering: optimizeLegibility; font-weight: normal; font-variant: normal; text-transform: none; line-height: 1; direction: ltr; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; display: inline-block; text-align: center; font-style: normal; vertical-align: middle; word-wrap: normal !important; user-select: none; opacity: 0.8; line-height: 16px; color: #1f2933; }

input.checkbox:checked:not(:indeterminate) + label:before, div.checkbox.checked:not(.indeterminate):before, .sel div.checkbox:not(.indeterminate):before { content: 'check'; font-size: 15px; }

input.checkbox:indeterminate + label:before, div.checkbox.indeterminate:before { content: 'minus'; font-size: 7px; text-align: center; }

input.checkbox:focus + label:before, :focus div.checkbox:before { outline: none; border-color: #607d9f; }

fieldset .checkboxfield { margin: 5px 0; }

.checkboxfield .instructions, .checkboxfield .notice, .checkboxfield .warning { margin-top: 2px; }

body.ltr .checkboxfield .instructions, body.ltr .checkboxfield .notice, body.ltr .checkboxfield .warning { padding-left: 21px; }

body.rtl .checkboxfield .instructions, body.rtl .checkboxfield .notice, body.rtl .checkboxfield .warning { padding-right: 21px; }

/* multiselect */
.multiselect > select { color: #3f4d5a; font-size: 14px; appearance: none; }

.multiselect > select:focus { outline: none; border-color: rgba(96, 125, 159, 0.8); }

.multiselect > select option { padding: 1px 8px; }

.text:not(.selectize-text), .selectize-text > .selectize-control > .selectize-input { padding: 6px 9px; }

.text { background-color: #fff; }

.text.small { padding: 3px; }

.text.readable { padding: 16px 18px; font-size: 16px; line-height: 22px; }

.text.readable + .chars-left { margin-top: -23px; }

.text input { margin: 0; padding: 0; border: none; outline: none; background-color: transparent; }

.input.errors > .text, .input.errors > .border-box, .input.errors > .passwordwrapper, .input.errors > .autosuggest-container .text, .text.error { border: 1px solid #CF1124 !important; }

.texticon { position: relative; cursor: text; min-width: 130px; }

.texticon.icon:before { position: absolute; top: 9px; color: #596673; }

body.ltr .texticon.icon:before { left: 9px; }

body.rtl .texticon.icon:before { right: 9px; }

body.ltr .texticon.icon .text { padding-left: 26px; }

body.rtl .texticon.icon .text { padding-right: 26px; }

body.ltr .texticon.clearable .text { padding-right: 22px; }

body.rtl .texticon.clearable .text { padding-left: 22px; }

.texticon .clear { position: absolute; top: 6px; color: #606d7b; cursor: pointer; }

body.ltr .texticon .clear { right: 9px; }

body.rtl .texticon .clear { left: 9px; }

.texticon .clear:hover { color: #596673; }

.texticon .clear:before { font-family: 'Craft'; speak: none; -webkit-font-feature-settings: "liga", "dlig"; -moz-font-feature-settings: "liga=1, dlig=1"; -moz-font-feature-settings: "liga", "dlig"; -ms-font-feature-settings: "liga", "dlig"; -o-font-feature-settings: "liga", "dlig"; font-feature-settings: "liga", "dlig"; text-rendering: optimizeLegibility; font-weight: normal; font-variant: normal; text-transform: none; line-height: 1; direction: ltr; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; display: inline-block; text-align: center; font-style: normal; vertical-align: middle; word-wrap: normal !important; user-select: none; opacity: 0.8; content: 'remove'; }

.texthint-container { position: relative; height: 0; }

.texthint { position: absolute; top: -1px; width: 100%; color: #606d7b; cursor: text; }

.passwordwrapper { position: relative; display: flex; align-items: center; }

.passwordwrapper .password { flex: 1; border: none; background: transparent; box-shadow: none; }

.passwordwrapper .password-toggle { color: #0B69A3; }

body.ltr .passwordwrapper .password-toggle { padding-right: 7px; }

body.rtl .passwordwrapper .password-toggle { padding-left: 7px; }

.passwordwrapper .password-toggle:hover { text-decoration: underline; }

.datetimewrapper { display: flex; flex-direction: row; align-items: center; }

body.ltr .datetimewrapper > .datewrapper + .timewrapper { margin-left: 5px; }

body.rtl .datetimewrapper > .datewrapper + .timewrapper { margin-right: 5px; }

.clear-btn { display: flex; justify-content: center; align-items: center; width: 24px; cursor: pointer; color: rgba(123, 135, 147, 0.5); border: none; padding: 0; background: transparent; }

.clear-btn:before { font-family: 'Craft'; speak: none; -webkit-font-feature-settings: "liga", "dlig"; -moz-font-feature-settings: "liga=1, dlig=1"; -moz-font-feature-settings: "liga", "dlig"; -ms-font-feature-settings: "liga", "dlig"; -o-font-feature-settings: "liga", "dlig"; font-feature-settings: "liga", "dlig"; text-rendering: optimizeLegibility; font-weight: normal; font-variant: normal; text-transform: none; line-height: 1; direction: ltr; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; display: inline-block; text-align: center; font-style: normal; vertical-align: middle; word-wrap: normal !important; user-select: none; opacity: 0.8; content: 'remove'; }

.clear-btn:hover { color: #596673; }

.datewrapper, .timewrapper { display: inline-block; position: relative; }

.datewrapper .text, .timewrapper .text { position: relative; z-index: 1; width: 100%; }

.datewrapper .text:placeholder-shown + div[data-icon], .timewrapper .text:placeholder-shown + div[data-icon] { display: block; position: absolute; top: calc(50% - 10px); z-index: 0; color: #606d7b; }

body.ltr .datewrapper .text:placeholder-shown + div[data-icon], body.ltr .timewrapper .text:placeholder-shown + div[data-icon] { left: 14px; }

body.rtl .datewrapper .text:placeholder-shown + div[data-icon], body.rtl .timewrapper .text:placeholder-shown + div[data-icon] { right: 14px; }

.datewrapper .text:placeholder-shown + div[data-icon], .datewrapper .text:placeholder-shown + div[data-icon]:before, .timewrapper .text:placeholder-shown + div[data-icon], .timewrapper .text:placeholder-shown + div[data-icon]:before { user-select: none; pointer-events: none; z-index: 1; }

#details .datewrapper .text:placeholder-shown + div[data-icon], #details .timewrapper .text:placeholder-shown + div[data-icon] { color: rgba(123, 135, 147, 0.5); }

.datewrapper .text:not(:placeholder-shown) + div[data-icon], .timewrapper .text:not(:placeholder-shown) + div[data-icon] { display: none; }

.datewrapper { width: 8em; }

.datewrapper .text:before { top: calc(50% - 7px); content: 'date'; }

body.ltr .datewrapper .text:before { left: 7px; }

body.rtl .datewrapper .text:before { right: 7px; }

.timewrapper { width: 7em; }

.timewrapper .text:before { top: calc(50% - 5px); font-size: 11px; content: 'time'; }

body.ltr .timewrapper .text:before { left: 7px; }

body.rtl .timewrapper .text:before { right: 7px; }

::-webkit-input-placeholder { color: #9aa5b1; }

input:-ms-input-placeholder { color: #9aa5b1; }

::-ms-input-placeholder { color: #9aa5b1; }

:-moz-placeholder { color: #9aa5b1; }

::-moz-placeholder { color: #9aa5b1; }

::placeholder { color: #9aa5b1; }

/* Kill IE's special text features */
::-ms-reveal, ::-ms-clear { display: none; }

/* Assets related */
.hud.assetshud .body { max-height: 500px; overflow: auto; }

.select:not(.selectize), .select:not(.selectize) select { position: relative; border-radius: 5px; white-space: nowrap; }

.select:not(.selectize) { max-width: 100%; position: relative; }

:not(.flex) > .select:not(.selectize) { display: inline-block; }

.select:not(.selectize):after { display: block; content: '.'; font-size: 0; width: 5px; height: 5px; border: solid #596673; border-width: 0 2px 2px 0; transform: rotate(45deg); position: absolute; z-index: 1; top: calc(50% - 5px); user-select: none; pointer-events: none; }

body.ltr .select:not(.selectize):after { right: 9px; }

body.rtl .select:not(.selectize):after { left: 9px; }

.select:not(.selectize) select { display: block; position: relative; max-width: 100%; border: none; font-size: 14px; line-height: 20px; color: #3f4d5a; background-color: rgba(96, 125, 159, 0.25); appearance: none; white-space: pre; }

body.ltr .select:not(.selectize) select { padding: 7px 22px 7px 10px ; }

body.rtl .select:not(.selectize) select { padding: 7px 10px 7px 22px ; }

.select:not(.selectize) select::-ms-expand { display: none; }

.select:not(.selectize) select option { background-color: #fff; }

.select:not(.selectize).fullwidth select { min-width: 100%; }

.select:not(.selectize) select:hover, .select:not(.selectize) select:focus { outline: none; background-color: rgba(96, 125, 159, 0.5); }

.select:not(.selectize).small:after { top: 9px; }

.select:not(.selectize).small select { padding-top: 4px !important; padding-bottom: 4px !important; font-size: 11px; }

/* selectize reset */
.selectize .selectize-control.single .selectize-input { border-color: inherit; box-shadow: none; background-color: transparent; }

.selectize .selectize-control.single .selectize-input:after { display: none; }

body .selectize-dropdown { border: none; }

/* single select styles */
.selectize.select .selectize-control, .selectize.select .selectize-control .selectize-input { position: relative; border-radius: 5px; white-space: nowrap; }

.selectize.select .selectize-control { max-width: 100%; position: relative; }

:not(.flex) > .selectize.select .selectize-control { display: inline-block; }

.selectize.select .selectize-control:after { display: block; content: '.'; font-size: 0; width: 5px; height: 5px; border: solid #596673; border-width: 0 2px 2px 0; transform: rotate(45deg); position: absolute; z-index: 1; top: calc(50% - 5px); user-select: none; pointer-events: none; }

body.ltr .selectize.select .selectize-control:after { right: 9px; }

body.rtl .selectize.select .selectize-control:after { left: 9px; }

.selectize.select .selectize-control .selectize-input { display: block; position: relative; max-width: 100%; border: none; font-size: 14px; line-height: 20px; color: #3f4d5a; background-color: rgba(96, 125, 159, 0.25); appearance: none; }

body.ltr .selectize.select .selectize-control .selectize-input { padding: 7px 22px 7px 10px ; }

body.rtl .selectize.select .selectize-control .selectize-input { padding: 7px 10px 7px 22px ; }

.selectize.select .selectize-control .selectize-input::-ms-expand { display: none; }

.selectize.select.fullwidth .selectize-control, .selectize.select.fullwidth .selectize-control .selectize-input { min-width: 100%; }

/* multi select styles */
.selectize.multiselect .selectize-control.multi .selectize-input { padding: 6px 8px; }

.selectize.multiselect .selectize-control.multi .selectize-input.has-items { padding: 5px 8px; }

.selectize.multiselect .selectize-control.multi .selectize-input > div { display: inline-block; border-radius: 10px; padding: 2px 7px; font-size: 12px; line-height: 14px; color: #3f4d5a; background-color: rgba(255, 255, 255, 0.5); box-shadow: 0 0 0 1px rgba(123, 135, 147, 0.5); margin-top: 1px; margin-bottom: 1px; }

.selectize.multiselect .selectize-control.plugin-remove_button [data-value] .remove { padding: 0; }

/* shared styles */
.selectize .selectize-control .selectize-input.focus { outline: none; border-color: rgba(96, 125, 159, 0.8); }

/* menu styles */
body .selectize-dropdown { z-index: 100; border-radius: 4px; padding: 0 14px; overflow: auto; background: #fff; user-select: none; box-shadow: 0 0 0 1px rgba(31, 41, 51, 0.1), 0 5px 20px rgba(31, 41, 51, 0.25); margin-top: 1px; padding: 0; }

body .selectize-dropdown-content { padding: 3px 14px; }

body .selectize-dropdown-content > div[data-value="new"]:before { font-family: 'Craft'; speak: none; -webkit-font-feature-settings: "liga", "dlig"; -moz-font-feature-settings: "liga=1, dlig=1"; -moz-font-feature-settings: "liga", "dlig"; -ms-font-feature-settings: "liga", "dlig"; -o-font-feature-settings: "liga", "dlig"; font-feature-settings: "liga", "dlig"; text-rendering: optimizeLegibility; font-weight: normal; font-variant: normal; text-transform: none; line-height: 1; direction: ltr; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; display: inline-block; text-align: center; font-style: normal; vertical-align: middle; word-wrap: normal !important; user-select: none; opacity: 0.8; content: 'plus'; margin-right: 5px; }

body .selectize-dropdown-content > div[data-value="new"]:after { content: '…'; }

body .selectize-dropdown [data-selectable], body .selectize-dropdown .optgroup-header { margin: 0 -14px; padding: 10px 14px; color: #3f4d5a; text-decoration: none; white-space: nowrap; }

body .selectize-dropdown .optgroup-header { margin: 14px 0 3px; font-size: 10px; line-height: 1.2; color: #606d7b; text-transform: uppercase; font-weight: bold; margin: 0; padding: 4px 0; }

body .selectize-dropdown .active { color: #3f4d5a; background-color: #f3f7fc; }

/* datepicker */
.ui-datepicker { position: fixed; top: -300px; -padding: 10px; width: 210px; height: 242px; z-index: 101 !important; }

body.ltr .ui-datepicker { margin-left: 1px; }

body.rtl .ui-datepicker { margin-right: 1px; }

.ui-datepicker-header { padding: 8px 8px 4px; }

body.ltr .ui-datepicker-prev { float: left; }

body.rtl .ui-datepicker-prev { float: right; }

body.ltr .ui-datepicker-next { float: right; }

body.rtl .ui-datepicker-next { float: left; }

.ui-datepicker-prev span, .ui-datepicker-next span { display: none; }

.ui-datepicker-prev, .ui-datepicker-next { width: 20px; height: 20px; display: flex; align-items: center; justify-content: center; }

.ui-datepicker-prev:hover:after, .ui-datepicker-next:hover:after { border-color: #0B69A3; }

.ui-datepicker-prev:after { display: block; content: '.'; font-size: 0; width: 5px; height: 5px; border: solid #596673; border-width: 0 2px 2px 0; }

body.ltr .ui-datepicker-prev:after { transform: rotate(135deg); }

body.rtl .ui-datepicker-prev:after { transform: rotate(-45deg); }

.ui-datepicker-next:after { display: block; content: '.'; font-size: 0; width: 5px; height: 5px; border: solid #596673; border-width: 0 2px 2px 0; }

body.ltr .ui-datepicker-next:after { transform: rotate(-45deg); }

body.rtl .ui-datepicker-next:after { transform: rotate(135deg); }

.ui-datepicker-title { text-align: center; }

.ui-datepicker-calendar th, .ui-datepicker-calendar td { padding: 2px !important; }

.ui-datepicker-calendar th span, .ui-datepicker-calendar td a { display: block; width: 26px; line-height: 26px; text-align: center; color: #3f4d5a; }

.ui-datepicker-calendar th span { color: #596673; font-weight: normal; }

.ui-datepicker-calendar td a { border-radius: 2px; }

.ui-datepicker-calendar td a:hover { background-color: #cdd8e4; text-decoration: none; }

.ui-datepicker-calendar td a.ui-state-active { background-color: #515f6c; color: #fff; -moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; font-weight: 500; cursor: default; }

.ui-datepicker-calendar td.ui-datepicker-today a { border-radius: 13px; box-shadow: inset 0 0 0 2px #cdd8e4; }

/* timepicker */
.ui-timepicker-wrapper { z-index: 101; }

.ui-timepicker-list { overflow-y: auto; width: calc(14px + 6em); height: 210px; z-index: 100; }

body.ltr .ui-timepicker-list { margin-left: 1px; }

body.rtl .ui-timepicker-list { margin-right: 1px; }

.ui-timepicker-list li { white-space: nowrap; cursor: pointer; }

body.ltr .ui-timepicker-list li { padding: 2px 0 2px 14px ; }

body.rtl .ui-timepicker-list li { padding: 2px 14px 2px 0 ; }

.ui-timepicker-list li:hover { background-color: #cdd8e4; }

.ui-timepicker-list li.ui-timepicker-selected { background-color: #515f6c; color: #fff; -moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; font-weight: 500; cursor: default; }

/* slide picker */
.slide-picker { display: flex; height: 15px; white-space: nowrap; }

.slide-picker a { border: 1px solid rgba(51, 64, 77, 0.1); background-image: none; width: 7px; height: 13px; margin-left: 0; display: inline-block; margin-top: 1px; margin-bottom: 1px; box-sizing: border-box; }

body.ltr .slide-picker a { border-left: none; }

body.rtl .slide-picker a { border-right: none; }

.slide-picker a:first-child { width: 8px; }

body.ltr .slide-picker a:first-child { border-left: 1px solid #7b8793 !important; }

body.rtl .slide-picker a:first-child { border-right: 1px solid #7b8793 !important; }

.slide-picker:not(:hover) a.active, .slide-picker:hover a.active-hover { border-top-color: #7b8793; border-bottom-color: #7b8793; height: 15px; margin-top: 0; margin-bottom: 0; }

body.ltr .slide-picker:not(:hover) a.active:first-child, body.ltr .slide-picker:hover a.active-hover:first-child { border-left: 1px solid #7b8793; }

body.rtl .slide-picker:not(:hover) a.active:first-child, body.rtl .slide-picker:hover a.active-hover:first-child { border-right: 1px solid #7b8793; }

body.ltr .slide-picker:not(:hover) a.active:first-child, body.ltr .slide-picker:hover a.active-hover:first-child { border-top-left-radius: 3px; }

body.rtl .slide-picker:not(:hover) a.active:first-child, body.rtl .slide-picker:hover a.active-hover:first-child { border-top-right-radius: 3px; }

body.ltr .slide-picker:not(:hover) a.active:first-child, body.ltr .slide-picker:hover a.active-hover:first-child { border-bottom-left-radius: 3px; }

body.rtl .slide-picker:not(:hover) a.active:first-child, body.rtl .slide-picker:hover a.active-hover:first-child { border-bottom-right-radius: 3px; }

body.ltr .slide-picker:not(:hover) a.last-active, body.ltr .slide-picker:hover a.last-active-hover { border-right: 1px solid #7b8793; }

body.rtl .slide-picker:not(:hover) a.last-active, body.rtl .slide-picker:hover a.last-active-hover { border-left: 1px solid #7b8793; }

body.ltr .slide-picker:not(:hover) a.last-active, body.ltr .slide-picker:hover a.last-active-hover { border-top-right-radius: 3px; }

body.rtl .slide-picker:not(:hover) a.last-active, body.rtl .slide-picker:hover a.last-active-hover { border-top-left-radius: 3px; }

body.ltr .slide-picker:not(:hover) a.last-active, body.ltr .slide-picker:hover a.last-active-hover { border-bottom-right-radius: 3px; }

body.rtl .slide-picker:not(:hover) a.last-active, body.rtl .slide-picker:hover a.last-active-hover { border-bottom-left-radius: 3px; }

.slide-picker:focus { outline: none; }

.slide-picker:focus a.active { background-color: #cdd8e4; }

/* errors */
ul.errors { margin-top: 5px; list-style-type: square; }

body.ltr ul.errors { padding-left: 20px; }

body.rtl ul.errors { padding-right: 20px; }

ul.errors li { color: #CF1124; }

/* message pages */
.message-container { position: absolute; z-index: 100; top: 0; width: 100%; height: 100%; }

body.ltr .message-container { left: 0; }

body.rtl .message-container { right: 0; }

.message-container.no-access { background-color: rgba(31, 41, 51, 0.5); }

.message-container .pane { top: 50%; margin-top: -33px !important; margin-left: auto; margin-right: auto; width: 320px; box-shadow: 0 25px 100px rgba(31, 41, 51, 0.5); }

/* auto-suggest */
.autosuggest-container { position: relative; }

.autosuggest__results-container { position: absolute; z-index: 2; width: 100%; border-radius: 5px; background-color: #fff; box-shadow: 0 1px 5px -1px rgba(31, 41, 51, 0.2); box-sizing: border-box; padding: 0 14px; text-align: left; font-family: system-ui, BlinkMacSystemFont, -apple-system, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; font-size: 1em !important; }

.autosuggest__results-container .autosuggest__results_title { margin: 14px 0 3px; font-size: 10px; line-height: 1.2; color: #606d7b; text-transform: uppercase; font-weight: bold; margin-top: 14px !important; }

.autosuggest__results-container .autosuggest__results_item { margin: 0 -14px; padding: 10px 14px; color: #3f4d5a; text-decoration: none; white-space: nowrap; }

.autosuggest__results-container .autosuggest__results_item:hover, .autosuggest__results-container .autosuggest__results_item.autosuggest__results_item-highlighted { color: #3f4d5a; background-color: #f3f7fc; cursor: pointer; }

/* ----------------------------------------
/* Matrix
/* ----------------------------------------*/
.matrix-configurator > .field { max-width: none; }

.matrix-configurator > .field > .input { position: relative; background-color: #f3f7fc; overflow: hidden; box-shadow: none; }

body.ltr .matrix-configurator > .field > .input { padding-left: 440px; }

body.rtl .matrix-configurator > .field > .input { padding-right: 440px; }

.matrix-configurator > .field > .input:after { display: block; position: absolute; z-index: 1; top: 0; left: 0; width: 100%; height: 100%; visibility: visible; content: ''; font-size: 0; border-radius: 3px; box-shadow: inset 0 1px 3px -1px #acbed2; user-select: none; pointer-events: none; }

.matrix-configurator .mc-sidebar { position: absolute; top: 0; margin: 0; border: none; height: 100%; box-sizing: border-box; }

.matrix-configurator .mc-sidebar .mc-col-items { margin-top: -1px; padding-top: 1px; }

.matrix-configurator .mc-sidebar.block-types { width: 200px; }

body.ltr .matrix-configurator .mc-sidebar.block-types { left: 0; }

body.rtl .matrix-configurator .mc-sidebar.block-types { right: 0; }

.matrix-configurator .mc-sidebar.block-types .mc-col-items .btn { margin: 14px; }

.matrix-configurator .mc-sidebar.mc-fields { width: 240px; z-index: 1; background: #fff; box-shadow: 0 0 0 1px rgba(31, 41, 51, 0.1), 0 2px 5px -2px rgba(31, 41, 51, 0.2); }

body.ltr .matrix-configurator .mc-sidebar.mc-fields { left: 200px; }

body.rtl .matrix-configurator .mc-sidebar.mc-fields { right: 200px; }

.matrix-configurator .mc-sidebar.mc-fields .mc-col-items { padding: 14px; }

.matrix-configurator .mc-sidebar.mc-fields .mc-col-items .btn { margin-top: 14px; }

.matrix-configurator .mc-sidebar > .mc-col-inner-container > .mc-col-heading, .matrix-configurator .mc-field-settings > .mc-col-inner-container > .mc-col-heading { margin: 0; padding: 7px 14px 6px; border-bottom: 1px solid rgba(51, 64, 77, 0.1); background-color: #f3f7fc; background-image: linear-gradient(rgba(51, 64, 77, 0), rgba(51, 64, 77, 0.05)); }

.matrix-configurator .mc-field-settings { flex: 1; position: relative; height: 100%; }

.matrix-configurator .mc-field-settings > .mc-col-inner-container > .mc-col-heading { padding-left: 24px; padding-right: 24px; }

.matrix-configurator .mc-field-settings > .mc-col-inner-container > .mc-col-items { padding: 24px; }

.matrixconfigitem { position: relative; display: flex; align-items: center; user-select: none; cursor: default; min-height: 48px; box-sizing: border-box; }

.matrixconfigitem.mci-blocktype { margin-top: -1px; padding: 8px 14px; border: solid rgba(51, 64, 77, 0.1); border-width: 1px 0; background-color: #e4edf6; }

.matrixconfigitem.mci-blocktype.sel { z-index: 1; background-color: #cdd8e4; }

.matrixconfigitem.mci-field { border-radius: 4px; padding: 7px 10px; background-color: #e4edf6; }

.matrixconfigitem.mci-field.sel { background-color: #cdd8e4; }

.matrixconfigitem.mci-field.sel .slide-picker:focus a.active { background-color: #5f6c79; }

.matrixconfigitem.mci-field + .mci-field { margin-top: 7px; }

.matrixconfigitem .mci-name { flex: 1; overflow: hidden; }

.matrixconfigitem .mci-name h4, .matrixconfigitem .mci-name .smalltext { white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }

.matrixconfigitem .mci-name h4 { margin-bottom: 2px; font-weight: normal; color: #3f4d5a; }

.matrixconfigitem .mci-name h4.mci-required:after { font-family: 'Craft'; speak: none; -webkit-font-feature-settings: "liga", "dlig"; -moz-font-feature-settings: "liga=1, dlig=1"; -moz-font-feature-settings: "liga", "dlig"; -ms-font-feature-settings: "liga", "dlig"; -o-font-feature-settings: "liga", "dlig"; font-feature-settings: "liga", "dlig"; text-rendering: optimizeLegibility; font-weight: normal; font-variant: normal; text-transform: none; line-height: 1; direction: ltr; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; display: inline-block; text-align: center; font-style: normal; vertical-align: middle; word-wrap: normal !important; user-select: none; opacity: 0.8; content: 'asterisk'; font-size: 8px; }

body.ltr .matrixconfigitem .mci-name h4.mci-required:after { margin: -2px 0 0 4px ; }

body.rtl .matrixconfigitem .mci-name h4.mci-required:after { margin: -2px 4px 0 0 ; }

.matrixconfigitem.error .mci-name h4 { color: #CF1124; }

body.ltr .matrixconfigitem .slide-picker, body.ltr .matrixconfigitem .icon { margin-left: 7px; }

body.rtl .matrixconfigitem .slide-picker, body.rtl .matrixconfigitem .icon { margin-right: 7px; }

.matrixconfigitem .icon { display: block; }

.matrixconfigitem .icon:not(.error):not(:hover):before { color: rgba(123, 135, 147, 0.5); }

.matrixconfigitem .icon.error:before { color: #CF1124; }

/* Matrix fields */
.matrix > .buttons { margin-top: 10px; }

.matrixblock { position: relative; margin-bottom: 10px; padding: 0 14px 14px; border-radius: 5px; border: 1px solid rgba(51, 64, 77, 0.1); background-color: #f3f7fc; outline: none; }

.matrixblock.static { padding-top: 14px; }

.matrixblock > .titlebar { margin: 0 -14px; width: calc(100% + 28px); box-sizing: border-box; border-radius: 4px 4px 0 0; color: #606d7b; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; word-wrap: normal; cursor: default; user-select: none; position: relative; background-color: #ebf2fa; }

body.ltr .matrixblock > .titlebar { padding: 5px 70px 5px 35px ; }

body.rtl .matrixblock > .titlebar { padding: 5px 35px 5px 70px ; }

.matrixblock > .titlebar:after { display: block; content: ''; position: absolute; bottom: -1px; left: 14px; width: calc(100% - 28px); height: 1px; background-color: rgba(51, 64, 77, 0.1); }

.matrixblock > .titlebar > .blocktype { display: inline; color: #596673; }

.matrixblock > .titlebar > .preview { display: inline; opacity: 0; transition: opacity linear 100ms; }

body.ltr .matrixblock > .titlebar > .preview { margin-left: 7px; }

body.rtl .matrixblock > .titlebar > .preview { margin-right: 7px; }

.matrixblock > .titlebar > .preview span { opacity: 0.5; }

.matrixblock.disabled { opacity: 1; }

body.ltr .matrixblock.disabled > .titlebar { padding-right: 90px; }

body.rtl .matrixblock.disabled > .titlebar { padding-left: 90px; }

.matrixblock.disabled > .actions > .status.off { display: block; }

.matrixblock.collapsed > .titlebar { border-radius: 4px; border-bottom: none; }

.matrixblock.collapsed > .titlebar > .preview { opacity: 1; }

.matrixblock > .checkbox { position: absolute; top: 7px; }

body.ltr .matrixblock > .checkbox { left: 14px; }

body.rtl .matrixblock > .checkbox { right: 14px; }

.matrixblock > .actions { display: flex; align-items: center; position: absolute; z-index: 1; top: 4px; cursor: default; }

body.ltr .matrixblock > .actions { right: 14px; }

body.rtl .matrixblock > .actions { left: 14px; }

body.ltr .matrixblock > .actions > * { margin: 0 0 0 10px ; }

body.rtl .matrixblock > .actions > * { margin: 0 10px 0 0 ; }

.matrixblock > .actions > .status.off { display: none; }

.matrixblock > .actions a { padding: 0 !important; height: 20px; text-align: center; color: rgba(123, 135, 147, 0.5); transform: color linear 100ms; }

.matrixblock > .actions a.settings:after { border-color: rgba(123, 135, 147, 0.5); transform: border-color linear 100ms; }

body.ltr .matrixblock > .actions a.settings:after { margin-left: 3px; }

body.rtl .matrixblock > .actions a.settings:after { margin-right: 3px; }

.matrixblock > .actions a:hover { color: #0B69A3; }

.matrixblock > .actions a:hover.settings:after { border-color: #0B69A3; }

.matrixblock:not(.static) > .fields { padding-top: 14px; }

.matrixblock > .fields > .field { margin: 15px 0; }

.matrixblock > .buttons { margin-top: 0; height: 30px; }

/* categories */
.add-category-form { margin-top: 24px; }

.add-category-form .texticon { width: 200px; }

body.ltr .add-category-form .texticon { float: left; }

body.rtl .add-category-form .texticon { float: right; }

body.ltr .add-category-form .texticon { margin-right: 5px; }

body.rtl .add-category-form .texticon { margin-left: 5px; }

body.ltr .add-category-form .texticon .text { padding-right: 30px; }

body.rtl .add-category-form .texticon .text { padding-left: 30px; }

.add-category-form .texticon .spinner { position: absolute; top: 0; }

body.ltr .add-category-form .texticon .spinner { right: 5px; }

body.rtl .add-category-form .texticon .spinner { left: 5px; }

.categoriesfield { position: relative; min-height: 30px; }

body.ltr .categoriesfield .structure ul { margin-left: 12px; }

body.rtl .categoriesfield .structure ul { margin-right: 12px; }

/* site pickers */
body.sitepicker #main-content { padding: 30px; justify-content: center; align-items: center; text-align: center; }

body.sitepicker #content-container { max-width: 400px; }

.sitepicker-group li:not(:first-child) a { margin-top: -1px; border-top-left-radius: 0; border-top-right-radius: 0; }

.sitepicker-group li:not(:first-child) a:not(:hover) { border-top-color: transparent; }

.sitepicker-group li:not(:last-child) a { border-bottom-left-radius: 0; border-bottom-right-radius: 0; }

.sitepicker-group li a { display: block; position: relative; text-align: left; width: 100%; box-sizing: border-box; border: 1px solid rgba(51, 64, 77, 0.1); border-radius: 4px; font-size: 16px; line-height: 1.4; }

body.ltr .sitepicker-group li a { padding: 9px 42px 9px 15px ; }

body.rtl .sitepicker-group li a { padding: 9px 15px 9px 42px ; }

.sitepicker-group li a:after { font-size: 14px; position: absolute; top: calc(50% - 7px); margin: 0; padding: 0; }

body.ltr .sitepicker-group li a:after { right: 12px; }

body.rtl .sitepicker-group li a:after { left: 12px; }

.sitepicker-group li a:hover { border-color: #0B69A3; text-decoration: none; z-index: 1; }

/* ----------------------------------------
/* IE hacks
/* ----------------------------------------*/
/* Fix layout of modal element selectors for IE8 */
.elementselectormodal .body .main { float: left \9; width: 445px \9; }

/* ----------------------------------------
/*  Retina graphics
/* ----------------------------------------*/
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (-moz-min-device-pixel-ratio: 1.5), only screen and (-o-min-device-pixel-ratio: 3 / 2), only screen and (min-device-pixel-ratio: 1.5), only screen and (min-resolution: 1.5dppx) { table.data thead th.ordered.loading { background-image: url(../images/spinner_tableheader_2x.gif); background-size: 26px; }
  .spinner, .elements.busy:after { background-image: url(../images/spinner_2x.gif); background-size: 20px; }
  .spinner.over-bg { background-image: url(../images/spinner_bg_2x.gif); }
  .spinner.big { background-image: url(../images/spinner_big_2x.gif); background-size: 48px; }
  .spinner.big.over-bg { background-image: url(../images/spinner_big_bg_2x.gif); }
  .btn.icon.add.loading:after { background-image: url(../images/spinner_2x.gif); background-size: 20px; }
  .btn.icon.add.loading.submit:after { background-image: url(../images/spinner_submit_2x.gif); }
  .secondary-buttons .btn.icon.add.loading.submit:after, .btn.secondary.icon.add.loading.submit:after { background-image: url(../images/spinner_submit_secondary_2x.gif); }
  .sel .element.loading:after, .element.loading.sel:after { background-image: url(../images/spinner_element_2x.gif); background-size: 20px; }
  .preview-thumb-container.loading:after { background-image: url(../images/spinner_dark_2x.gif); background-size: 20px; }
  .structure ul li { background-size: 40px; }
  body.ltr .structure ul li { background-image: url(../images/branch_2x.png); }
  body.rtl .structure ul li { background-image: url(../images/branch_rtl_2x.png); }
  .modal .resizehandle { background-size: 13px; }
  body.ltr .modal .resizehandle { background-image: url(../images/resizehandle_2x.png); }
  body.rtl .modal .resizehandle { background-image: url(../images/resizehandle_rtl_2x.png); }
  .hud .tip-left { background-image: url(../images/hudtip_left_2x.png); background-size: 15px 30px; }
  .hud .tip-top { background-image: url(../images/hudtip_top_2x.png); background-size: 30px 15px; }
  .hud .tip-right { background-image: url(../images/hudtip_right_2x.png); background-size: 15px 30px; }
  .hud .tip-bottom { background-image: url(../images/hudtip_bottom_2x.png); background-size: 30px 15px; }
  .hud.has-footer .tip-bottom { background-image: url(../images/hudtip_bottom_gray_2x.png); } }

html { -webkit-text-size-adjust: 100%; min-height: 100vh; }

body { min-height: 100vh; }

#global-container { position: relative; display: flex; flex-direction: column; min-height: 100vh; }

/* global sidebar */
#global-sidebar { position: fixed; z-index: 100; display: flex; flex-direction: column; height: 100vh; padding: 0; overflow-y: auto; overflow-x: hidden; -webkit-overflow-scrolling: touch; -moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; font-weight: 500; background-color: #33404d; }

#global-sidebar a { color: #e4edf6; transition: color linear 100ms; text-decoration: none; }

#global-sidebar > a, #global-sidebar *:not(.has-subnav) > a { outline: none; transition: background-color linear 100ms; }

#global-sidebar > a[href]:hover, #global-sidebar > a[href].active, #global-sidebar > a[href]:active, #global-sidebar *:not(.has-subnav) > a[href]:hover, #global-sidebar *:not(.has-subnav) > a[href].active, #global-sidebar *:not(.has-subnav) > a[href]:active { color: #fff; }

#global-sidebar > a[href]:not(.sel):hover, #global-sidebar *:not(.has-subnav) > a[href]:not(.sel):hover { background-color: #29333d; transition: none; }

#global-sidebar > a[href]:not(.sel):hover .icon, #global-sidebar *:not(.has-subnav) > a[href]:not(.sel):hover .icon { opacity: 1; }

#global-sidebar > a[href].active, #global-sidebar > a[href]:active, #global-sidebar *:not(.has-subnav) > a[href].active, #global-sidebar *:not(.has-subnav) > a[href]:active { background-color: #1f272e; }

#global-sidebar > a:not([href]), #global-sidebar *:not(.has-subnav) > a:not([href]) { cursor: default; background-color: transparent; }

#global-sidebar > a .icon, #global-sidebar *:not(.has-subnav) > a .icon { transition: opacity linear 100ms; }

#global-sidebar .light { color: #7b8793; }

#system-info { display: grid; grid-template-columns: 30px auto; grid-gap: 10px; padding: 0 10px; position: relative; flex: 0 0 50px; flex-direction: row; align-items: center; background-color: #29333d; }

#system-info:after { display: none; }

#system-info:focus, #system-info:hover { background-color: #1f272e !important; }

#site-icon { height: 30px; }

#site-icon img, #site-icon svg { display: block; width: 30px; height: 30px; border-radius: 4px; }

#site-icon svg rect, #site-icon svg circle, #site-icon svg ellipse, #site-icon svg line, #site-icon svg polyline, #site-icon svg polygon, #site-icon svg path, #site-icon svg text { fill: #596673; stroke-width: 0; transition: fill linear 100ms; }

#system-name h2 { margin: 0; width: 100%; overflow: hidden; max-height: 40px; position: relative; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2; }

#system-name h2:after { display: block; content: ''; font-size: 0; position: absolute; top: 0; width: 14px; background-image: linear-gradient(to right, rgba(41, 51, 61, 0), #29333d); }

body.ltr #system-name h2:after { right: 0; }

body.rtl #system-name h2:after { left: 0; }

#system-info:hover #system-name h2:after { background-image: linear-gradient(to right, rgba(31, 39, 46, 0), #1f272e); }

#job-icon { align-items: flex-start; }

#job-icon > span.icon { display: block; position: relative; width: 16px; height: 16px; margin-top: 2px !important; }

#job-icon > span.icon > canvas { display: block; position: absolute; width: 16px; height: 16px; }

#job-icon > span.icon > canvas#job-icon-hover { opacity: 0; transition: opacity linear 100ms; }

#job-icon[href]:hover .icon > span.icon > canvas#job-icon-hover { opacity: 1; }

#job-icon .progress-label { display: block; color: #9aa5b1; font-size: 11px; line-height: 1.5; }

#nav { flex: 1; margin: 27px 0 0; padding-bottom: 24px; overflow: visible; }

#nav li:not(.has-subnav) > a.sel { color: #fff; background-color: #1f2933; opacity: 1; }

#nav li:not(.has-subnav) > a.sel .icon { opacity: 1; }

#nav li a { padding-left: 14px; padding-right: 14px; }

#nav li a.menubtn { line-height: 26px; }

#nav li a.external:after { margin-left: 5px; font-family: 'Craft'; speak: none; -webkit-font-feature-settings: "liga", "dlig"; -moz-font-feature-settings: "liga=1, dlig=1"; -moz-font-feature-settings: "liga", "dlig"; -ms-font-feature-settings: "liga", "dlig"; -o-font-feature-settings: "liga", "dlig"; font-feature-settings: "liga", "dlig"; text-rendering: optimizeLegibility; font-weight: normal; font-variant: normal; text-transform: none; line-height: 1; direction: ltr; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; display: inline-block; text-align: center; font-style: normal; vertical-align: middle; word-wrap: normal !important; user-select: none; opacity: 0.8; content: 'external'; }

#nav li a:active .icon { opacity: 1; }

#nav li a .icon { opacity: 0.5; }

#nav li a .icon.icon-mask svg rect, #nav li a .icon.icon-mask svg circle, #nav li a .icon.icon-mask svg ellipse, #nav li a .icon.icon-mask svg line, #nav li a .icon.icon-mask svg polyline, #nav li a .icon.icon-mask svg polygon, #nav li a .icon.icon-mask svg path, #nav li a .icon.icon-mask svg text { fill: #fff; stroke-width: 0; transition: fill linear 100ms; }

#nav li a .icon.icon-mask span[data-icon] { color: #fff; }

#nav li a .label, #nav li a .label span { white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }

#nav li a .badge { background-color: rgba(255, 255, 255, 0.5); color: #33404d; }

body.ltr #nav li a .badge { margin-right: 0; }

body.rtl #nav li a .badge { margin-left: 0; }

#nav li ul { display: block; margin-bottom: 10px; }

#nav li ul li a { font-size: 12px; }

body.ltr #nav li ul li a { padding: 3px 14px 3px 42px !important; }

body.rtl #nav li ul li a { padding: 3px 42px 3px 14px !important; }

#nav li ul li a:not(.active) { color: #cdd8e4; }

#global-footer { display: flex; flex-direction: column; align-items: center; padding: 14px 14px 24px; }

#app-info { margin-top: 7px; display: flex; align-items: center; justify-content: center; font-size: 12px; color: #515f6c; text-align: center; }

#app-info li { margin: 0 7px; }

#app-info li a { color: #515f6c; text-decoration: underline; }

#app-info li a.go:hover:after { color: #515f6c; }

#edition-logo { user-select: none; border: 1px solid #515f6c; border-radius: 3px; display: inline-flex; height: 20px; box-sizing: content-box; font-size: 11px; }

#edition-logo > .edition-name, #edition-logo > .edition-trial { padding: 6px 7px 5px; line-height: 8px; }

#edition-logo > .edition-name { font-weight: 600; letter-spacing: 1.7px; padding-right: 5px; text-transform: uppercase; transition: color linear 100ms; }

#edition-logo > .edition-trial { display: inline-block; position: relative; background-color: #515f6c; color: #cdd8e4; border-radius: 0 1px 1px 0; letter-spacing: 1px; text-transform: lowercase; transition: background linear 100ms; }

body.ltr #edition-logo > .edition-trial { margin-left: 5px; }

body.rtl #edition-logo > .edition-trial { margin-right: 5px; }

body.ltr #edition-logo > .edition-trial { padding-left: 5px; }

body.rtl #edition-logo > .edition-trial { padding-right: 5px; }

body.ltr #edition-logo > .edition-trial { padding-right: 7px; }

body.rtl #edition-logo > .edition-trial { padding-left: 7px; }

#edition-logo > .edition-trial:before { display: block; position: absolute; top: 0; content: ''; font-size: 0; width: 0; height: 0; border-style: solid; transition: border-color linear 100ms; }

body.ltr #edition-logo > .edition-trial:before { left: -10px; border-width: 0 10px 20px 0; border-color: transparent #515f6c transparent transparent; }

body.rtl #edition-logo > .edition-trial:before { right: -10px; border-width: 0 0 20px 10px; border-color: transparent transparent transparent #515f6c; }

#devmode { flex: 0 0 4px; width: 100%; min-height: 4px; background: url(../images/dev-mode.svg) repeat-x 21px 0; cursor: help; }

#page-container { display: flex; flex-direction: column; flex-grow: 1; }

body.ltr #page-container { padding-left: 226px; }

body.rtl #page-container { padding-right: 226px; }

#alerts { background-color: #CF1124; padding: 11px 0; text-align: center; color: #fff; }

#alerts li { padding: 4px 24px; }

#alerts li:before { font-family: 'Craft'; speak: none; -webkit-font-feature-settings: "liga", "dlig"; -moz-font-feature-settings: "liga=1, dlig=1"; -moz-font-feature-settings: "liga", "dlig"; -ms-font-feature-settings: "liga", "dlig"; -o-font-feature-settings: "liga", "dlig"; font-feature-settings: "liga", "dlig"; text-rendering: optimizeLegibility; font-weight: normal; font-variant: normal; text-transform: none; line-height: 1; direction: ltr; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; display: inline-block; text-align: center; font-style: normal; vertical-align: middle; word-wrap: normal !important; user-select: none; opacity: 0.8; content: 'alert'; font-size: 16px; }

body.ltr #alerts li:before { margin: -1px 3px 0 0 ; }

body.rtl #alerts li:before { margin: -1px 0 0 3px ; }

#alerts li a { color: #fff; text-decoration: underline; }

#alerts li a.go { text-decoration: none; white-space: nowrap; border: 1px solid rgba(255, 255, 255, 0.5); border-radius: 4px; padding: 3px 5px; margin: 0 2px; }

#alerts li a.go:after { color: #fff; }

#alerts li a.go:hover { border-color: #fff; transition: border-color linear 100ms; }

#global-header { margin-bottom: 14px; background: #fff; box-shadow: 0 0 0 1px rgba(205, 216, 228, 0.25), 0 2px 12px rgba(205, 216, 228, 0.5); background: #f3f7fc; }

#global-header .flex { height: 50px; align-items: center; }

#crumbs { display: flex; flex-direction: row; align-items: center; font-size: 13px; }

#crumbs.empty { display: none; }

#crumbs a { color: #606d7b; transition: color linear 100ms; }

#crumbs a:hover { color: #0B69A3; text-decoration: none; }

#crumbs nav { margin: 0; }

#crumbs nav ul { display: flex; flex-direction: row; align-items: stretch; }

#crumbs nav ul li { display: inline-flex; align-items: center; }

#crumbs nav ul li a { padding: 15px 0; }

#crumbs nav ul li:after { margin: 0 7px; display: block; content: '.'; font-size: 0; width: 5px; height: 5px; border: solid rgba(81, 95, 108, 0.25); border-width: 0 2px 2px 0; }

body.ltr #crumbs nav ul li:after { transform: rotate(-45deg); }

body.rtl #crumbs nav ul li:after { transform: rotate(135deg); }

#nav-toggle { display: none; }

#user-info { display: flex; flex-direction: row; align-items: center; height: 100%; background-color: transparent; border-radius: 0; }

body.ltr #user-info { padding: 0 11px 0 14px ; }

body.rtl #user-info { padding: 0 14px 0 11px ; }

body.ltr #user-info { margin-right: 14px; }

body.rtl #user-info { margin-left: 14px; }

#user-info:after { border-color: rgba(81, 95, 108, 0.25); transition: border-color linear 100ms; }

#user-info:hover:after, #user-info.active:after { border-color: rgba(123, 135, 147, 0.5); }

.header-photo { padding: 5px 0; }

.header-photo img { display: block; width: 30px; height: 30px; border-radius: 50%; box-shadow: 0 0 0 1px rgba(31, 41, 51, 0.05), 0 0 0 rgba(31, 41, 51, 0); transition: box-shadow linear 150ms; }

#user-info:hover .header-photo img { box-shadow: 0 0 0 1px rgba(31, 41, 51, 0.05), 0 2px 10px -2px rgba(31, 41, 51, 0.3); }

/* main container */
#main-container { position: relative; flex-grow: 1; }

#notifications { position: fixed; top: 0; width: 100%; z-index: 101; display: flex; justify-content: center; align-items: flex-start; }

@media only screen and (min-width: 1200px) { #notifications { width: calc(100% - 226px); } }

#notifications .notification { padding: 5px 10px; border-radius: 0 0 3px 3px; border-width: 0 1px 1px; color: #fff !important; pointer-events: auto; -moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; font-weight: 500; box-shadow: 0 1px 5px -1px rgba(31, 41, 51, 0.2); }

#notifications .notification.notice { background-color: #0B69A3; }

#notifications .notification.error { background-color: #E12D39; }

#header-container { margin-bottom: 14px; }

body.ltr #crumbs, body.ltr #header { padding-left: 30px; }

body.rtl #crumbs, body.rtl #header { padding-right: 30px; }

body.ltr #crumbs, body.ltr #header { padding-right: 24px; }

body.rtl #crumbs, body.rtl #header { padding-left: 24px; }

#global-header .flex, #header .flex { flex-wrap: nowrap; max-width: 100%; }

#global-header .flex.flex-nowrap, #header .flex.flex-nowrap { min-width: 0; }

#global-header .flex > *, #header .flex > * { margin-bottom: 0; }

#header { display: flex; align-items: flex-start; align-content: stretch; flex-wrap: nowrap; justify-content: space-between; padding-top: 8px; padding-bottom: 8px; position: relative; z-index: 2; width: calc(100vw - 226px); box-sizing: border-box; background-color: rgba(154, 165, 177, 0); box-shadow: 0 1px 0 rgba(51, 64, 77, 0); transition: background-color linear 100ms, box-shadow linear 100ms; }

body.fixed-header #header { position: fixed; z-index: 12; top: 0; background-color: #e4edf6; box-shadow: inset 0 -1px 0 rgba(63, 77, 90, 0.1); }

@supports (backdrop-filter: blur(10px)) { #header { transition: background-color linear 100ms, box-shadow linear 100ms, backdrop-filter linear 100ms; }
  body.fixed-header #header { background-color: rgba(228, 237, 246, 0.75); backdrop-filter: blur(10px); } }

@media only screen and (min-width: 974px) { body.ltr #header .flex:not(:last-child) { margin-right: 7px; }
  body.rtl #header .flex:not(:last-child) { margin-left: 7px; } }

#header h1 { line-height: 32px; margin-top: 0; margin-bottom: 0; min-width: 0; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }

body.ltr #header h1:not(:last-child) { margin-right: 14px !important; }

body.rtl #header h1:not(:last-child) { margin-left: 14px !important; }

#header .buttons, #header .btngroup { margin-top: 0; }

@media only screen and (min-width: 974px) { #page-title.has-toolbar { min-width: 189px !important; } }

#toolbar { flex: 1; align-items: flex-start; }

#toolbar .btn, #toolbar .text { color: #394b60; }

#toolbar .icon:before, #toolbar .texticon:before, #toolbar .menubtn:after, #toolbar [data-icon]:before, #toolbar [data-icon-after]:after { color: #4d6480; }

#toolbar .text { border-radius: 5px; box-shadow: none; }

#toolbar .text::-webkit-input-placeholder { color: #4d6480; }

#toolbar .text:-ms-input-placeholder { color: #4d6480; }

#toolbar .text::-ms-input-placeholder { color: #4d6480; }

#toolbar .text:-moz-placeholder { color: #4d6480; }

#toolbar .text::-moz-placeholder { color: #4d6480; }

#toolbar .text::placeholder { color: #4d6480; }

#toolbar .text:not(:focus) { background-clip: border-box; }

#toolbar .spinner { background-image: url(../images/spinner_toolbar.gif); }

#action-button { height: 34px; }

#main-content { display: flex; flex-direction: row; align-items: flex-start; width: calc(100vw - 226px); padding: 0 24px 48px; box-sizing: border-box; }

#main-content > .grid:only-child { flex: 1; }

#sidebar-toggle-container { display: none; }

#sidebar-container { flex: 0 0 226px; width: 226px; box-sizing: border-box; }

body.ltr #sidebar-container { margin-left: -24px; }

body.rtl #sidebar-container { margin-right: -24px; }

#sidebar { box-sizing: border-box; padding: 0 31px; width: 226px; background-color: transparent; }

#sidebar.fixed { position: fixed; overflow-y: auto; padding-top: 14px; padding-bottom: 14px; }

#sidebar nav { margin-left: -31px; margin-right: -31px; }

#sidebar nav li a:not(.sel):hover { background-color: #cdd8e4; }

#sidebar nav li a.sel { background: #fff; box-shadow: 0 0 0 1px rgba(205, 216, 228, 0.25), 0 2px 12px rgba(205, 216, 228, 0.5); background-color: #f3f7fc; }

#content-container { flex: 1; }

#main-content.has-sidebar #content-container { width: calc(100% - 202px); max-width: calc(100% - 202px); }

#main-content.has-details #content-container { width: calc(100% - 350px - 14px); max-width: calc(100% - 350px - 14px); }

#content-container #main-content.has-sidebar.has-details { width: calc(100% - 226px - 350px - 38px); max-width: calc(100% - 226px - 350px - 38px); }

#tabs { margin: -10px -12px 0; padding: 10px 12px 0; overflow: hidden; display: flex; align-items: center; }

#tabs ul { display: flex; flex-direction: row; max-width: calc(100% - 40px); }

#tabs ul li { max-width: 100%; }

body.ltr #tabs ul li + li { margin-left: -12px; }

body.rtl #tabs ul li + li { margin-right: -12px; }

#tabs ul li a { display: block; position: relative; border-radius: 4px 4px 0 0; padding: 12px 24px; max-width: 100%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; box-sizing: border-box; line-height: 1.2; }

#tabs ul li a:hover { text-decoration: none; }

#tabs ul li a:not(.sel) { color: #596673; }

#tabs ul li a:not(.sel):hover { color: #3f4d5a; background-image: linear-gradient(to bottom, #f3f7fc, rgba(243, 247, 252, 0.5)); }

#tabs ul li a.sel { background: #fff; box-shadow: 0 0 0 1px rgba(205, 216, 228, 0.25), 0 2px 12px rgba(205, 216, 228, 0.5); z-index: 1; color: #3f4d5a; cursor: default; background-clip: padding-box; }

#tabs #overflow-tab-btn { margin-left: 10px; width: 30px; height: 17px; padding: 0; line-height: 16px; border-radius: 3px; color: #596673; }

#tabs #overflow-tab-btn:before { margin: 0; }

#tabs #overflow-tab-btn:after { display: none; }

.content-pane { background: #fff; box-shadow: 0 0 0 1px rgba(205, 216, 228, 0.25), 0 2px 12px rgba(205, 216, 228, 0.5); border-radius: 5px; position: relative; background-clip: padding-box; padding: 24px; word-wrap: break-word; }

body.ltr .content-pane.square { border-top-left-radius: 0; }

body.rtl .content-pane.square { border-top-right-radius: 0; }

.content-pane > hr { margin-left: -24px; margin-right: -24px; }

#footer { z-index: 1; margin: 25px -24px -24px; padding: 10px 24px; background-color: #f3f7fc; border-radius: 0 0 5px 5px; }

#footer:empty { display: none; }

#footer > *, #footer .flex > * { margin-bottom: 0; }

#details-container { flex: 0 0 388px; width: 388px; box-sizing: border-box; }

body.ltr #details-container { margin-right: -24px; }

body.rtl #details-container { margin-left: -24px; }

#details { box-sizing: border-box; padding-bottom: 24px; width: 388px; }

body.ltr #details { padding-right: 24px; }

body.rtl #details { padding-left: 24px; }

body.ltr #details { padding-left: 14px; }

body.rtl #details { padding-right: 14px; }

#main-content.has-tabs #details { padding-top: 40px; }

#details.fixed { position: fixed; overflow-y: auto; padding-top: 14px; }

#main-content.has-tabs #details.fixed { padding-top: 54px; }

#details .meta { margin-bottom: 14px; border-radius: 5px; padding: 0 24px; }

#details .meta:not(.read-only):not(.warning) { background: #fff; box-shadow: 0 0 0 1px rgba(205, 216, 228, 0.25), 0 2px 12px rgba(205, 216, 228, 0.5); background-color: #f3f7fc; }

#details .meta.read-only { padding-top: 14px; padding-bottom: 14px; background-color: #cdd8e4; }

#details .meta.warning { border: 2px solid #FADB5F; padding: 19px 22px; background: #f3f7fc; color: #3f4d5a !important; }

#details .meta.warning p { margin-bottom: 5px; }

#details .meta.warning .btn { background-color: #FADB5F; }

#details .meta.warning .btn:hover, #details .meta.warning .btn:focus { background-color: #f8d02e; }

#details .meta.warning .btn:active { background-color: #f8ca15; }

#details .meta > .field, #details .meta > .data { margin: 0 -24px !important; }

#details .meta > .field:first-child, #details .meta > .data:first-child { border-top-left-radius: 5px; border-top-right-radius: 5px; }

#details .meta > .field:last-child, #details .meta > .data:last-child { border-bottom-left-radius: 5px; border-bottom-right-radius: 5px; }

#details .meta > .field > .heading > label, #details .meta > .field > .heading, #details .meta > .data > .heading > label, #details .meta > .data > .heading { color: #515f6c; }

#details .meta > .field .status-badge { top: calc(50% - 8px); }

body.ltr #details .meta > .field .status-badge { left: 4px; }

body.rtl #details .meta > .field .status-badge { right: 4px; }

#details .meta .text::placeholder, #details .meta .datewrapper .text:placeholder-shown + div[data-icon], #details .meta .timewrapper .text:placeholder-shown + div[data-icon] { color: #9aa5b1; }

body.ltr #details .meta .ui-datepicker { margin: 0 0 0 -8px ; }

body.rtl #details .meta .ui-datepicker { margin: 0 -8px 0 0 ; }

#details hr { margin: 14px 0; border-top-color: rgba(51, 64, 77, 0.1); }

#details .spinner { background-image: url(../images/spinner_details.gif); }

#details .text { background-color: transparent; }

#details > .text { box-shadow: none; border-radius: 5px; margin-bottom: 14px; }

@media only screen and (max-width: 1199px) { #global-container { width: calc(100vw + 226px); }
  body.ltr #global-container { left: -226px; }
  body.rtl #global-container { right: -226px; }
  body.ltr #global-container { transition: left 250ms ease-in-out; }
  body.rtl #global-container { transition: right 250ms ease-in-out; }
  body.ltr.showing-nav #global-container { left: 0; }
  body.rtl.showing-nav #global-container { right: 0; }
  #crumbs { display: flex !important; }
  #header { width: 100vw; }
  #nav-toggle { display: flex; align-items: center; justify-content: center; width: 36px; height: 50px; line-height: 26px; color: #9aa5b1; text-align: center; }
  body.ltr #nav-toggle { margin-left: -10px; }
  body.rtl #nav-toggle { margin-right: -10px; }
  #nav-toggle:before { font-family: 'Craft'; speak: none; -webkit-font-feature-settings: "liga", "dlig"; -moz-font-feature-settings: "liga=1, dlig=1"; -moz-font-feature-settings: "liga", "dlig"; -ms-font-feature-settings: "liga", "dlig"; -o-font-feature-settings: "liga", "dlig"; font-feature-settings: "liga", "dlig"; text-rendering: optimizeLegibility; font-weight: normal; font-variant: normal; text-transform: none; line-height: 1; direction: ltr; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; display: inline-block; text-align: center; font-style: normal; vertical-align: middle; word-wrap: normal !important; user-select: none; opacity: 0.8; content: 'menu'; font-size: 16px; line-height: 0; }
  #nav-toggle:hover { color: #7b8793; }
  #main-content { width: 100vw; } }

@media only screen and (max-width: 973px) { #header { display: block; }
  #header .flex:not(#toolbar) { margin-top: 10px; }
  #toolbar { flex-wrap: wrap !important; }
  #toolbar > * { margin-top: 10px !important; }
  body.fixed-header #header .flex:first-child { margin-top: 0; }
  #main-content { display: block; }
  #sidebar-toggle-container { display: block; margin-bottom: 14px; }
  body.showing-sidebar #sidebar-toggle { background-color: #acbed2 !important; }
  body.showing-sidebar #sidebar-toggle:after { transform: rotate(225deg); top: 1px; }
  #sidebar-container, #details-container { width: auto !important; }
  #sidebar, #details { position: static !important; overflow-y: visible !important; max-height: none !important; width: auto; }
  #sidebar { margin-bottom: 14px; }
  body:not(.showing-sidebar) #sidebar { display: none; }
  body.ltr #details-container { margin-right: 0; }
  body.rtl #details-container { margin-left: 0; }
  #content-container { width: auto !important; max-width: none !important; }
  #details { padding: 0 !important; margin-top: 14px; } }

@media only screen and (max-width: 767px) { #crumbs, #header, #main-content { padding-left: 10px; padding-right: 10px; }
  #tabs ul li a { padding-left: 14px; padding-right: 14px; }
  body.ltr #tabs ul li + li { margin-left: -7px; }
  body.rtl #tabs ul li + li { margin-right: -7px; } }

body.ltr #settingsmenu ul li a { padding-left: 46px; }

body.rtl #settingsmenu ul li a { padding-right: 46px; }

#settingsmenu ul li a:before { font-size: 15px; }

body.ltr #settingsmenu ul li a:before { margin: 1px 0 0 -22px ; }

body.rtl #settingsmenu ul li a:before { margin: 1px -22px 0 0 ; }

#settingsmenu ul li a img { width: 16px; height: 16px; position: absolute; margin-left: -23px; margin-top: 1px; }

/* grids */
.grid { position: relative; }

.grid:after { content: '.'; display: block; height: 0; clear: both; visibility: hidden; }

.grid > .item { display: none; box-sizing: border-box; }

/* Sidebar */
.sidebar { padding: 14px 0; width: 226px; box-sizing: border-box; font-size: 13px; background-color: #f3f7fc; }

.sidebar .heading { position: relative; margin: 14px 24px 2px; }

.sidebar .heading span { display: inline-block; position: relative; z-index: 1; padding: 0 5px; margin: 0 -5px; text-transform: uppercase; color: #596673; font-size: 11px; font-weight: bold; }

.sidebar nav { padding: 0 7px; }

body.ltr .sidebar nav > ul > li > a { padding-left: 24px; }

body.rtl .sidebar nav > ul > li > a { padding-right: 24px; }

body.ltr .sidebar nav > ul > li > .toggle { left: 0px; }

body.rtl .sidebar nav > ul > li > .toggle { right: 0px; }

body.ltr .sidebar nav > ul > li > ul > li > a { padding-left: 38px; }

body.rtl .sidebar nav > ul > li > ul > li > a { padding-right: 38px; }

body.ltr .sidebar nav > ul > li > ul > li > .toggle { left: 14px; }

body.rtl .sidebar nav > ul > li > ul > li > .toggle { right: 14px; }

body.ltr .sidebar nav > ul > li > ul > li > ul > li > a { padding-left: 52px; }

body.rtl .sidebar nav > ul > li > ul > li > ul > li > a { padding-right: 52px; }

body.ltr .sidebar nav > ul > li > ul > li > ul > li > .toggle { left: 28px; }

body.rtl .sidebar nav > ul > li > ul > li > ul > li > .toggle { right: 28px; }

body.ltr .sidebar nav > ul > li > ul > li > ul > li > ul > li > a { padding-left: 66px; }

body.rtl .sidebar nav > ul > li > ul > li > ul > li > ul > li > a { padding-right: 66px; }

body.ltr .sidebar nav > ul > li > ul > li > ul > li > ul > li > .toggle { left: 42px; }

body.rtl .sidebar nav > ul > li > ul > li > ul > li > ul > li > .toggle { right: 42px; }

body.ltr .sidebar nav > ul > li > ul > li > ul > li > ul > li > ul > li > a { padding-left: 80px; }

body.rtl .sidebar nav > ul > li > ul > li > ul > li > ul > li > ul > li > a { padding-right: 80px; }

body.ltr .sidebar nav > ul > li > ul > li > ul > li > ul > li > ul > li > .toggle { left: 56px; }

body.rtl .sidebar nav > ul > li > ul > li > ul > li > ul > li > ul > li > .toggle { right: 56px; }

.sidebar nav li { position: relative; }

.sidebar nav li:not(.has-subnav) > a { transition: background-color linear 100ms; }

.sidebar nav li:not(.has-subnav) > a:hover { text-decoration: none; background-color: #e4edf6; }

.sidebar nav li:not(.has-subnav) > a.sel { cursor: default; background-color: #cdd8e4; }

.sidebar nav li a { position: relative; margin-bottom: 1px; display: flex; flex-direction: row; align-items: center; padding: 7px 24px; min-height: 24px; box-sizing: border-box; color: #3f4d5a; user-select: none; outline: none; border-radius: 5px; }

.sidebar nav li a .status { flex-shrink: 1; }

.sidebar nav li a .icon { flex-shrink: 1; width: 18px; height: 18px; transition: margin-left linear 150ms, margin-right linear 150ms; }

body.ltr .sidebar nav li a .icon { margin-right: 10px; }

body.rtl .sidebar nav li a .icon { margin-left: 10px; }

.sidebar nav li a .icon.icon-mask svg rect, .sidebar nav li a .icon.icon-mask svg circle, .sidebar nav li a .icon.icon-mask svg ellipse, .sidebar nav li a .icon.icon-mask svg line, .sidebar nav li a .icon.icon-mask svg polyline, .sidebar nav li a .icon.icon-mask svg polygon, .sidebar nav li a .icon.icon-mask svg path, .sidebar nav li a .icon.icon-mask svg text { fill: #596673; stroke-width: 0; transition: fill linear 100ms; }

.sidebar nav li a .icon.icon-mask span[data-icon] { color: #596673; }

.sidebar nav li a .icon svg { width: 16px; height: 16px; }

.sidebar nav li a .icon span[data-icon] { font-size: 16px; }

.sidebar nav li a .icon span[data-icon]::before { display: block; }

.sidebar nav li a .label { flex: 1; }

.sidebar nav li a .badge { flex-shrink: 1; padding: 0 6px; font-size: 11px; line-height: 16px; border-radius: 8px; background-color: #596673; color: #fff; }

body.ltr .sidebar nav li a .badge { margin: 0 -4px 0 10px ; }

body.rtl .sidebar nav li a .badge { margin: 0 10px 0 -4px ; }

.sidebar nav li .toggle { position: absolute; height: 34px; top: 0; width: 24px; display: flex; align-items: center; justify-content: center; }

.sidebar nav li ul { display: none; }

.sidebar nav li.expanded > ul { display: block; }

body.ltr .content.has-sidebar:not(.hiding-sidebar) { margin-left: 226px; }

body.rtl .content.has-sidebar:not(.hiding-sidebar) { margin-right: 226px; }

/* flexbox field layouts */
#content :not(.meta) > .flex-fields { display: flex; flex-wrap: wrap; align-content: flex-start; margin: 0 -14px -24px 0; width: calc(100% + 14px); }

#content :not(.meta) > .flex-fields > h2 { margin: 0 -10px 24px -24px !important; padding: 0 10px 0 24px; width: calc(100% + 34px); }

#content :not(.meta) > .flex-fields > h2:not(:first-child) { padding-top: 24px; border-top: 1px solid rgba(51, 64, 77, 0.1); }

#content :not(.meta) > .flex-fields > hr { margin: 0 -10px 24px -24px; width: calc(100% + 34px); }

#content :not(.meta) > .flex-fields > :not(h2):not(hr), #content :not(.meta) > .flex-fields > :not(h2):not(hr):last-child { margin: 0 14px 24px 0 !important; width: calc(100% - 14px); }

@media only screen and (min-width: 1426px) { #content :not(.meta) > .flex-fields > :not(h2):not(hr).width-25, #content :not(.meta) > .flex-fields > :not(h2):not(hr):last-child.width-25 { width: calc(25% - 14px); }
  #content :not(.meta) > .flex-fields > :not(h2):not(hr).width-50, #content :not(.meta) > .flex-fields > :not(h2):not(hr):last-child.width-50 { width: calc(50% - 14px); }
  #content :not(.meta) > .flex-fields > :not(h2):not(hr).width-75, #content :not(.meta) > .flex-fields > :not(h2):not(hr):last-child.width-75 { width: calc(75% - 14px); } }

@media only screen and (min-width: 500px) and (max-width: 1425px) { #content :not(.meta) > .flex-fields > :not(h2):not(hr).width-25, #content :not(.meta) > .flex-fields > :not(h2):not(hr).width-50, #content :not(.meta) > .flex-fields > :not(h2):not(hr):last-child.width-25, #content :not(.meta) > .flex-fields > :not(h2):not(hr):last-child.width-50 { width: calc(50% - 14px); } }

/* retina */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (-moz-min-device-pixel-ratio: 1.5), only screen and (-o-min-device-pixel-ratio: 3 / 2), only screen and (min-device-pixel-ratio: 1.5), only screen and (min-resolution: 1.5dppx) { #details .spinner { background-image: url(../images/spinner_details_2x.gif); background-size: 20px; }
  #toolbar .spinner { background-image: url(../images/spinner_toolbar_2x.gif); background-size: 20px; }
  #upgrademodal.loading { background-image: url(../images/spinner_big_2x.gif); background-size: 48px; }
  #upgrademodal-success .message { background-image: url(../images/success_2x.png); background-size: 48px; } }

.lp-editor-container, .lp-preview-container { position: fixed; top: 0; height: 100%; background-color: #fff; z-index: 100; }

.lp-editor-container header, .lp-preview-container header { padding: 8px 24px; background-color: #e4edf6; box-shadow: 0 1px 0 rgba(63, 77, 90, 0.2); box-sizing: border-box; }

@supports (backdrop-filter: blur(10px)) { .lp-editor-container header, .lp-preview-container header { position: absolute; z-index: 2; top: 0; left: 0; width: 100%; background-color: rgba(228, 237, 246, 0.75); backdrop-filter: blur(10px); }
  .lp-editor-container header + .lp-editor, .lp-preview-container header + .lp-editor { padding-top: 78px; } }

@supports (-moz-appearance: none) { .lp-editor-container header, .lp-preview-container header { position: relative; z-index: 1; } }

.lp-editor-container header .btn, .lp-editor-container header .spinner, .lp-editor-container header .checkmark-icon, .lp-preview-container header .btn, .lp-preview-container header .spinner, .lp-preview-container header .checkmark-icon { margin-bottom: 0; }

.lp-editor-container { display: flex; flex-direction: column; box-shadow: 1px 0 0 rgba(63, 77, 90, 0.2); }

.lp-editor-container .lp-editor { flex: 1; padding: 24px; overflow: auto; box-sizing: border-box; }

.lp-editor-container .lp-editor > .field:last-child { margin-bottom: 24px !important; }

.lp-editor-container .lp-draghandle { position: absolute; z-index: 6; top: 0; width: 4px; height: 100%; cursor: col-resize; }

body.ltr .lp-editor-container .lp-draghandle { right: -2px; }

body.rtl .lp-editor-container .lp-draghandle { left: -2px; }

.lp-preview-container { display: flex; flex-direction: column; }

.lp-preview-container.dragging:after { position: absolute; top: 0; left: 0; width: 100%; height: 100%; content: ''; font-size: 0; }

.lp-preview-container .lp-preview-header { display: flex; }

@supports (-moz-appearance: none) { .lp-preview-container .lp-preview-header { background-color: rgba(228, 237, 246, 0.75); } }

.lp-preview-container .lp-preview-header + .lp-iframe-container { padding-top: 50px; }

@supports (-moz-appearance: none) { .lp-preview-container .lp-preview-header + .lp-iframe-container { padding-top: 0; } }

.lp-preview-container .lp-preview-header .lp-device-type .btn::before { width: 27px; height: 22px; display: block; content: ''; background-size: contain; background-repeat: no-repeat; background-position: center; transition-duration: .3s; }

.lp-preview-container .lp-preview-header .lp-device-type .btn.lp-device-type-btn--phone::before { background-image: url(../images/preview/icon-phone.svg); }

.lp-preview-container .lp-preview-header .lp-device-type .btn.lp-device-type-btn--tablet::before { background-image: url(../images/preview/icon-tablet.svg); }

.lp-preview-container .lp-preview-header .lp-device-type .btn.lp-device-type-btn--desktop::before { background-image: url(../images/preview/icon-desktop.svg); }

.lp-preview-container .lp-device-mask { display: none; }

.lp-preview-container .lp-iframe-container { flex: 1; overflow: auto; }

.lp-preview-container .lp-iframe-container .lp-preview { display: block; width: 100%; min-height: 100%; }

.lp-preview-container .lp-iframe-container--has-device-preview { flex: none; padding-top: 0 !important; position: relative; top: 50px; height: calc(100% - 50px); width: 100%; background-color: #e4edf6; }

@supports (-moz-appearance: none) { .lp-preview-container .lp-iframe-container--has-device-preview { top: 0; } }

.lp-preview-container .lp-iframe-container--has-device-preview .lp-device-preview-container { position: absolute; top: 50%; left: 50%; z-index: 2; overflow: auto; opacity: 1; transition: opacity .3s; }

.lp-preview-container .lp-iframe-container--has-device-preview .lp-device-mask { display: block; position: absolute; top: 50%; left: 50%; z-index: 1; background-image: url(../images/preview/chrome-phone-top.svg), url(../images/preview/chrome-phone-bottom.svg); background-position: top center, bottom center; background-repeat: no-repeat; background-size: contain; background-color: #fff; box-shadow: 0 0 58px rgba(6, 67, 117, 0.3); border-radius: 18px; }

.lp-preview-container .lp-iframe-container--has-device-preview.lp-iframe-container--tablet .lp-device-mask { background-image: url(../images/preview/chrome-tablet-top.svg), url(../images/preview/chrome-tablet-bottom.svg); }

.lp-preview-container .lp-iframe-container--animating .lp-device-mask { transition-duration: .3s; transition-property: transform; }

.lp-preview-container .lp-iframe-container--animating .lp-device-preview-container, .lp-preview-container .lp-iframe-container--updating .lp-device-preview-container { opacity: 0; transition: opacity 0s; }

.layoutdesigner { display: flex; align-items: stretch; position: relative; border-radius: 3px; border: 1px solid rgba(96, 125, 159, 0.25); background-color: #fbfcfe; box-shadow: inset 0 1px 4px -1px rgba(96, 125, 159, 0.25); background-clip: padding-box; overflow: hidden; box-shadow: none; min-height: 500px; }

body.ltr .layoutdesigner { padding-right: 241px; }

body.rtl .layoutdesigner { padding-left: 241px; }

.layoutdesigner .fld-workspace { flex: 1; background-color: #f3f7fc; background-image: linear-gradient(to right, #ecf2f9 1px, transparent 0px), linear-gradient(to bottom, #ecf2f9 1px, transparent 1px); background-size: 24px 24px; background-position: -1px -1px; box-shadow: inset 0 1px 3px -1px #acbed2; }

body.ltr .layoutdesigner .fld-workspace { border-radius: 3px 0 0 3px; }

body.rtl .layoutdesigner .fld-workspace { border-radius: 0 3px 3px 0; }

body.ltr .layoutdesigner .fld-workspace { padding: 24px 0 24px 24px ; }

body.rtl .layoutdesigner .fld-workspace { padding: 24px 24px 24px 0 ; }

.layoutdesigner .fld-workspace .fld-tabs { display: flex; align-items: flex-start; flex-wrap: wrap; }

.layoutdesigner .fld-sidebar { position: absolute; top: 0; height: 100%; width: 241px; padding: 14px; overflow: auto; box-sizing: border-box; }

body.ltr .layoutdesigner .fld-sidebar { right: 0; }

body.rtl .layoutdesigner .fld-sidebar { left: 0; }

.layoutdesigner .fld-sidebar .btngroup { margin-bottom: 14px; }

.layoutdesigner .fld-sidebar .fld-field-library .fld-field-group { margin-top: 14px; }

.layoutdesigner .fld-sidebar .fld-field-library .fld-field-group h6 { margin-bottom: 7px; }

.layoutdesigner .fld-sidebar .filtered { display: none; }

.layoutdesigner .fld-new-tab-btn:active { background-color: #f3f7fc; }

.fld-sidebar, .fld-tab .tabs .tab, .fld-tab .fld-tabcontent, .fld-new-tab-btn, .fld-new-tab-btn:hover { background-color: #fff; box-shadow: 0 0 0 1px rgba(31, 41, 51, 0.1), 0 2px 5px -2px rgba(31, 41, 51, 0.2); }

body.ltr .fld-tab .settings, body.ltr .fld-element .settings, body.ltr .fld-element .slide-picker { margin-left: 7px; }

body.rtl .fld-tab .settings, body.rtl .fld-element .settings, body.rtl .fld-element .slide-picker { margin-right: 7px; }

.fld-tab .settings:before, .fld-element .settings:before { margin-top: -2px; font-size: 16px; opacity: 0.5; }

.fld-tab .settings:hover:before, .fld-tab .settings.active:before, .fld-element .settings:hover:before, .fld-element .settings.active:before { opacity: 1; }

.fld-tab { width: 264px; box-sizing: border-box; }

body.ltr .fld-tab { padding: 0 25px 24px 0 ; }

body.rtl .fld-tab { padding: 0 0 24px 25px ; }

.fld-tab .tabs { margin: -10px -12px 0; padding: 10px 12px 0; overflow: hidden; display: flex; }

.fld-tab .tabs .tab { display: flex; max-width: calc(100% - 10px); box-sizing: border-box; padding: 8px 14px; border-radius: 4px 4px 0 0; }

body:not(.dragging) .fld-tab .tabs .tab.draggable { cursor: move; cursor: grab; }

.fld-tab .tabs .tab span { white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }

.fld-tab .fld-tabcontent { padding: 14px; }

body.ltr .fld-tab .fld-tabcontent { border-radius: 0 4px 4px 4px; }

body.rtl .fld-tab .fld-tabcontent { border-radius: 4px 0 4px 4px; }

.fld-tab.fld-insertion .tabs .tab, .fld-tab.fld-insertion .fld-tabcontent { margin: -2px; border: 2px dashed rgba(51, 64, 77, 0.1); box-shadow: none; background-color: #f3f7fc; background-image: linear-gradient(to right, #ecf2f9 1px, transparent 0px), linear-gradient(to bottom, #ecf2f9 1px, transparent 1px); background-size: 24px 24px; }

.fld-tab.fld-insertion .tabs .tab { background-position: -1px -1px; }

.fld-tab.fld-insertion .fld-tabcontent { background-position: -1px -13px; }

.fld-tab-caboose { min-height: 24px; }

.fld-element { position: relative; display: flex; align-items: center; padding: 7px 10px; box-shadow: inset 0 0 0 1px rgba(51, 64, 77, 0.1); background-color: #fff; border-radius: 4px; }

body:not(.dragging) .fld-element { cursor: move; cursor: grab; }

.fld-element + .fld-element { margin-top: 7px; }

.fld-element.fld-insertion { box-sizing: border-box; border: 2px dashed rgba(51, 64, 77, 0.1); border-radius: 4px; background: none; box-shadow: none; }

.fld-element.draghelper { box-shadow: 0 1px 5px -1px rgba(31, 41, 51, 0.2); }

.fld-element.fld-field { color: #596673; background-color: #e4edf6; }

.fld-element.fld-field:not(.draghelper) { box-shadow: none; }

.fld-element.fld-field .fld-field-hidden:not(.hidden) { margin-top: -3px; }

body.ltr .fld-element.fld-field .fld-field-hidden:not(.hidden) { margin-right: 5px; }

body.rtl .fld-element.fld-field .fld-field-hidden:not(.hidden) { margin-left: 5px; }

.fld-element.fld-field .fld-field-hidden:not(.hidden):before { font-family: 'Craft'; speak: none; -webkit-font-feature-settings: "liga", "dlig"; -moz-font-feature-settings: "liga=1, dlig=1"; -moz-font-feature-settings: "liga", "dlig"; -ms-font-feature-settings: "liga", "dlig"; -o-font-feature-settings: "liga", "dlig"; font-feature-settings: "liga", "dlig"; text-rendering: optimizeLegibility; font-weight: normal; font-variant: normal; text-transform: none; line-height: 1; direction: ltr; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; display: inline-block; text-align: center; font-style: normal; vertical-align: middle; word-wrap: normal !important; user-select: none; opacity: 0.8; content: 'shuteye'; }

.fld-element.fld-field .field-name .fld-element-label { margin-bottom: 2px; }

.fld-element .fld-element-icon { text-align: center; }

body.ltr .fld-element .fld-element-icon { margin-right: 5px; }

body.rtl .fld-element .fld-element-icon { margin-left: 5px; }

.fld-element .fld-element-icon, .fld-element .fld-element-icon svg { width: 16px; height: 16px; }

.fld-element .fld-element-icon svg rect, .fld-element .fld-element-icon svg circle, .fld-element .fld-element-icon svg ellipse, .fld-element .fld-element-icon svg line, .fld-element .fld-element-icon svg polyline, .fld-element .fld-element-icon svg polygon, .fld-element .fld-element-icon svg path, .fld-element .fld-element-icon svg text { fill: #606d7b; stroke-width: 0; transition: fill linear 100ms; }

.fld-element .field-name { flex: 1; overflow: hidden; }

.fld-element .field-name .fld-element-label, .fld-element .field-name .fld-attribute { flex: 1; display: flex; }

.fld-element .field-name .fld-element-label h4, .fld-element .field-name .fld-attribute .smalltext { white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }

.fld-element .field-name .fld-element-label h4 { font-weight: normal; color: #3f4d5a; margin: 0; }

.fld-element .field-name .fld-element-label h4 + .fld-required-indicator { color: #3f4d5a; }

.fld-element .field-name .fld-attribute .fld-required-indicator { margin-top: -1px; }

.fld-element.fld-required .fld-required-indicator { line-height: 1; }

body.ltr .fld-element.fld-required .fld-required-indicator { margin-left: 2px; }

body.rtl .fld-element.fld-required .fld-required-indicator { margin-right: 2px; }

.fld-element.fld-required .fld-required-indicator:after { font-family: 'Craft'; speak: none; -webkit-font-feature-settings: "liga", "dlig"; -moz-font-feature-settings: "liga=1, dlig=1"; -moz-font-feature-settings: "liga", "dlig"; -ms-font-feature-settings: "liga", "dlig"; -o-font-feature-settings: "liga", "dlig"; font-feature-settings: "liga", "dlig"; text-rendering: optimizeLegibility; font-weight: normal; font-variant: normal; text-transform: none; line-height: 1; direction: ltr; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; display: inline-block; text-align: center; font-style: normal; vertical-align: middle; word-wrap: normal !important; user-select: none; opacity: 0.8; content: 'asterisk'; font-size: 8px; line-height: 1; }

body.dragging { cursor: move !important; cursor: grabbing !important; }

.fld-hr { display: flex; justify-content: center; padding: 9px 10px; }

.fld-hr:before { position: absolute; display: block; top: calc(50% - 2px); left: 10px; width: calc(100% - 20px); height: 4px; content: ''; font-size: 0; background-color: #e4edf6; border-radius: 2px; }

.fld-hr .smalltext { position: relative; background-color: #e4edf6; border-radius: 8px; padding: 1px 7px; }

.fld-element-settings { width: 240px; }

/* No Scroll */
html.noscroll, html.noscroll body { overflow: hidden; height: 100%; }

/* Image Editor */
.modal.imageeditor { background-color: #1f2933; color: #fff; -moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; font-weight: 500; opacity: 0; user-select: none; }

.modal.imageeditor.modal { border-radius: 0; }

.modal.imageeditor.modal .body:after { display: none; }

.modal.imageeditor .btn:not(.submit):not(.noborder) { background-color: #515f6c; color: #fff; }

.modal.imageeditor .btn:not(.submit):not(.noborder):hover { background-color: #3f4d5a; }

.modal.imageeditor .btn:not(.submit):not(.noborder).active { background-color: #33404d; }

.modal.imageeditor .icon::before { width: 24px; height: 24px; margin-top: -4px; margin-left: -3px; display: inline-block; content: ''; }

.modal.imageeditor .icon.rotate-left::before { background-image: url(../images/image-editor/rotate-left.svg); }

.modal.imageeditor .icon.rotate-right::before { background-image: url(../images/image-editor/rotate-right.svg); }

.modal.imageeditor .icon.flip-vertical::before { background-image: url(../images/image-editor/flip-vertical.svg); }

.modal.imageeditor .icon.flip-horizontal::before { background-image: url(../images/image-editor/flip-horizontal.svg); }

.modal.imageeditor .icon.constraint::before { background-image: url(../images/image-editor/unconstrained.svg); }

.modal.imageeditor .icon.focal-point::before { background-image: url(../images/image-editor/focal-point.svg); }

.modal.imageeditor .body { display: flex; padding: 0; }

.modal.imageeditor .body .tabs { border-right: 1px solid #131a20; }

.modal.imageeditor .body .tabs ul { width: 100px; flex-direction: column; align-items: stretch; }

.modal.imageeditor .body .tabs ul li { padding: 14px; text-align: center; border-bottom: 1px solid #131a20; }

.modal.imageeditor .body .tabs ul li i { background-repeat: no-repeat; background-position: top center; display: block; height: 24px; }

.modal.imageeditor .body .tabs ul li[data-view="rotate"] i { background-image: url(../images/image-editor/rotate-left.svg); }

.modal.imageeditor .body .tabs ul li[data-view="crop"] i { background-image: url(../images/image-editor/crop.svg); }

.modal.imageeditor .body .tabs ul li[data-view="filters"] i { background-image: url(../images/image-editor/filters.svg); }

.modal.imageeditor .body .tabs ul li:hover { background-color: #1b242c; }

.modal.imageeditor .body .tabs ul li.selected { background-color: #151c23; }

.modal.imageeditor .body .tabs ul li:hover { cursor: pointer; }

.modal.imageeditor .body .views { padding: 24px; overflow: auto; }

.modal.imageeditor .body .views .btngroup { margin-bottom: 24px; }

.modal.imageeditor .body .views .filters ul { width: 100px; }

.modal.imageeditor .body .views .filters ul li { text-align: center; margin-right: 14px; margin-bottom: 14px; }

.modal.imageeditor .body .views .filters ul li:last-child { margin-right: 0; }

.modal.imageeditor .body .views .filters ul li img { border: 3px solid transparent; border-radius: 4px; }

.modal.imageeditor .body .views .filters ul li span { display: block; }

.modal.imageeditor .body .views .filters ul li:hover { cursor: pointer; }

.modal.imageeditor .body .views .filters ul li.selected img { border-color: #fff; }

.modal.imageeditor .body .image-container { flex-grow: 1; display: flex; flex-direction: column; position: relative; overflow: hidden; }

.modal.imageeditor .body .image-container .image { text-align: center; flex-grow: 1; position: relative; }

.modal.imageeditor .body .image-container .image canvas { position: absolute; top: 0; left: 0; }

.modal.imageeditor .body .image-container .image-tools { text-align: center; }

.modal.imageeditor .body .image-container .image-tools .straightening { max-width: 400px; display: block; margin: 0 auto; overflow: hidden; }

.modal.imageeditor .footer { position: absolute; background-color: transparent; bottom: 0; left: 0; width: 100%; border-top: 1px solid #000; }

.modal.imageeditor .body .views .crop .orientation { display: flex; align-items: center; justify-content: center; }

.modal.imageeditor .body .views .crop .orientation input { -webkit-appearance: none; -moz-appearance: none; appearance: none; background-position: center center; background-repeat: no-repeat; margin: 0 4px; cursor: pointer; }

.modal.imageeditor .body .views .crop .orientation input:focus { outline: none; }

.modal.imageeditor .body .views .crop .orientation input[value="landscape"] { width: 30px; height: 20px; background-image: url(../images/image-editor/orientation-landscape-unchecked.svg); }

.modal.imageeditor .body .views .crop .orientation input[value="landscape"]:checked { background-image: url(../images/image-editor/orientation-landscape-checked.svg); }

.modal.imageeditor .body .views .crop .orientation input[value="portrait"] { width: 20px; height: 30px; background-image: url(../images/image-editor/orientation-portrait-unchecked.svg); }

.modal.imageeditor .body .views .crop .orientation input[value="portrait"]:checked { background-image: url(../images/image-editor/orientation-portrait-checked.svg); }

.modal.imageeditor.vertical .body .views .crop > .btngroup:first-child { justify-content: center; }

.modal.imageeditor.vertical .body .views .crop .constraint-buttons-container { display: flex; justify-content: center; }

.modal.imageeditor.vertical .body .views .crop .constraint-buttons-container .constraint-buttons { overflow: auto; margin-left: -24px; margin-right: -24px; margin-bottom: 12px; padding-left: 24px; padding-right: 24px; padding-bottom: 12px; }

.modal.imageeditor.vertical .body .views .crop .constraint-buttons-container .constraint-buttons .constraint { padding: 4px 12px; }

.modal.imageeditor.vertical .body .views .crop .constraint-buttons-container .constraint-buttons .constraint:hover { cursor: pointer; }

.modal.imageeditor.vertical .body .views .crop .constraint-buttons-container .constraint-buttons .constraint.active { color: #fff; background: #515f6c; border-radius: 1rem; }

.modal.imageeditor.vertical .body .views .crop .constraint-buttons-container .constraint-buttons .constraint:last-child { position: relative; }

.modal.imageeditor.vertical .body .views .crop .constraint-buttons-container .constraint-buttons .constraint:last-child:after { content: ''; display: block; position: absolute; right: -24px; width: 24px; height: 1px; }

.modal.imageeditor:not(.vertical) .body .views .crop .constraint-buttons { flex-direction: column; align-items: stretch; text-align: center; }

.modal.imageeditor:not(.vertical) .body .views .crop .constraint-buttons .constraint { text-align: left; padding: 4px 0 4px 24px; cursor: pointer; }

.modal.imageeditor:not(.vertical) .body .views .crop .constraint-buttons .constraint.active { background-position: left center; background-repeat: no-repeat; background-image: url(../images/image-editor/check.svg); }

/* Vertical */
.vertical.imageeditor .body { flex-direction: column-reverse; }

.vertical.imageeditor .body .tabs ul { display: flex; flex-direction: row; width: 100%; border-top: 1px solid #000; border-bottom: 1px solid #000; }

.vertical.imageeditor .body .tabs ul li { flex-grow: 1; border: 0; border-right: 1px solid #000; padding: 7px; }

.vertical.imageeditor .body .views { width: auto; }

.vertical.imageeditor .body .views .rotate { display: flex; justify-content: center; flex-wrap: wrap; margin-bottom: -14px; }

.vertical.imageeditor .body .views .rotate .btngroup { margin: 0 7px 14px; }

.vertical.imageeditor .body .views .filters ul { width: auto; height: 109px; overflow: auto; white-space: nowrap; margin: 0 -24px; margin-bottom: -24px; padding: 24px; text-align: center; }

.vertical.imageeditor .body .views .filters ul li { display: inline-block; margin-bottom: 0; }

/* Slide Rule */
.slide-rule { position: relative; padding: 10px 0; }

.slide-rule .cursor { position: absolute; margin-left: -4px; margin-top: 4px; left: 50%; z-index: 1; width: 0; height: 0; border-left: 5px solid transparent; border-right: 5px solid transparent; border-top: 5px solid #63A6E1; }

.slide-rule .graduations { white-space: nowrap; height: 40px; position: relative; }

.slide-rule .graduations ul { position: relative; float: left; }

.slide-rule .graduations ul li { display: inline-block; font-size: 8px; position: relative; width: 10px; }

.slide-rule .graduations ul li:not(.main-graduation) { left: 1px; }

.slide-rule .graduations ul li.main-graduation:before { border-left-width: 3px; }

.slide-rule .graduations ul li.main-graduation .label { display: block; cursor: default; }

.slide-rule .graduations ul li.selected:before { border-left-color: #63A6E1; }

.slide-rule .graduations ul li:before { content: ''; position: absolute; border-left: 1px solid #fff; height: 10px; top: 0px; left: 0; }

.slide-rule .graduations ul li .label { width: 20px; position: absolute; top: 10px; left: -9px; display: none; text-align: center; }

.slide-rule .value { display: none; position: absolute; bottom: 0; left: 50%; z-index: 1; box-sizing: border-box; padding: 5px; width: 50px; margin-left: -25px; background: #515f6c; }

.slide-rule .overlay { z-index: 2; position: absolute; top: 0; right: 0; bottom: 1px; left: 0; background-image: linear-gradient(to right, #1f2933 0%, rgba(31, 41, 51, 0) 15%, rgba(31, 41, 51, 0) 85%, #1f2933 100%); }

/* Misc */
.menu.dark { background-color: #515f6c; -moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; font-weight: 500; }

.menu.dark ul li a { color: #fff !important; }

.menu.dark ul li a:not(.sel):not(.disabled):hover { background-color: #3f4d5a; }

_:-ms-lang(x), .elements .tableview td.checkbox-cell input.checkbox + label, .elements .tableview td.checkbox-cell div.checkbox { top: 16px; }

_:-ms-lang(x), input.checkbox + label:empty, div:empty.checkbox { display: block; }
